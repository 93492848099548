import React, { useEffect, useState } from "react";
import download from "downloadjs";
import SearchIcon from "../../../images/search.png";
import FilterIcon from "../../../images/filter.png";
import QrCode from "../../partials/QrCode";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Edit from "../../../assets/icons/edit-ic.svg";
import Delete from "../../../assets/icons/delete-ic.svg";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { Auth } from "aws-amplify";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import { awsExports } from "../../../aws-exports";
const cognito = new CognitoIdentityServiceProvider({
  region: awsExports.REGION, // e.g., 'us-east-1'
  accessKeyId: awsExports.AWS_ACCESS_KEY_ID,
  secretAccessKey: awsExports.AWS_SECRET_ACCESS_KEY,
});
const UserList = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [getRoles, setRoles] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;
  const [getSite, setSite] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRole, setSelectedRole] = useState("all");
  const [isActive, setIsActive] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [downloadFile, setDownloadFile] = useState(false);
  const [downloadLinkFile, setDownloadLinkFile] = useState(false);
  const [excelFileUrl, setExcelFileUrl] = useState(null);
  const [credentials, setCredentials] = useState({
    role: "all",
    status: "all",
    site: "all",
    name: "",
  });
  const handleInput = (e) => {
    setCurrentPage(1);
    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      fetchData(updatedCredentials);
      return updatedCredentials;
    });
  };
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  useEffect(() => {
    fetchData(credentials);
  }, [currentPage, searchValue, selectedRole]);
  useEffect(() => {
    roleData();
    siteData();
  }, []);
  const roleData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/roles/getRoles`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setRoles(json);
    } catch (error) {
      console.error(error);
    }
  };
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/list`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setSite(json.site_data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData = async (fetchCredentials) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(
        `${url}/api/auth/getAllUsers?page=${currentPage}&limit=${itemsPerPage}&name=${fetchCredentials.name}&role=${fetchCredentials.role}&site=${fetchCredentials.site}&status=${fetchCredentials.status}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      const json = await response.json();
      if (json.users.length > 0) {
        setIsDisplay(true);
      } else {
        setIsNotFound(true);
      }
      setData(json.users);
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const handleResetBtn = () => {
    const updatedCredentials = {
      name: "",
      role: "all",
      site: "all",
      status: "all",
    };
    // Update state and then call fetchData
    setCredentials(updatedCredentials);
    setCurrentPage(1);
    // Call fetchData with the updated credentials
    fetchData(updatedCredentials);
  };
  const handleDelete = async (id, awsUserId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Delete it!",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        // alert(awsUserId);

        if (awsUserId) {
          const params = {
            UserPoolId: awsExports.USER_POOL_ID, // Replace with your Cognito User Pool ID
            Username: awsUserId,
          };
          await cognito.adminDeleteUser(params).promise();
          // Delete the user from Cognito
          // await Auth.adminDeleteUser({
          //   UserPoolId: awsExports.USER_POOL_ID, // Replace with your actual User Pool ID
          //   Username: awsUserId, // User to delete
          // }).promise();
        }
        // await Auth.deleteUser(awsUserId);

        Swal.showLoading();
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("auth-token", localStorage.getItem("token"));
        const response = await fetch(`${url}/api/auth/user/delete/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        });
        if (response.ok) {
          fetchData(credentials); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "User deleted successfully!", "success");
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }
  const fetchDataAuth = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(`${url}/api/auth/generate/excel`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });

      if (response.ok) {
        const blob = await response.blob();
        download(blob, "user.xlsx");
      }
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(excelFileUrl);
  // useEffect(() => {
  //   fetchDataAuth();
  // }, []);
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap">
                <h3>All Users</h3>
                <p>List of all the users registered in the system</p>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="filter-right-sec">
                <div className="filter-wrap">
                  <div className="filter-box" onClick={toggleFilterSection}>
                    <img src={FilterIcon} alt="" />
                  </div>
                </div>
                {(isAuthenticated.role.toLowerCase() === "site admin" ||
                  isAuthenticated.role.toLowerCase() === "super admin" ||
                  isAuthenticated.role.toLowerCase() === "company admin") && (
                  <div className="d-flex gap-2">
                    <Link to="/user/add" className="btn btn-prim w-auto">
                      Add New User
                    </Link>

                    <Link
                      to="/add/cognito/users"
                      className="btn btn-prim w-auto"
                    >
                      Add New Cognito Users
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isFilterVisible && (
            <>
              <motion.div
                className={`row mt-5 filter-section`}
                initial={{ opacity: 0 }} // Initial state (hidden)
                animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
                transition={{ duration: 1.2, delay: 0.1 }}
              >
                <div className="col-12">
                  <div className="filter-right-sec filter-wrap-chat">
                    <div className="filter-select d-flex align-items-center">
                      <label htmlFor="">Role</label>
                      <select
                        name="role"
                        id=""
                        value={credentials.role}
                        onChange={handleInput}
                      >
                        <option value="all">Choose Role</option>
                        {getRoles.map((per) => (
                          <option key={per._id} value={per.name}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="filter-select d-flex align-items-center">
                      <label htmlFor="">Site</label>
                      <select
                        name="site"
                        id=""
                        value={credentials.site}
                        onChange={handleInput}
                      >
                        <option value="all">Choose Site</option>
                        {getSite.map((per) => (
                          <option key={per._id} value={per._id}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="filter-select d-flex align-items-center">
                      <label htmlFor="">Status</label>
                      <select
                        name="status"
                        id=""
                        value={credentials.status}
                        onChange={handleInput}
                      >
                        <option value="all">Choose Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                    <div className="search-wrap">
                      <input
                        type="search"
                        placeholder="Search by Username"
                        name="name"
                        value={credentials.name}
                        onChange={handleInput}
                      />
                      <div className="search-box">
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <Link
                      to=""
                      onClick={handleResetBtn}
                      className="btn btn-prim w-auto mb-2"
                    >
                      RESET
                    </Link>
                  </div>
                </div>
              </motion.div>
            </>
          )}
        </div>
        {/* row */}
        <div className="table-wrap">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>Email</th>
                          <th>Role</th>
                          <th>Status</th>
                          <th>Phone</th>
                          <th>Created</th>
                          <th>Level</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <>
                            <tr>
                              <td
                                colSpan={6}
                                className="text-center"
                                style={{ background: "transparent" }}
                              >
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        {isDisplay &&
                          data.map((user, i) => (
                            <motion.tr
                              key={user._id}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 1.2, delay: i * 0.1 }}
                            >
                              <td>
                                <div className="usr_det_tb">
                                  {user.image ? (
                                    <div className="img_wrap_user_tb">
                                      <img
                                        className="not_prof_img"
                                        src={`${user.image}`}
                                        alt=""
                                      />
                                    </div>
                                  ) : (
                                    <div className="img_wrap_user_tb"></div>
                                  )}
                                  <span className="text-capitalize">
                                    {user.name}
                                  </span>
                                </div>{" "}
                              </td>
                              <td>{user.email}</td>
                              <td>{user.role}</td>
                              <td>
                                {user.status && user.status !== "" && (
                                  <>
                                    {user.status === "active" && (
                                      <span className="status success">
                                        Active
                                      </span>
                                    )}
                                    {user.status === "inactive" && (
                                      <span className="status error">
                                        Inactive
                                      </span>
                                    )}
                                  </>
                                )}
                              </td>
                              <td>{user.phone}</td>
                              <td>{formattedDate(user.date)}</td>

                              <td>{user.level}</td>
                              <td>
                                <div className="d-flex justify-content-center align-items-center gap-2">
                                  <Link
                                    type="button"
                                    className=""
                                    to={`/user/edit/${user._id}`}
                                  >
                                    <img src={Edit} alt="edit" />
                                  </Link>
                                  <button
                                    type="button"
                                    className="border-0"
                                    onClick={() =>
                                      handleDelete(user._id, user.aws_username)
                                    }
                                  >
                                    <img src={Delete} alt="delete" />
                                  </button>
                                </div>
                              </td>
                            </motion.tr>
                          ))}
                        {isNotFound && (
                          <tr>
                            <td colSpan={7} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {/* <button
                      className="btn btn-prim w-auto"
                      onClick={fetchDataAuth}
                    >
                      Generate Excel
                    </button> */}
                  </div>
                  {/* <div className="table-num">
                  <div className="dig-num">
                    <span>1</span> <Link to="/">2</Link>{" "}
                    <Link to="/">3</Link> <Link to="/">4</Link>{" "}
                  </div>
                  <div className="num-btns">
                    <Link to="/">
                      <img src={PreviousIcon} alt="" />
                    </Link>{" "}
                    <Link to="/">
                      <img src={NextIcon} alt="" />
                    </Link>
                  </div>
                </div> */}
                  <div className="table-num">
                    {data.length > 0 && (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={totalPages}
                        forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                        onPageChange={handlePageChange}
                        containerClassName={" dig-num"}
                        pageClassName={"dig-num"}
                        previousClassName={"num-btns"}
                        nextClassName={"num-btns"}
                        disabledClassName={"pagination-disabled"}
                        activeClassName={"pagination-active"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserList;
