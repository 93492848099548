import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../partials/LockerHeader";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../Redux/Authentication";
import "../../../index.css";
import styles from "./style.module.css";
import yeslocker from "../../../assets/new-design/locker.png";
import shortyes from "../../../assets/new-design/small-locker.png";
import SendIcon from "../../../assets/icons/send_icon.svg";
import CrossIcon from "../../../assets/icons/cross_icon.svg";
import VideoAssist from "../../../images/new images/video.png";
import NoImage from "../../../images/new images/no-photo.png";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import io from "socket.io-client";
import { notification } from "antd";
import Modal from "../../partials/Modal";
import DecVideCall from "../../../assets/new-design/dec-video-call.png";
import MuteSwitch from "../../../assets/new-design/muted_mic_icon.svg";
import UnmuteSwitch from "../../../assets/new-design/unmuted_mic_ico n.svg";
import UnmuteVideo from "../../../assets/new-design/muted_vdo_icon.svg";
import SwtchVideo from "../../../assets/new-design/unmute_vdo_icon.svg";
import ringTone from "../../../assets/ringcall.mp3";
import backgroundMusicClick from "../../../assets/click.mp3";
import ReactPlayer from "react-player";
import {
  StringeeUtil,
  StringeeClient,
  StringeeCall,
  StringeeCall2,
} from "stringee";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);

const LockerPage = () => {
  const streaming_url = process.env.REACT_APP_STREAMIN_SOCKET_URL;
  let { slug } = useParams();
  slug = slug.toLowerCase();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDisplay, setIsDisplay] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [visible, setVisible] = useState(true);
  const [pin, setPin] = useState(false);
  const [selectedLocker, setselectedLocker] = useState("");
  const [lockerId, setLockerId] = useState("");
  const [getLocker, setLocker] = useState([]);
  const isAuthenticated = useSelector((state) => state.user);
  const [user, setUser] = useState({});
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [notLoggegIn, setLoggedIn] = React.useState(false);
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [callDeclined, setcallDeclined] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [stream, setStream] = useState();
  const [audioTrack, setAudioTrack] = useState(null);
  const [videoTrack, setVideoTrack] = useState(null);
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [sdkVersion, setSdkVersion] = useState("");
  const [loggedUserId, setLoggedUserId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [toNumber, setToNumber] = useState("842471098576");
  const [call, setCall] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [stringeeClient, setStringeeClient] = useState(null);
  const [muted, setMuted] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [timeoutStats, setTimeoutStats] = useState(null);
  const [callConnectionDestroy, setCallConnectionDestroy] = useState(false);
  const [callButton, setCallButton] = useState(true);
  const [isCallActive, setIsCallActive] = useState(false);
  const [isReadyModalOpen, setIsReadyModalOpen] = useState(false);
  const [connectedAttendee, setConnectedAttendee] = useState(null);
  const [ringingCall, setRingingCall] = useState(false);
  const [callerBusy, setCallerBusy] = useState(false);
  const [backgroundAudio] = useState(new Audio(ringTone));
  const [backgroundAudioClick] = useState(new Audio(backgroundMusicClick));
  const [waitingCallModal, setWaitingCallModal] = useState(false);
  const remoteButtonRef = useRef(null);
  const leaveCallButtonRef = useRef(null);
  const ringCall = useRef(null);
  const clickButton = useRef(null);
  const { width } = getWindowDimensions();

  useEffect(() => {
    setSdkVersion(
      StringeeUtil.version().version +
        "_build_" +
        StringeeUtil.version().build +
        "_sample_1"
    );
    getAccessToken();
  }, []);
  const generateUserId = async () => {
    // Generate a random unique user ID (example)
    return "user_" + Date.now();
  };
  const getAccessToken = async () => {
    try {
      const loginUser = await generateUserId();
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/auth/get-token?userId=${loginUser}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        setAccessToken(json.token);
        handleLogin(json.token);

        // const client = new StringeeClient([
        //   "wss://v1.stringee.com:6899/",
        //   "wss://v2.stringee.com:6899/",
        // ]);

        // client.on("authen", (res) => {
        //   if (res.r === 0) {
        //     setLoggedUserId(res.userId);
        //     setAccessToken(""); // Reset access token input field
        //   }
        // });
        //  console.log(json.token);
        // client.connect(json.token); // Connect using the generated token
        // setStringeeClient(client); // Set stringeeClient in state
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogin = (token) => {
    const client = new StringeeClient();

    client.on("connect", function () {
      console.log("++++++++++++++ connected to StringeeServer");
    });

    client.on("authen", function (res) {
      console.log("authen", res);
      if (res.r === 0) {
        setLoggedUserId(res.userId);
      } else {
        setLoggedUserId(res.message);
      }
    });

    client.on("disconnect", function () {
      console.log("++++++++++++++ disconnected");
      // alert("disconnected");
    });

    client.on("incomingcall", function (incomingcall) {
      if (isCallActive) {
        alert("Busy another call. Please wait.");
        return;
      }
      setCall(incomingcall);
      setIsCallActive(true);
      // settingCallEvent(incomingcall);

      incomingcall.ringing(function (res) {});

      console.log("++++++++++++++ incomingcall", incomingcall);
    });

    client.on("requestnewtoken", function () {
      console.log(
        "++++++++++++++ requestnewtoken; please get new access_token from YourServer and call client.connect(new_access_token)+++++++++"
      );
      //please get new access_token from YourServer and call:
      //client.connect(new_access_token);
    });

    setStringeeClient(client);
    client.connect(token);
  };

  const handleHangup = () => {
    try {
      if (clickButton.current) {
        clickButton.current.click();
      }
      if (call) {
        // call.hangup((res) => {
        //   console.log("hangup res", res);
        // });
        call.hangup();
        setCall(null);
        setLocalStream(null);
        setRemoteStream(null);
        setCallModalOpen(false);
        setCallConnectionDestroy(false);
        onStop();
        toast.success("Call hang up successfully!");
      }
    } catch (error) {
      console.error("Error hanging up the call:", error);
    }
  };

  useEffect(() => {
    socket.on("destroyConnection", (data) => {
      try {
        setCallConnectionDestroy(false);
        console.log("test");
      } catch (error) {
        console.error("Error handling destroyConnection event:", error);
      }
    });
  }, []);

  const ring = async () => {
    backgroundAudio.play();
  };
  const clickButtonFun = async () => {
    backgroundAudioClick.play();
  };

  const onStop = () => {
    if (timeoutStats) {
      clearTimeout(timeoutStats);
    }

    // if (call) {
    //   call.subscribedTracks.forEach((track) => {
    //     track.detachAndRemove();
    //   });
    // }
  };
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://cdn.jsdelivr.net/npm/rtsp-relay@1.7.0/browser/index.js";
  //   script.async = true;
  //   script.onload = () => {
  //     /* eslint-disable no-undef */
  //     loadPlayer({
  //       url: `${streaming_url}/stream/url/17`, // Replace with your desired channel ID
  //       canvas: document.getElementById("canvas"),
  //       options: {
  //         video: {
  //           codec: "h264", // Set the codec to H.264
  //           // Add any other video codec options here
  //         },
  //       },
  //     });
  //     /* eslint-enable no-undef */
  //   };
  //   document.head.appendChild(script);
  //   return () => {
  //     // Clean up the script element if the component unmounts
  //     document.head.removeChild(script);
  //   };
  // }, [IDBIndex]);
  const [barcode, setBarcode] = useState("");
  let scanner;
  useEffect(() => {
    let interval;
    const handleKeyDown = (evt) => {
      if (interval) clearInterval(interval);
      if (evt.code === "Enter") {
        if (barcode) fetchUserData(barcode);
        setBarcode("");
        return;
      }
      if (evt.key !== "Shift")
        setBarcode((prevBarcode) => prevBarcode + evt.key);
      interval = setInterval(() => setBarcode(""), 20);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [barcode]);
  const [credentials, setCredentials] = useState({
    number: "",
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const sendPind = async () => {
    if (credentials.number === pin) {
      try {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(
          `${url}/api/locker/update/request/status`,
          {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              id: lockerId,
              userId: user._id,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const json = await response.json();
        if (json.success) {
          // setselectedLocker(lockerId);
          await openLocker(lockerId);
          setIsOpen(false);
        }
      } catch (error) {
        notification.error({
          message: "Failed",
          description: "Something went wrong!",
          duration: 3,
        });
      }
    } else {
      notification.error({
        message: "Failed",
        description: "Invalid Pin",
        duration: 3,
      });
    }
  };

  const displayOpen = async (id, position) => {
    navigate(`/${slug}/locker-slider?locker=${id}&position=${position}`);
    // sendRequest(id);
    setLockerId(id);
    // if (user.level == "1") {
    //   const result = await Swal.fire({
    //     title: "Do you want to open this locker?",
    //     text: "",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: "Yes!",
    //     cancelButtonText: "No",
    //     reverseButtons: true,
    //   });

    //   if (result.isConfirmed) {
    //     // setselectedLocker(id);
    //     openLocker(id);
    //   }
    // } else if (user.level == "2") {
    //   if (user.level_permission.includes(id)) {
    //     const result = await Swal.fire({
    //       title: "Do you want to open this locker?",
    //       text: "",
    //       icon: "warning",
    //       showCancelButton: true,
    //       confirmButtonText: "Yes!",
    //       cancelButtonText: "No",
    //       reverseButtons: true,
    //     });
    //     if (result.isConfirmed) {
    //       // setselectedLocker(id);
    //       openLocker(id);
    //     }
    //   } else {
    //     const checkRequest = await checkLockerRequest(id);
    //     if (checkRequest) {
    //       setIsOpen(true);
    //     } else {
    //       const result = await Swal.fire({
    //         title: "You dont have permission to access this locker",
    //         text: "",
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonText: "Request Permission",
    //         cancelButtonText: "Cancel",
    //         reverseButtons: true,
    //       });
    //       if (result.isConfirmed) {
    //         sendRequest(id);
    //       }
    //     }
    //   }
    // } else if (user.level == "3") {
    //   const result = await Swal.fire({
    //     title: "You dont have permission to access this locker",
    //     text: "",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: "Request Permission",
    //     cancelButtonText: "Cancel",
    //     reverseButtons: true,
    //   });
    // }
  };
  const displayOpen1 = async (id) => {
    const result = await Swal.fire({
      title: "Do you want to open this locker?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      setselectedLocker(id);
      // if (selectedLocker === id) {
      // openModal(id);
      // }
    }
  };

  function closeModal() {
    setIsOpen(false);
  }
  const openLocker = async (id) => {
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading1(true);
    const response = await fetch(`${url}/api/sites/relay/state/update`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        id: id,
        state: state,
      }),
    });

    const json = await response.json();
    if (json.success) {
      notification.success({
        message: "Success",
        description: json.message,
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
    setIsLoading1(false);
    fetchData();
  };

  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(`${url}/api/locker/site/lockers/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.locker_data.length > 0) {
        console.log(json.locker_data);
        setLocker(json.locker_data);
        setIsDisplay(true);
      } else {
        setIsNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchUserData = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(`${url}/api/auth/user/edit/${id}`, {
        mode: "cors",
        method: "get",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.success) {
        // setUser({ ...json.user });
        dispatch(loginSuccess(json.user));
        setLoggedIn(false);
        setVisible(true);
      } else {
        notification.error({
          message: "Failed",
          description: json.message,
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "Failed",
        description: error.message,
        duration: 3,
      });
    }
  };
  // check locker open request
  const checkLockerRequest = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(`${url}/api/locker/check/locker/request`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id: id,
          userId: user._id,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      setPin(json.pin); // Set the pin regardless of success or failure
      return json.success;
    } catch (error) {
      notification.error({
        message: "Failed",
        description: "Something went wrong!",
        duration: 3,
      });
    }
  };
  const sendRequest = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/locker/create/open/Lockers`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id: id,
          userId: isAuthenticated._id,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.success) {
        notification.success({
          message: "Success",
          description: "Request send successfully",
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "Failed",
        description: error.message,
        duration: 3,
      });
    }
  };
  useEffect(() => {
    fetchData();
    socket.on("updateProductquantity", (data) => {
      fetchData();
    });
    // fetchUserData("65fbc1b55a9eb8774ad7a1ec");
  }, []);

  function closeeModal() {
    setCallModalOpen(false);
  }
  const handleVideoCall = async () => {
    if (clickButton.current) {
      clickButton.current.click();
    }

    if (isCallActive) {
      // Add the new call to the queue
      if (connectedAttendee === toNumber) {
        alert("The video attendant is already busy. Please wait.");
      } else {
        // If the user is trying to call a different attendant while one call is active
        addCallToQueue();
        alert(
          "You are already in a call. Please wait for the next available attendant."
        );
      }
      return;
    }

    // setIsReadyModalOpen(true);

    setCallModalOpen(false);
    setCallConnectionDestroy(true);
    setIsCallActive(true);
    setConnectedAttendee();
    callConnection();
  };

  const callConnection = async () => {
    setVideoShow(true);
    const fromNumber = loggedUserId;
    const toNumber = "shoaib"; // Agent's user ID
    const isVideoCall = true;
    const newCall = new StringeeCall(
      stringeeClient,
      fromNumber,
      toNumber,
      isVideoCall
    );

    newCall.on("addlocalstream", (stream) => {
      setLocalStream(stream);
    });

    newCall.on("addremotestream", (stream) => {
      setRemoteStream(stream);
    });

    newCall.makeCall((res) => {
      console.log("make call callback: " + JSON.stringify(res));
      console.log(res, "res");
      setCall(newCall);

      // if (isCallActive) {
      //   console.log("in call active");
      //   setCallerBusy(true);
      // } else {
      //   console.log("make call callback: " + JSON.stringify(res));
      //   // if (res.status === "connected") {
      //   setCall(newCall);
      //   // setCallerBusy(true);
      //   // }
      // }
    });

    newCall.on("signalingstate", function (state) {
      console.log("Signaling state changed:", state);
      console.log("state.code", state.code);
      if (state.code === 2) {
        setRingingCall(true);
        setCallerBusy(false);
        setIsCallActive(false);
      } else if (state.code === 3) {
        setRingingCall(false);
        setIsCallActive(true);
        setCallerBusy(false);
      } else if (state.code === 5) {
        setRingingCall(false);
        setIsCallActive(false);
        setCallerBusy(true);
      } else if (state.code === 6) {
        setRingingCall(false);
        setIsCallActive(false);
        setCallerBusy(false);
        setCallConnectionDestroy(false);
        Swal.fire({
          icon: "info",
          title: "Call Ended",
          text: "The call has been declined by video attendant.",
        });
      } else {
        console.log("Unknown signaling state: ", state.code);
        setRingingCall(false);
        setIsCallActive(false);
        setCallerBusy(false);
      }
    });
  };

  const handleMuteToggle = () => {
    if (call) {
      call.mute(!muted);
      setMuted(!muted);
    }
    if (clickButton.current) {
      clickButton.current.click();
    }
  };

  const handleVideoToggle = () => {
    if (call) {
      call.enableLocalVideo(!videoEnabled);
      setVideoEnabled(!videoEnabled);
    }
    if (clickButton.current) {
      clickButton.current.click();
    }
  };
  const queue = [];
  const addCallToQueue = () => {
    queue.push(true);
    const positionInQueue = queue.length;
    alert(
      `You are ${positionInQueue} in the queue. Please wait for the next available agent.`
    );
  };

  // const formSubmit = async (e) => {
  //   try {
  //     e.preventDefault();
  //     // if (credentials.email != "" || credentials.number != "") {
  //     let headers = new Headers();
  //     headers.append("Content-Type", "application/json");
  //     headers.append("Access-Control-Allow-Origin", "*");
  //     headers.append("Access-Control-Allow-Credentials", "true");
  //     setIsLoading(true);
  //     const response = await fetch(`${url}/api/auth/update/name`, {
  //       mode: "cors",
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify({
  //         name: credentials.name,
  //       }),
  //     });

  //     const json = await response.json();
  //     console.log(json, "my json");
  //     if (json.success) {
  //       toast.success("Name Updated Successfully");
  //       setCredentials({
  //         name: "",
  //       });
  //       setIsReadyModalOpen(false);
  //     } else {
  //       console.log("Error get data");
  //     }
  //   } catch (error) {
  //     toast.error("Internal Server Error form submit");
  //   }
  // };

  // console.log(getLocker[3].product_image);

  return (
    <>
      <div className={styles.heading}>
        <div className="body-wrap">
          <div className="container-fluid px-0">
            <div className="general-dashboard">
              <div className="container-fluid">
                <Header />
                <div className="container-fluid text-center ">
                  <div style={{ position: "relative" }}>
                    <div className="form-box card-box-locker d-flex justify-content-center flex-column">
                      <div className="row">
                        <div className="col-lg-12 locker-side  mt-2">
                          <div className="row gap locker-row mt-3 overflow-auto">
                            {getLocker[0] && (
                              <>
                                <div
                                  className={`col-2  ${
                                    isLoading &&
                                    selectedLocker !== getLocker[0]._id
                                      ? "loading-disabled"
                                      : ""
                                  }`}
                                >
                                  <div className="lockers">
                                    <span className="locker-num">{1}</span>
                                    <>
                                      {selectedLocker === getLocker[0]._id && (
                                        <div className="locker-button-text">
                                          getLocker OPEN
                                        </div>
                                      )}
                                      <img
                                        src={yeslocker}
                                        alt="yes"
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[0]._id, 0);
                                          }
                                        }}
                                      />
                                      <div className="product-images-locker">
                                        <img
                                          onClick={() => {
                                            if (visible) {
                                              displayOpen(getLocker[0]._id, 0);
                                            }
                                          }}
                                          src={
                                            getLocker[0].isImageExists
                                              ? getLocker[0].product_image
                                              : NoImage
                                          }
                                          alt="product-image"
                                        />
                                      </div>
                                      <div className="product-name-locker">
                                        <h6 className="text-capitalize text-break ">
                                          {getLocker[0]?.product_name.length >
                                          10
                                            ? getLocker[0]?.product_name.slice(
                                                0,
                                                9
                                              ) + "..."
                                            : getLocker[0]?.product_name ??
                                              "no name"}{" "}
                                        </h6>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </>
                            )}
                            {getLocker[3] && (
                              <div
                                className={`col-2  ${
                                  isLoading &&
                                  selectedLocker !== getLocker[3]._id
                                    ? "loading-disabled"
                                    : ""
                                }`}
                              >
                                <div className="lockers">
                                  <span className="locker-num">{4}</span>
                                  <>
                                    {selectedLocker === getLocker[3]._id && (
                                      <div className="locker-button-text">
                                        OPEN
                                      </div>
                                    )}
                                    <img
                                      src={yeslocker}
                                      alt="yes"
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[3]._id, 3);
                                        }
                                      }}
                                    />
                                    <div className="product-images-locker">
                                      <img
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[3]._id, 3);
                                          }
                                        }}
                                        src={
                                          getLocker[3].isImageExists // Check if isImageExists is true
                                            ? getLocker[3].product_image // If true, use product_image
                                            : NoImage // If false, use placeholder image
                                        }
                                        alt="product-image"
                                      />
                                    </div>
                                    <div className="product-name-locker">
                                      <h6 className="text-capitalize text-break ">
                                        {getLocker[3]?.product_name.length > 10
                                          ? getLocker[3]?.product_name.slice(
                                              0,
                                              9
                                            ) + "..."
                                          : getLocker[3]?.product_name ??
                                            "no name"}
                                      </h6>
                                    </div>
                                  </>
                                </div>
                              </div>
                            )}
                            <div className="col-2 ">
                              <div className="lockers">
                                {getLocker[6] && (
                                  <div
                                    className={`lockers-short ${
                                      isLoading &&
                                      selectedLocker !== getLocker[6]._id
                                        ? "loading-disabled"
                                        : ""
                                    }`}
                                  >
                                    <span className="locker-num1">{7}</span>
                                    <>
                                      {selectedLocker === getLocker[6]._id && (
                                        <div className="locker-button-text ">
                                          OPEN
                                        </div>
                                      )}
                                      <img
                                        src={shortyes}
                                        alt="yes"
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[6]._id, 6);
                                          }
                                        }}
                                      />
                                      <div className="product-images-locker-short">
                                        <img
                                          onClick={() => {
                                            if (visible) {
                                              displayOpen(getLocker[6]._id, 6);
                                            }
                                          }}
                                          src={
                                            getLocker[6].isImageExists
                                              ? getLocker[6].product_image
                                              : NoImage
                                          }
                                          alt="product-image"
                                        />
                                      </div>
                                      <div className="product-name-locker-short">
                                        <h6 className="text-capitalize text-break ">
                                          {getLocker[6]?.product_name.length >
                                          10
                                            ? getLocker[6].product_name.slice(
                                                0,
                                                9
                                              ) + "..."
                                            : getLocker[6]?.product_name ??
                                              "no name"}
                                        </h6>
                                      </div>
                                    </>
                                  </div>
                                )}
                                {getLocker[7] && (
                                  <div
                                    className={`lockers-short half-locker  ${
                                      isLoading &&
                                      selectedLocker !== getLocker[7]._id
                                        ? "loading-disabled"
                                        : ""
                                    }`}
                                  >
                                    <span className="locker-num2">8</span>
                                    <>
                                      {selectedLocker === getLocker[7]._id && (
                                        <div className="locker-button-text locker-button-text-small">
                                          OPEN
                                        </div>
                                      )}
                                      <img
                                        src={shortyes}
                                        alt="yes"
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[7]._id, 7);
                                          }
                                        }}
                                      />
                                      <div className="product-images-locker-short-2">
                                        <img
                                          onClick={() => {
                                            if (visible) {
                                              displayOpen(getLocker[7]._id, 7);
                                            }
                                          }}
                                          src={
                                            getLocker[7].isImageExists
                                              ? getLocker[7].product_image
                                              : NoImage
                                          }
                                          alt="product-image"
                                        />
                                      </div>
                                      <div className="product-name-locker-short-2">
                                        <h6 className="text-capitalize text-break ">
                                          {getLocker[7]?.product_name.length >
                                          10
                                            ? getLocker[7].product_name.slice(
                                                0,
                                                9
                                              ) + "..."
                                            : getLocker[7].product_name ??
                                              "no name"}
                                        </h6>
                                      </div>
                                    </>
                                  </div>
                                )}
                              </div>
                            </div>
                            {getLocker[10] && (
                              <div
                                className={`col-2  ${
                                  isLoading &&
                                  selectedLocker !== getLocker[10]._id
                                    ? "loading-disabled"
                                    : ""
                                }`}
                              >
                                <div className="lockers">
                                  <span className="locker-num">{11}</span>
                                  <>
                                    {selectedLocker === getLocker[10]._id && (
                                      <div className="locker-button-text">
                                        OPEN
                                      </div>
                                    )}
                                    <img
                                      src={yeslocker}
                                      alt="yes"
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[10]._id, 10);
                                        }
                                      }}
                                    />
                                    <div className="product-images-locker">
                                      <img
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[10]._id, 10);
                                          }
                                        }}
                                        src={
                                          getLocker[10].isImageExists
                                            ? getLocker[10].product_image
                                            : NoImage
                                        }
                                        alt="product-image"
                                      />
                                    </div>
                                    <div className="product-name-locker">
                                      <h6 className="text-capitalize text-break ">
                                        {getLocker[10]?.product_name.length > 10
                                          ? getLocker[10]?.product_name.slice(
                                              0,
                                              9
                                            ) + "..."
                                          : getLocker[10]?.product_name ??
                                            "no name"}{" "}
                                      </h6>
                                    </div>
                                  </>
                                </div>
                              </div>
                            )}
                            {getLocker[13] && (
                              <>
                                <div
                                  className={`col-2  ${
                                    isLoading &&
                                    selectedLocker !== getLocker[13]._id
                                      ? "loading-disabled"
                                      : ""
                                  }`}
                                >
                                  <div className="lockers">
                                    <span className="locker-num">{14}</span>
                                    <>
                                      {selectedLocker === getLocker[13]._id && (
                                        <div className="locker-button-text">
                                          OPEN
                                        </div>
                                      )}
                                      <img
                                        src={yeslocker}
                                        alt="yes"
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[13]._id, 13);
                                          }
                                        }}
                                      />
                                      <div className="product-images-locker">
                                        <img
                                          onClick={() => {
                                            if (visible) {
                                              displayOpen(
                                                getLocker[13]._id,
                                                13
                                              );
                                            }
                                          }}
                                          src={
                                            getLocker[13].isImageExists
                                              ? getLocker[13].product_image
                                              : NoImage
                                          }
                                          alt="product-image"
                                        />
                                      </div>
                                      <div className="product-name-locker">
                                        <h6 className="text-capitalize text-break ">
                                          {getLocker[13]?.product_name.length >
                                          15
                                            ? getLocker[13]?.product_name.slice(
                                                0,
                                                14
                                              ) + "..."
                                            : getLocker[13]?.product_name ??
                                              "no name"}
                                        </h6>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="row locker-row mt-3">
                            {getLocker[1] && (
                              <>
                                <div
                                  className={`col-2  ${
                                    isLoading &&
                                    selectedLocker !== getLocker[1]._id
                                      ? "loading-disabled"
                                      : ""
                                  }`}
                                >
                                  <div className="lockers">
                                    <span className="locker-num">{2}</span>
                                    <>
                                      {selectedLocker === getLocker[1]._id && (
                                        <div className="locker-button-text">
                                          OPEN
                                        </div>
                                      )}
                                      <img
                                        src={yeslocker}
                                        alt="yes"
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[1]._id, 1);
                                          }
                                        }}
                                      />
                                      <div className="product-images-locker">
                                        <img
                                          onClick={() => {
                                            if (visible) {
                                              displayOpen(getLocker[1]._id, 1);
                                            }
                                          }}
                                          src={
                                            getLocker[1].isImageExists
                                              ? getLocker[1].product_image
                                              : NoImage
                                          }
                                          alt="product-image"
                                        />
                                      </div>
                                      <div className="product-name-locker">
                                        <h6 className="text-capitalize text-break ">
                                          {getLocker[1]?.product_name.length >
                                          10
                                            ? getLocker[1]?.product_name.slice(
                                                0,
                                                9
                                              ) + "..."
                                            : getLocker[1]?.product_name ??
                                              "no name"}{" "}
                                        </h6>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </>
                            )}
                            {getLocker[4] && (
                              <div
                                className={`col-2  ${
                                  isLoading &&
                                  selectedLocker !== getLocker[4]._id
                                    ? "loading-disabled"
                                    : ""
                                }`}
                              >
                                <div className="lockers">
                                  <span className="locker-num">{5}</span>
                                  <>
                                    {selectedLocker === getLocker[4]._id && (
                                      <div className="locker-button-text">
                                        OPEN
                                      </div>
                                    )}
                                    <img
                                      src={yeslocker}
                                      alt="yes"
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[4]._id, 4);
                                        }
                                      }}
                                    />
                                    <div className="product-images-locker">
                                      <img
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[4]._id, 4);
                                          }
                                        }}
                                        src={
                                          getLocker[4].isImageExists
                                            ? getLocker[4].product_image
                                            : NoImage
                                        }
                                        alt="product-image"
                                      />
                                    </div>
                                    <div className="product-name-locker">
                                      <h6 className="text-capitalize text-break ">
                                        {getLocker[4].product_name
                                          ? getLocker[4].product_name.split(" ")
                                              .length > 2
                                            ? `${getLocker[4].product_name
                                                .split(" ")
                                                .slice(0, 1)
                                                .join(" ")}...`
                                            : getLocker[4].product_name
                                          : "no name"}{" "}
                                      </h6>
                                    </div>
                                  </>
                                </div>
                              </div>
                            )}
                            <div className="col-2">
                              <div className="d-flex flex-column video-call-attendant">
                                <div
                                  className="locker video-attendant"
                                  style={{ zIndex: "1000" }}
                                >
                                  <img
                                    src={VideoAssist}
                                    alt="yes"
                                    onClick={handleVideoCall}
                                  />
                                </div>

                                <button
                                  className="btn btn-prim w-25  video-call-button"
                                  onClick={handleVideoCall}
                                  style={{ zIndex: "1000" }}
                                >
                                  <span>Call</span>
                                </button>
                              </div>
                            </div>
                            {getLocker[11] && (
                              <div
                                className={`col-2  ${
                                  isLoading &&
                                  selectedLocker !== getLocker[11]._id
                                    ? "loading-disabled"
                                    : ""
                                }`}
                              >
                                <div className="lockers">
                                  <span className="locker-num">{12}</span>
                                  <>
                                    {selectedLocker === getLocker[11]._id && (
                                      <div className="locker-button-text">
                                        OPEN
                                      </div>
                                    )}
                                    <img
                                      src={yeslocker}
                                      alt="yes"
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[11]._id, 11);
                                        }
                                      }}
                                    />
                                    <div className="product-images-locker">
                                      <img
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[11]._id, 11);
                                          }
                                        }}
                                        src={
                                          getLocker[11].isImageExists
                                            ? getLocker[11].product_image
                                            : NoImage
                                        }
                                        alt="product-image"
                                      />
                                    </div>
                                    <div className="product-name-locker">
                                      <h6 className="text-capitalize text-break ">
                                        {getLocker[11]?.product_name.length > 10
                                          ? getLocker[11]?.product_name.slice(
                                              0,
                                              9
                                            ) + "..."
                                          : getLocker[11]?.product_name ??
                                            "no name"}{" "}
                                      </h6>
                                    </div>
                                  </>
                                </div>
                              </div>
                            )}
                            {getLocker[14] && (
                              <>
                                <div
                                  className={`col-2  ${
                                    isLoading &&
                                    selectedLocker !== getLocker[14]._id
                                      ? "loading-disabled"
                                      : ""
                                  }`}
                                >
                                  <div className="lockers">
                                    <span className="locker-num">{15}</span>
                                    <>
                                      {selectedLocker === getLocker[14]._id && (
                                        <div className="locker-button-text">
                                          OPEN
                                        </div>
                                      )}
                                      <img
                                        src={yeslocker}
                                        alt="yes"
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[14]._id, 14);
                                          }
                                        }}
                                      />
                                      <div className="product-images-locker">
                                        <img
                                          onClick={() => {
                                            if (visible) {
                                              displayOpen(
                                                getLocker[14]._id,
                                                14
                                              );
                                            }
                                          }}
                                          src={
                                            getLocker[14].isImageExists
                                              ? getLocker[14].product_image
                                              : NoImage
                                          }
                                          alt="product-image"
                                        />
                                      </div>
                                      <div className="product-name-locker">
                                        <h6 className="text-capitalize text-break ">
                                          {getLocker[14]?.product_name.length >
                                          10
                                            ? getLocker[14]?.product_name.slice(
                                                0,
                                                9
                                              ) + "..."
                                            : getLocker[14]?.product_name ??
                                              "no name"}{" "}
                                        </h6>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="row locker-row mt-3">
                            {getLocker[2] && (
                              <>
                                <div
                                  className={`col-2  ${
                                    isLoading &&
                                    selectedLocker !== getLocker[2]._id
                                      ? "loading-disabled"
                                      : ""
                                  }`}
                                >
                                  <div className="lockers">
                                    <span className="locker-num">{3}</span>
                                    <>
                                      {selectedLocker === getLocker[2]._id && (
                                        <div className="locker-button-text">
                                          OPEN
                                        </div>
                                      )}
                                      <img
                                        src={yeslocker}
                                        alt="yes"
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[2]._id, 2);
                                          }
                                        }}
                                      />
                                      <div className="product-images-locker">
                                        <img
                                          onClick={() => {
                                            if (visible) {
                                              displayOpen(getLocker[2]._id, 2);
                                            }
                                          }}
                                          src={
                                            getLocker[2].isImageExists
                                              ? getLocker[2].product_image
                                              : NoImage
                                          }
                                          alt="product-image"
                                        />
                                      </div>
                                      <div className="product-name-locker">
                                        <h6 className="text-capitalize text-break ">
                                          {getLocker[2]?.product_name.length >
                                          10
                                            ? getLocker[2]?.product_name.slice(
                                                0,
                                                9
                                              ) + "..."
                                            : getLocker[2]?.product_name ??
                                              "no name"}{" "}
                                        </h6>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </>
                            )}
                            {getLocker[5] && (
                              <div
                                className={`col-2  ${
                                  isLoading &&
                                  selectedLocker !== getLocker[5]._id
                                    ? "loading-disabled"
                                    : ""
                                }`}
                              >
                                <div className="lockers">
                                  <span className="locker-num">{6}</span>
                                  <>
                                    {selectedLocker === getLocker[5]._id && (
                                      <div className="locker-button-text">
                                        OPEN
                                      </div>
                                    )}
                                    <img
                                      src={yeslocker}
                                      alt="yes"
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[5]._id, 5);
                                        }
                                      }}
                                    />
                                    <div className="product-images-locker">
                                      <img
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[5]._id, 5);
                                          }
                                        }}
                                        src={
                                          getLocker[5].isImageExists
                                            ? getLocker[5].product_image
                                            : NoImage
                                        }
                                        alt="product-image"
                                      />
                                    </div>
                                    <div className="product-name-locker">
                                      <h6 className="text-capitalize text-break ">
                                        {getLocker[5]?.product_name.length > 10
                                          ? getLocker[5]?.product_name.slice(
                                              0,
                                              9
                                            ) + "..."
                                          : getLocker[5]?.product_name ??
                                            "no name"}{" "}
                                      </h6>
                                    </div>
                                  </>
                                </div>
                              </div>
                            )}
                            <div className="col-2 ">
                              <div className="lockers">
                                {getLocker[8] && (
                                  <div
                                    className={`lockers-short ${
                                      isLoading &&
                                      selectedLocker !== getLocker[8]._id
                                        ? "loading-disabled"
                                        : ""
                                    }`}
                                  >
                                    <span className="locker-num1">{9}</span>
                                    <>
                                      {selectedLocker === getLocker[8]._id && (
                                        <div className="locker-button-text">
                                          OPEN
                                        </div>
                                      )}
                                      <img
                                        src={shortyes}
                                        alt="yes"
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[8]._id, 8);
                                          }
                                        }}
                                      />
                                      <div className="product-images-locker-short">
                                        <img
                                          onClick={() => {
                                            if (visible) {
                                              displayOpen(getLocker[8]._id, 8);
                                            }
                                          }}
                                          src={
                                            getLocker[8].isImageExists
                                              ? getLocker[8].product_image
                                              : NoImage
                                          }
                                          alt="product-image"
                                        />
                                      </div>
                                      <div className="product-name-locker-short">
                                        <h6 className="text-capitalize text-break ">
                                          {getLocker[8].product_name.length > 10
                                            ? getLocker[8].product_name.slice(
                                                0,
                                                10
                                              ) + "..."
                                            : getLocker[8].product_name ??
                                              "no name"}
                                        </h6>
                                      </div>
                                    </>
                                  </div>
                                )}
                                {getLocker[9] && (
                                  <div
                                    className={`lockers-short half-locker ${
                                      isLoading &&
                                      selectedLocker !== getLocker[9]._id
                                        ? "loading-disabled"
                                        : ""
                                    }`}
                                  >
                                    <span className="locker-num2">{10}</span>
                                    <>
                                      {selectedLocker === getLocker[9]._id && (
                                        <div className="locker-button-text locker-button-text-small">
                                          OPEN
                                        </div>
                                      )}
                                      <img
                                        src={shortyes}
                                        alt="yes"
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[9]._id, 9);
                                          }
                                        }}
                                      />
                                      <div className="product-images-locker-short-2">
                                        <img
                                          onClick={() => {
                                            if (visible) {
                                              displayOpen(getLocker[9]._id, 9);
                                            }
                                          }}
                                          src={
                                            getLocker[9].isImageExists
                                              ? getLocker[9].product_image
                                              : NoImage
                                          }
                                          alt="product-image"
                                        />
                                      </div>
                                      <div className="product-name-locker-short-2">
                                        <h6 className="text-capitalize text-break ">
                                          {getLocker[9].product_name.length > 10
                                            ? getLocker[9].product_name.slice(
                                                0,
                                                10
                                              ) + "..."
                                            : getLocker[9].product_name ??
                                              "no name"}
                                        </h6>
                                      </div>
                                    </>
                                  </div>
                                )}
                              </div>
                            </div>
                            {getLocker[12] && (
                              <div
                                className={`col-2  ${
                                  isLoading &&
                                  selectedLocker !== getLocker[12]._id
                                    ? "loading-disabled"
                                    : ""
                                }`}
                              >
                                <div className="lockers">
                                  <span className="locker-num">{13}</span>
                                  <>
                                    {selectedLocker === getLocker[12]._id && (
                                      <div className="locker-button-text">
                                        OPEN
                                      </div>
                                    )}
                                    <img
                                      src={yeslocker}
                                      alt="yes"
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[12]._id, 12);
                                        }
                                      }}
                                    />
                                    <div className="product-images-locker">
                                      <img
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[12]._id, 12);
                                          }
                                        }}
                                        src={
                                          getLocker[12].isImageExists
                                            ? getLocker[12].product_image
                                            : NoImage
                                        }
                                        alt="product-image"
                                      />
                                    </div>
                                    <div className="product-name-locker">
                                      <h6 className="text-capitalize text-break ">
                                        {getLocker[12]?.product_name.length > 10
                                          ? getLocker[12]?.product_name.slice(
                                              0,
                                              9
                                            ) + "..."
                                          : getLocker[12]?.product_name ??
                                            "no name"}{" "}
                                      </h6>
                                    </div>
                                  </>
                                </div>
                              </div>
                            )}
                            {getLocker[15] && (
                              <>
                                <div
                                  className={`col-2  ${
                                    isLoading &&
                                    selectedLocker !== getLocker[15]._id
                                      ? "loading-disabled"
                                      : ""
                                  }`}
                                >
                                  <div className="lockers">
                                    <span className="locker-num">{16}</span>
                                    <>
                                      {selectedLocker === getLocker[15]._id && (
                                        <div className="locker-button-text">
                                          OPEN
                                        </div>
                                      )}
                                      <img
                                        src={yeslocker}
                                        alt="yes"
                                        onClick={() => {
                                          if (visible) {
                                            displayOpen(getLocker[15]._id, 15);
                                          }
                                        }}
                                      />
                                      <div className="product-images-locker">
                                        <img
                                          onClick={() => {
                                            if (visible) {
                                              displayOpen(
                                                getLocker[15]._id,
                                                15
                                              );
                                            }
                                          }}
                                          src={
                                            getLocker[15].isImageExists
                                              ? getLocker[15].product_image
                                              : NoImage
                                          }
                                          alt="product-image"
                                        />
                                      </div>
                                      <div className="product-name-locker">
                                        <h6 className="text-capitalize text-break ">
                                          {getLocker[15]?.product_name.length >
                                          10
                                            ? getLocker[15]?.product_name.slice(
                                                0,
                                                9
                                              ) + "..."
                                            : getLocker[15]?.product_name ??
                                              "no name"}{" "}
                                        </h6>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {callConnectionDestroy && (
                        <div className="d-flex justify-content-center gap-1 flex-row mt-3">
                          {videoShow && (
                            <>
                              <div className="local-streaming-side position-relative">
                                {!localStream && (
                                  <div
                                    class="lds-spinner"
                                    style={{ display: "flex", top: "125px" }}
                                  >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                                {localStream && (
                                  <video
                                    ref={(ref) => {
                                      if (ref) ref.srcObject = localStream;
                                    }}
                                    autoPlay
                                    playsInline
                                    muted
                                    // style={{
                                    //   width: "120px",
                                    //   position: "absolute",
                                    //   top: "7px",
                                    //   right: "5px",
                                    // }}
                                    width="100%"
                                  ></video>
                                )}
                                {/* <div className="icons-wrap-end-call-locker my-3">
                                  <div
                                    className="icon-call caller-icon d-flex justify-content-center align-items-center"
                                    style={{
                                      background: "rgb(2, 1, 93)",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  >
                                    {videoEnabled ? (
                                      <img
                                        src={SwtchVideo}
                                        alt="Unmute Video"
                                        className="mx-2 d-flex justify-content-center"
                                        width={20}
                                        height={20}
                                        onClick={handleVideoToggle}
                                      />
                                    ) : (
                                      <img
                                        src={UnmuteVideo}
                                        alt="Mute Video"
                                        className="mx-2 d-flex justify-content-center  rounded"
                                        width={20}
                                        height={20}
                                        onClick={handleVideoToggle}
                                      />
                                    )}
                                  </div>
                                  <Link>
                                    <div className="icon-call dec-call-video">
                                      <img
                                        src={DecVideCall}
                                        onClick={handleHangup}
                                        alt="Decline Call"
                                      />
                                    </div>
                                  </Link>
                                  <div
                                    className="icon-call d-flex  align-items-center"
                                    style={{
                                      background: "rgb(2, 1, 93)",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  >
                                    {!muted ? (
                                      <img
                                        src={UnmuteSwitch}
                                        alt="UnMute Call"
                                        className="mx-2 d-flex justify-content-center"
                                        width={20}
                                        height={20}
                                        onClick={handleMuteToggle}
                                      />
                                    ) : (
                                      <img
                                        src={MuteSwitch}
                                        alt="Mute Call"
                                        className="mx-2 d-flex justify-content-center  rounded "
                                        width={20}
                                        height={20}
                                        onClick={handleMuteToggle}
                                      />
                                    )}
                                  </div>
                                </div> */}
                              </div>
                              <div className="remote-streaming-side  d-flex justify-content-between flex-column position-relative ">
                                {!remoteStream && (
                                  <div
                                    class="lds-spinner"
                                    style={{ display: "flex", top: "125px" }}
                                  >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                )}
                                {remoteStream ? (
                                  <>
                                    <video
                                      width="100%"
                                      ref={(ref) => {
                                        if (ref) ref.srcObject = remoteStream;
                                      }} // userVideo
                                      autoPlay
                                      playsInline
                                      style={{
                                        overflow: "auto",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <video autoPlay playsInline />
                                  </>
                                )}

                                {/* {isCallActive && (
                                  <p
                                    className="text-center text-white"
                                    style={{
                                      position: "absolute",
                                      top: "92%",
                                      left: "30%",
                                      padding: "0px 10px",
                                      width: "50%",
                                    }}
                                  >
                                    Call Connected
                                  </p>
                                )} */}

                                {ringingCall &&
                                  !isCallActive &&
                                  !callerBusy && (
                                    <div>
                                      <p>Call Ringing...</p>
                                    </div>
                                  )}

                                {callerBusy && !isCallActive && (
                                  <div>
                                    {" "}
                                    <p>Please Wait! Attendant is busy...</p>
                                  </div>
                                )}

                                {/* {callConnectionDestroy && !isCallActive && (
                                  <h5 className="text-center">
                                    Call Decline by Video Attendant...
                                  </h5>
                                )} */}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    {/* <div className="live-streaming bg-white py-4 my-2 ">
                      <div className="d-flex justify-content-center w-full ">
                        <canvas id="canvas"></canvas>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onClose={closeModal}
          className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
          shouldCloseOnOverlayClick={false}
          style={{
            content: {
              fontFamily: "Arial",
              fontWeight: "800",
              fontSize: "30px",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
            },
          }}
        >
          <div className="d-flex justify-content-between align-items-center flex-row">
            <img src={SendIcon} alt="send" height={40} width={40} />
            <img
              src={CrossIcon}
              alt="cross"
              onClick={closeModal}
              height={37}
              width={37}
            />
          </div>

          <div className="modal-body">
            <div
              className="p-3 w-sm-50 h-sm-50 col-12 rounded-3"
              style={{ background: "#fff" }}
            >
              <div>
                <h5 className="fs-6 m-2" style={{ fontWeight: "700" }}>
                  Please Enter Your Pin
                </h5>
              </div>
              <div
                className="p-1 rounded-2 w-sm-25  " //h-75
                style={{ background: "#fff" }}
              >
                <div className="d-flex flex-column">
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      id="number"
                      name="number"
                      value={credentials.number}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center flex-row flex-wrap gap-2 mt-4">
                  <button
                    className="  border border-2 border-blue rounded-pill px-3 py-2 shadow "
                    style={{
                      fontWeight: "700",
                      fontFamily: "Arial",
                      fontSize: "12px",
                      textDecoration: "none",
                      background: "#ff0000",
                      color: "#fff",
                    }}
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className=" border border-2 border-blue rounded-pill px-3 py-2 shadow "
                    style={{
                      fontWeight: "700",
                      fontFamily: "Arial",
                      fontSize: "12px",
                      textDecoration: "none",
                      background: "#012267",
                      color: "#fff",
                    }}
                    onClick={sendPind}
                  >
                    Enter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={notLoggegIn}
          className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
          shouldCloseOnOverlayClick={false}
          style={{
            content: {
              fontFamily: "Arial",
              fontWeight: "800",
              fontSize: "30px",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
            },
          }}
        >
          <div className="d-flex justify-content-between align-items-center flex-row">
            <img src={SendIcon} alt="send" height={40} width={40} />
          </div>

          <div className="modal-body">
            <div
              className="p-3 w-sm-50 h-sm-50 col-12 rounded-3"
              style={{ background: "#fff" }}
            >
              <div>
                <h5 className="fs-6 m-2" style={{ fontWeight: "700" }}>
                  Please scan your profile qr code
                </h5>
              </div>
              <div
                className="p-1 rounded-2 w-sm-25  " //h-75
                style={{ background: "#fff" }}
              ></div>
            </div>
          </div>
        </Modal>

        <div className="modal-caller">
          <Modal
            isOpen={callModalOpen}
            onClick={closeeModal}
            className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
            style={{
              content: {
                position: "relative",
                fontFamily: "Arial",
                fontWeight: "800",
                fontSize: "30px",
                maxWidth: "500px", // Set a maximum width for the modal container
                maxHeight: "450px",
                margin: "auto",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
              },
            }}
          >
            <div
              className="p-1 w-sm-50 h-sm-50 col-sm-12 rounded-3"
              style={{
                background: "linear-gradient(#323232, #4c4c4c)",
                position: "relative",
                marginTop: "10px",
                // marginTop: "120px"
              }}
            >
              {!localStream && (
                <div
                  class="lds-spinner"
                  style={{ display: "flex", top: "80px" }}
                >
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
              <div className=" ">
                {videoShow && (
                  <>
                    {localStream && (
                      <video
                        ref={(ref) => {
                          if (ref) ref.srcObject = localStream;
                        }}
                        autoPlay
                        playsInline
                        muted
                        style={{
                          width: "120px",
                          position: "absolute",
                          top: "7px",
                          right: "5px",
                        }}
                      ></video>
                    )}
                    {/* <ReactPlayer
                      playing
                      muted
                      style={{
                        position: "absolute",
                        zIndex: "99999999",
                        top: "1%",
                        right: "3%",
                        borderRadius: "10px",
                        objectFit: "cover",
                        width: "100%", // Set the width of the video to 100%
                        height: "auto",
                      }}
                      height="25%"
                      width="22%"
                      url={stream}
                    /> */}
                    {remoteStream ? (
                      <video
                        width="100%"
                        ref={(ref) => {
                          if (ref) ref.srcObject = remoteStream;
                        }} // userVideo
                        autoPlay
                        playsInline
                        style={{
                          overflow: "auto",
                        }}
                      />
                    ) : (
                      <video autoPlay playsInline />
                    )}

                    {callDeclined && (
                      <h5 className="text-center">Call Declined by Admin...</h5>
                    )}
                  </>
                )}
              </div>

              {/* <>
                <div className="icons-wrap-end-call my-3">
                  <div
                    className="icon-call caller-icon d-flex justify-content-center align-items-center"
                    style={{
                      background: "rgb(2, 1, 93)",
                      width: "35px",
                      height: "35px",
                    }}
                  >
                    {videoEnabled ? (
                      <img
                        src={SwtchVideo}
                        alt="Unmute Video"
                        className="mx-2 d-flex justify-content-center"
                        width={27}
                        height={27}
                        onClick={handleVideoToggle}
                      />
                    ) : (
                      <img
                        src={UnmuteVideo}
                        alt="Mute Video"
                        className="mx-2 d-flex justify-content-center  rounded"
                        width={27}
                        height={27}
                        onClick={handleVideoToggle}
                      />
                    )}
                  </div>
                  <Link>
                    <div className="icon-call dec-call-video">
                      <img
                        src={DecVideCall}
                        onClick={handleHangup}
                        alt="Decline Call"
                      />
                    </div>
                  </Link>
                  <div
                    className="icon-call d-flex  align-items-center"
                    style={{
                      background: "rgb(2, 1, 93)",
                      width: "35px",
                      height: "35px",
                    }}
                  >
                    {!muted ? (
                      <img
                        src={UnmuteSwitch}
                        alt="UnMute Call"
                        className="mx-2 d-flex justify-content-center"
                        width={27}
                        height={27}
                        onClick={handleMuteToggle}
                      />
                    ) : (
                      <img
                        src={MuteSwitch}
                        alt="Mute Call"
                        className="mx-2 d-flex justify-content-center  rounded "
                        width={27}
                        height={27}
                        onClick={handleMuteToggle}
                      />
                    )}
                  </div>
                </div>
              </> */}
            </div>
          </Modal>

          <Modal
            isOpen={waitingCallModal}
            contentLabel="Example Modal"
            className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
            shouldCloseOnOverlayClick={false}
            style={{
              content: {
                fontFamily: "Arial",
                fontWeight: "800",
                fontSize: "30px",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
              },
            }}
          >
            <div
              className="p-3 w-sm-50 h-sm-50 col-10 rounded-3"
              style={{ background: "#012269" }}
            >
              <div className="d-flex flex-row">
                <h4 className="win-heading ">Waiting...</h4>
              </div>
              <div
                className="p-4 rounded-2 w-sm-25 " //h-75
                style={{ background: "#031947" }}
              >
                <h5
                  className="text-center text-white mt-2 "
                  style={{
                    fontFamily: "Arial",
                    fontSize: "25px",
                    fontWeight: "700",
                  }}
                >
                  WAITING FOR PLAYERS TO JOIN
                </h5>

                <div className="d-flex flex-row flex-wrap  gap-3 justify-content-center align-items-center mt-4"></div>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={isReadyModalOpen}
            contentLabel="Example Modal"
            className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
            shouldCloseOnOverlayClick={false}
            style={{
              content: {
                fontFamily: "Arial",
                fontWeight: "800",
                fontSize: "30px",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
              },
            }}
          >
            <div
              className="p-4 w-sm-50 h-sm-50 col-12 rounded-3"
              style={{ background: "#fff" }}
            >
              <div className="d-flex flex-row">
                <h4 className="win-heading-2 text-dark text-center">
                  Enter Your Name
                </h4>
              </div>
              <form>
                <div
                  className="p-1 rounded-2 w-sm-25 " //h-75
                  style={{ background: "#fff" }}
                >
                  <div>
                    {/* <label
                      htmlFor="text"
                      className="form-label"
                      style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                      Name
                    </label> */}
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={credentials?.name}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center flex-row flex-wrap gap-2 mt-3">
                    <Link
                      onClick={() => setIsReadyModalOpen(false)}
                      className="bg-white border border-2 border-blue rounded-pill px-4 py-2 shadow "
                      style={{
                        fontWeight: "700",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        textDecoration: "none",
                        color: "#012267",
                      }}
                    >
                      Cancel
                    </Link>
                    <button
                      type="submit"
                      className="bg-blue text-white  border border-2 border-blue rounded-pill px-4 py-2 shadow"
                      style={{
                        fontWeight: "700",
                        fontFamily: "Arial",
                        fontSize: "12px",
                        textDecoration: "none",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </div>
        {/* <a ref={remoteButtonRef} onClick={() => answerCall(slug)}></a> */}
        <a ref={leaveCallButtonRef} onClick={handleHangup}></a>
        <a ref={ringCall} onClick={ring}></a>
        <a ref={clickButton} onClick={clickButtonFun}></a>
      </div>
    </>
  );
};

export default LockerPage;
