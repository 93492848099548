import React, { useEffect, useRef, useState } from "react";
// import { CopyToClipboard } from "react-copy-to-clipboard"
import styles from "./style.module.css";
import ProfileImg from "../../../images/profile.png";
import NotificationIcon from "../../../images/icon-notifications.png";
import BackIcon from "../../../images/icon-back.png";
import LargeScreenIcon from "../../../images/large-switch.png";
import DecVideCall from "../../../images/dec-video-call.png";
import MuteSwitch from "../../../images/mute-switch.png";
import UnmuteSwitch from "../../../images/mike.png";
import UnmuteVideo from "../../../images/muteVideo.png";
import RcvrImg from "../../../images/reciver-img.png";
import ShadowImg from "../../../images/shadow-video.png";
import SwitchCamera from "../../../images/switch-camera.png";
import SwtchVideo from "../../../images/vid-switch.png";
import PersonalImg from "../../../images/personal-img.png";
import Peer from "simple-peer";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { setConnection } from "../../Redux/Authentication";
import io from "socket.io-client";
import { notification } from "antd";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);
function VideoCall() {
  const myButtonRef = useRef(null);
  const remoteButtonRef = useRef(null);
  const leaveCallButtonRef = useRef(null);
  const location = useLocation();

  const dispatch = useDispatch();
  const connection = useSelector((state) => state.connection);
  const queryParams = new URLSearchParams(location.search);
  const remote = queryParams.get("remote");
  const { roomId, id } = useParams();
  const isAuthenticated = useSelector((state) => state.user);
  const [me, setMe] = useState("");
  const [stream, setStream] = useState(null);
  const [userstream, setUserStream] = useState();
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState();
  const [callAccepted, setCallAccepted] = useState(false);
  const [idToCall, setIdToCall] = useState("");
  const [callEnded, setCallEnded] = useState(false);
  const [callDeclined, setcallDeclined] = useState(false);
  const [name, setName] = useState("");
  const myVideo = useRef(null);
  const userVideo = useRef(null);
  const connectionRef = useRef(null);
  const [videoCallUser, setVideoCallUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const senderId = isAuthenticated._id;
  const [messageInput, setMessageInput] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const countRef = useRef(newMessage);
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [audioTrack, setAudioTrack] = useState(null);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [videoTrack, setVideoTrack] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  let navigate = useNavigate();
  useEffect(() => {
    async function setStreamFun() {
      try {
        const strm = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        const audioTrack = strm.getAudioTracks()[0];
        // Mute the audio track by default
        audioTrack.enabled = true;
        const videoTrack = strm.getVideoTracks()[0];
        // Mute the video track by default
        videoTrack.enabled = true;
        setStream(strm);
        setAudioTrack(audioTrack);
        setVideoTrack(videoTrack);
        if (myVideo.current) {
          myVideo.current.srcObject = strm;
        }
        socket.emit("callUser2", {
          caller: senderId + "1a",
        });
        socket.on("connectCall", (data) => {
          const delayInMilliseconds = 5000; // 10 seconds
          // Update elapsed time every second
          let intervalId = "";
          const timeoutId = setTimeout(() => {
            if (myButtonRef.current) {
              myButtonRef.current.click();
            }
          }, delayInMilliseconds);
          // Make sure to clear the timeout if the component unmounts
          return () => {
            clearTimeout(timeoutId);
          };
        });
        socket.on("callUser1", (data) => {
          setReceivingCall(true);
          setCaller(data.from);
          setName(data.name);
          setCallerSignal(data.signal);
          const delayInMilliseconds = 5000; // 10 seconds
          const timeoutId = setTimeout(() => {
            if (remoteButtonRef.current) {
              remoteButtonRef.current.click();
            }
          }, delayInMilliseconds);
          // Make sure to clear the timeout if the component unmounts
          return () => clearTimeout(timeoutId);
        });
        socket.on("destroyConnection", (data) => {
          destroyConnection();
        });
        // window.addEventListener('beforeunload', (event) => {
        //   // Save call-related information to persistent storage
        //   localStorage.setItem('callInfo', JSON.stringify({
        //     stream,
        //     myVideo,
        //     userVideo,
        //     connectionRef,
        //     // Add other relevant data
        //   }));
        // });
        let newConnection;
        // if (connection) {
        //   // Re-establish connection if it exists
        //   newConnection = connection;
        // } else {
        //   // Initialize a new connection
        //   newConnection = new Peer({
        //     initiator: true,
        //     trickle: false,
        //     stream: stream,
        //   });
        //   dispatch(setConnection(newConnection)); // Store the connection in Redux
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    setStreamFun();
    socket.on("updateChatMessages", (data) => {
      showNewMessageBox(data.senderId);
    });
    socket.on("pageRefesh", (data) => {
      localStorage.removeItem("streamDone");
      window.location.reload();
    });
    if (localStorage.getItem("streamDone")) {
      socket.emit("pageHardRefesh", {
        caller: id + "1a",
      });
      // Reload the page
    }
    // Function to add the event listener
    getUserMessages(id);
    return () => {
      localStorage.removeItem("streamDone");
      changeStatusAvailable();
      if (stream) {
        // Release camera permissions when the component unmounts
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [senderId, id]);
  useEffect(() => {
    if (remote) {
      socket.emit("recieverOnline", {
        caller: id + "1a",
      });
    }
  }, []);
  useEffect(() => {
    // Start the timer when the call is accepted
    if (callAccepted && !callEnded) {
      const intervalId = setInterval(updateElapsedTime, 1000); // Update every second
      changeStatus();
      // Make sure to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [callAccepted, callEnded]);
  const callUser = async (roomId) => {
    try {
    
      // Create a promise that resolves when the stream is set
      const streamPromise = new Promise((resolve, reject) => {
        const peer = new Peer({
          initiator: true,
          trickle: false,
          stream: stream,
        });
        peer.on("signal", (data) => {
          
          socket.emit("callUser", {
            caller: roomId + "1a",
            remote: remote,
            signalData: data,
            name: "imran",
          });
        });
        peer.on("stream", (stream) => {
          
          setUserStream(stream);
          if (userVideo.current) {
            userVideo.current.srcObject = stream;
          }
          resolve(); // Resolve the promise when the stream is set
        });
        socket.on("callAccepted", (signal) => {
          setCallAccepted(true);
         
          try {
            peer.signal(signal);
          } catch (error) {
            // Swallow the error without logging it
          }
        });
        connectionRef.current = peer;
        localStorage.setItem("streamDone", true);
      });
      // Wait for the stream to be set before continuing
      await streamPromise;
      // Continue with other functionality that depends on the stream
    
    } catch (error) {
      // Handle any errors that may occur during the process
      console.error("Error in callUser:", error.message);
    }
  };
  const answerCall = (roomId) => {
    setCallAccepted(true);
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream,
    });
    peer.on("signal", (data) => {
      socket.emit("answerCall", { signal: data, to: roomId + "1a" });
    });
    peer.on("stream", (strme) => {
      setUserStream(strme);
      if (userVideo.current) {
        // Set the srcObject of the video element
        userVideo.current.srcObject = strme;
      }
    });
    peer.signal(callerSignal);
    connectionRef.current = peer;
    localStorage.setItem("streamDone", true);
  };
  // leave video call
  const userlLeaveCall = () => {
    stopCamera();
    setStream();
    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }
    // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    // connectionRef.current.destroy();
    connectionRef.current = null;
    const delayInMilliseconds = 3000; // 10 seconds
    // Update elapsed time every second
    const timeoutId = setTimeout(() => {
      navigate("/chats");
    }, delayInMilliseconds);
  };
  const handleSendNotification = (userId, message) => {
    // Send the notification to the server via WebSocket
    socket.emit("sendNotification", { userId, message });
  };
  function stopCamera() {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  }
  const leaveCall = () => {
    socket.emit("destroyCallConnection", {
      caller: id + "1a",
    });
    stopCamera();
    setCallEnded(true);
    if (userVideo.current) {
      userVideo.current.pause();
    }
    // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    // connectionRef.current.destroy();
    connectionRef.current = null;

    const delayInMilliseconds = 3000; // 10 seconds

    // Update elapsed time every second
    const timeoutId = setTimeout(() => {
      navigate("/chats");
    }, delayInMilliseconds);
    changeStatusAvailable();
  };
  const destroyConnection = () => {
    setCallEnded(true);
    setcallDeclined(true);
    changeStatusAvailable();
    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }
    // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    connectionRef.current.destroy();
    const delayInMilliseconds = 5000; // 10 seconds
    // Update elapsed time every second
    const timeoutId = setTimeout(() => {
      if (leaveCallButtonRef.current) {
        leaveCallButtonRef.current.click();
      }
    }, delayInMilliseconds);
    // Make sure to clear the timeout if the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
    // navigate('/chats');
  };
  const toggleAudioMute = () => {
    if (audioTrack) {
      audioTrack.enabled = !isAudioMuted;
      setIsAudioMuted(!isAudioMuted);
    }
  };
  const toggleVideoMute = () => {
    if (videoTrack) {
      videoTrack.enabled = !isVideoMuted;
      setIsVideoMuted(!isVideoMuted);
    }
  };
  function updateElapsedTime() {
    setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
  }
  const getUserMessages = async (id) => {
    try {
      setNewMessage(id);
      countRef.current = id;
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(`${url}/api/chat/message/list/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setVideoCallUser(id);
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        setMessages(json.chats);
        setSelectedUser(json.selectedUser);
        setShowMessageBox(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(`${url}/api/chat/message/send`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        message: messageInput,
        sender_id: senderId,
        receiver_id: selectedUser._id,
      }),
    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setMessageInput("");
      getUserMessages(selectedUser._id);
      const receiverId = selectedUser._id;
      socket.emit("updateReciverChat", { senderId });
      socket.emit("sendMessageNotification", { receiverId });
      notification.success({
        message: "Success",
        description: "Message sent Successfully",
        duration: 3,
      });
    } else {
      notification.error({
        message: "Success",
        description: "failed to send message",
        duration: 3,
      });
    }
  };
  const showNewMessageBox = async (id) => {
    if (countRef.current === id) {
      getUserMessages(id);
    }
  };
  const changeStatus = async (e) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    const response = await fetch(
      `${url}/api/auth/user/update/chat_status/${senderId}`,
      {
        mode: "cors",
        method: "GET",
        headers: headers,
      }
    );
  };
  const changeStatusAvailable = async (e) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    const response = await fetch(
      `${url}/api/auth/user/update/chat_status/available/${senderId}`,
      {
        mode: "cors",
        method: "GET",
        headers: headers,
      }
    );
  };
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/chats">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>Video call</h3>
                  <p>
                    You’re now talking to{" "}
                    <b>
                      {" "}
                      {selectedUser && selectedUser !== ""
                        ? selectedUser.name
                        : "no user"}
                    </b>
                    {/* {
                                  stream &&(
                                    <> */}
                    {/* </>
                                  )
                                } */}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        <a ref={myButtonRef} onClick={() => callUser(id)}></a>
        <a ref={remoteButtonRef} onClick={() => answerCall(id)}></a>
        <a ref={leaveCallButtonRef} onClick={userlLeaveCall}></a>
        {/* row */}
        <div className="row">
          <div className="col-12">
            <div className="video-screen-sec">
              <div className="row">
                <div className="col col-video-screen">
                  <div className="vide-sec-whole-wrap">
                    <div className="vide-img-wrap">
                      <img src={ShadowImg} alt="" className="shadow-img" />
                      {!callEnded ? (
                        <video ref={userVideo} autoPlay playsInline />
                      ) : (
                        <video autoPlay playsInline />
                      )}
                    </div>
                    <div className="top-bar-call-vid">
                      <div className="call-time">
                        <p>{`${Math.floor(elapsedTime / 60)}:${(
                          elapsedTime % 60
                        )
                          .toString()
                          .padStart(2, "0")}`}</p>
                      </div>
                      <div className="small-screen">
                        <video
                          className="persona-img"
                          muted
                          ref={myVideo}
                          autoPlay
                        />
                      </div>
                    </div>
                    <div className="bottom-bar-call">
                    {!callAccepted &&
                              <div class="lds-spinner" ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            }
                      {/* {!callAccepted && <h5>Call connecting ...</h5>} */}
                      {callDeclined && (
                        <h5>
                          Call declined by{" "}
                          <b>
                            {" "}
                            {selectedUser && selectedUser !== ""
                              ? selectedUser.name
                              : "no user"}
                          </b>
                        </h5>
                      )}
                    </div>
                  </div>
                  <div className="bottom-bar-call seperat-row">
                    {callAccepted && !callEnded ? (
                      <div className="icons-wrap-end-call">
                        {/* <div className="icon-call">
                                      <img src={SwtchVideo} alt="" />
                                    </div> */}
                        <div className="icon-call" onClick={toggleVideoMute}>
                          {isVideoMuted ? (
                            <img src={SwtchVideo} alt="Unmute Video" />
                          ) : (
                            <img src={UnmuteVideo} alt="Mute Video" />
                          )}
                        </div>
                        {/* <div className="icon-call">
                                      <img src={MuteSwitch} alt="" />
                                    </div> */}
                        <div className="icon-call dec-call-video">
                          <img src={DecVideCall} alt="" onClick={leaveCall} />
                        </div>
                        <div className="icon-call" onClick={toggleAudioMute}>
                          {isAudioMuted ? (
                            <img src={UnmuteSwitch} alt="" />
                          ) : (
                            <img src={MuteSwitch} alt="" />
                          )}
                        </div>
                        {/* <div className="icon-call">
                                      <img src={SwitchCamera} alt="" />
                                    </div>
                                    <div className="icon-call">
                                      <img src={LargeScreenIcon} alt="" />
                                    </div> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col col-chat-screen">
                  <div className="message-body">
                    <div className="message-top-bar-user-wrap">
                      <div className="message-top-bar-user d-flex justify-content-between align-items-center">
                        <div className="name-det d-flex">
                          <div className="icon-wrap">
                            <img
                              src={
                                selectedUser && selectedUser.image !== ""
                                  ? selectedUser.image
                                  : ProfileImg
                              }
                              alt=""
                            />
                          </div>
                          <div className="name-job">
                            <h3 className="mb-0">
                              {" "}
                              {selectedUser && selectedUser !== ""
                                ? selectedUser.name
                                : "no user"}
                            </h3>
                            <p className="mb-0">
                              {selectedUser && selectedUser !== ""
                                ? selectedUser.role
                                : ""}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="message-content-area">
                      <div className="cht-wrap">
                        {messages.length > 0 ? (
                          messages.map((chat) => (
                            <>
                              {chat.receiverId === senderId && (
                                <div className="recv-msg msg-line">
                                  <p>{chat.message}</p>
                                </div>
                              )}
                              {chat.senderId === senderId && (
                                <div className="snt-msg msg-line">
                                  <p>{chat.message}</p>
                                </div>
                              )}
                            </>
                          ))
                        ) : (
                          <p>No Message...</p>
                        )}
                      </div>
                      <div className="send-wrap">
                        <form onSubmit={sendMessage}>
                          <div className="inpt-fld d-flex">
                            <div className="col input-fld">
                              <input
                                type="text"
                                placeholder="Write a message..."
                                value={messageInput}
                                onChange={(e) => {
                                  setMessageInput(e.target.value);
                                }}
                              />
                            </div>
                            <div className="col input-btn">
                              <button type="submit" className="btn btn-prim">
                                Send
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VideoCall;
