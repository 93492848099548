import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import Images from "../modal/Images";
import BackIcon from "../../../assets/icons/icon-back.svg";
import { notification } from "antd";
const Products = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const role = isAuthenticated.role;
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [availableProductList, setAvailableProduct] = useState([]);
  const [getSelectedRquestedProduct, setSelectedRquestedProduct] = useState([]);
  const [totalProduct1, setTotalProduct1] = useState(0);
  const [productQuantities1, setProductQuantities1] = useState({});
  const [credentials, setCredentials] = useState({ name: "" });
  const [availableNotFound, setAvailableNotFound] = useState(false);
  const [requestedSelectedNotFound, setRequestedSelectedNotFound] =
    useState(false);
  const { id } = useParams();
  const itemsPerPage = 8;
  useEffect(() => {
    getRquestedProduct(credentials, id);
  }, [currentPage]);
  // get requested product list
  const getRquestedProduct = async (fetchCredentials, id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/products/requestedProductList/${id}?page=${currentPage}&limit=${itemsPerPage}&name=${fetchCredentials.name}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.inventory.length > 0) {
        setRequestedSelectedNotFound(false);
        setSelectedRquestedProduct(json.inventory);
      } else {
        setSelectedRquestedProduct(json.inventory);
        setRequestedSelectedNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleIncrement2 = (id, quantity) => {
    const updatedQuantities = { ...productQuantities1 };

    updatedQuantities[id] =
      (updatedQuantities[id] || parseInt(quantity, 10)) + 1;
    setProductQuantities1(updatedQuantities);

    updateTotalProduct1(updatedQuantities);
  };

  const handleDecrement2 = (id) => {
    const updatedQuantities = { ...productQuantities1 };
    if (updatedQuantities[id] > 0) {
      updatedQuantities[id] = (updatedQuantities[id] || 0) - 1;
      setProductQuantities1(updatedQuantities);
      updateTotalProduct1(updatedQuantities);
    }
  };
  // update product
  const updateTotalProduct1 = (quantities) => {
    const total = Object.values(quantities).reduce(
      (acc, val) => acc + Math.abs(val),
      0
    );
    setTotalProduct1(total);
  };
  // view all product quantity
  // const handleInput1 = (e) => {
  //   setCurrentPage(1);
  //   // Use the callback function to ensure that the state is updated before calling fetchData
  //   setCredentials((prevCredentials) => {
  //     const updatedCredentials = {
  //       ...prevCredentials,
  //       [e.target.name]: e.target.value,
  //     };
  //     availableRequestedProduct(updatedCredentials,site);
  //     return updatedCredentials;
  //   });
  // };
  // order requested product
  const orderRquestedProduct = async (p_id, quantity) => {
    const updatedQuantities = { ...productQuantities1 };
    const quantities = updatedQuantities[p_id]
      ? updatedQuantities[p_id]
      : quantity;
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/inventory/orderRequestProductQuantity`,
        {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            id: p_id,
            quantity: quantities,
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        getRquestedProduct(credentials, id);
        notification.success({
          message: "Success",
          description: data.message,
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: data.error,
          duration: 3,
        });
      }
      // Reset form or handle success as needed
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/dashboard">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Requested Products</h3>
                  <p>
                    Select product quantity and click button to request the
                    product
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* row */}
        <div className="main-containerbox">
          <div className="data-box">
            <div className="top-heading-data">
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-2 total-row">
                  {/* <p className="total-text text-center">Total</p>
                  <p className="total-counter">
                    {totalProduct ? totalProduct : "0"}
                  </p> */}
                </div>
                <div className="col-md-4">
                  {/* <input
                    type="text"
                    className="form-control search-box"
                    id="fuserName"
                    name="name"
                    placeholder="Search.."
                    value={credentials.name}
                    onChange={handleInput1}
                  /> */}
                </div>
                {/* <div className="col-md-2">
                  <button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className="btn btn-prim"
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>
                    Open Locker
                  </button>
                </div> */}
              </div>
            </div>

            <div className="booked-container-details mt-3 text-center">
              {getSelectedRquestedProduct.length > 0 &&
                getSelectedRquestedProduct.map((item, key) => (
                  <>
                    <div className="booked-container">
                      <div className="booked-data">
                        <h5>{item.p_name}</h5>
                        <p>{item.quantity}</p>

                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "0px",
                            }}
                          >
                            <button
                              onClick={() => handleDecrement2(item._id)}
                              className="btn btn-danger btn-decrement"
                              style={{ marginRight: "10px" }}
                            >
                              <i className="fa fa-minus" aria-hidden="true"></i>
                            </button>
                            <p className="product-counter">
                              {productQuantities1[item._id]
                                ? productQuantities1[item._id]
                                : item.quantity}
                            </p>
                            <button
                              onClick={() =>
                                handleIncrement2(item._id, item.quantity)
                              }
                              className="btn btn-primary btn-increment"
                              style={{ marginLeft: "10px" }}
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            orderRquestedProduct(item._id, item.quantity)
                          }
                          className="btn btn-prim "
                        >
                          Order
                        </button>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {requestedSelectedNotFound && (
              <div className="row ">
                <div className="col-12">
                  <p className="text-center text-danger">No data found.</p>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* available products */}
        <div className="table-num mt-3">
          {getSelectedRquestedProduct.length > 0 && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={totalPages}
              forcePage={currentPage - 1} // react-paginate uses zero-based indexing
              onPageChange={handlePageChange}
              containerClassName={" dig-num"}
              pageClassName={"dig-num"}
              previousClassName={"num-btns"}
              nextClassName={"num-btns"}
              disabledClassName={"pagination-disabled"}
              activeClassName={"pagination-active"}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Products;
