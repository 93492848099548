import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { notification } from "antd";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import VideoOrImages from "./VideoOrImages";
import { useParams } from "react-router-dom";
import Select from "react-select";
import styles from "./style.module.css";
import BackIcon from "../../../images/icon-back.png";
const AddScreen = () => {
  const { id } = useParams();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [videosOrImages, setVideosOrImages] = useState([]);
  const [siteQrcodes, setSiteQrcodes] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedQrcodes, setSelectedQrcodes] = useState([]);
  const [currentScreen, setCurrentScreen] = useState("");
  const handleInput = (e) => {
    setCurrentScreen(e.target.value);
  };
  useEffect(() => {
    fetchFile();
    fetchQrcodes();
  }, []);

  useEffect(() => {
    fetchscreen();
  }, [id]);
  const fetchFile = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/screen/files`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setVideosOrImages(json.content);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchQrcodes = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/screen/qrcodes`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSiteQrcodes(json.qrcodes);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchscreen = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/screen/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSelectedVideos(json.files);
        setSelectedQrcodes(json.qrcodeIds);
        setCurrentScreen(json.screen);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleVideoChange = (event) => {
    const { value, checked } = event.target;
    setSelectedVideos((prevSelectedVideos) =>
      checked
        ? [...prevSelectedVideos, value]
        : prevSelectedVideos.filter((video) => video !== value)
    );
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));

      if (selectedVideos.length === 0) {
        notification.error({
          message: "Warning",
          description: "Please select any image or video first",
          duration: 3,
        });
        return;
      }
      if (selectedQrcodes.length === 0) {
        notification.error({
          message: "Warning",
          description: "Please select any qrcode first",
          duration: 3,
        });
        return;
      }
      setIsLoading(true);
      const response = await fetch(`${url}/api/screen/update/${id}`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          name: currentScreen,
          files: selectedVideos,
          qrcodes: selectedQrcodes,
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      console.log(json);
      if (json.success) {
        notification.success({
          message: "Success",
          description: "Screen updated Successfully",
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: "failed to update screen",
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleQrSelection = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedQrcodes(selectedValues);
  };
  let getQrOptions = []; // Initialize an empty array
  if (siteQrcodes.length > 0) {
    getQrOptions = siteQrcodes.map((per) => ({
      value: per._id,
      label: per.name,
      isSelected: selectedQrcodes.includes(per._id),
    }));
  }
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/locker/bank">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Configure screen</h3>
                  <p>
                    Configure locker bank screen by selectting qr codes and
                    videos
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-3 col-cust-screens-3">
                    <div className="label-wrap">
                      <h4>Select Media</h4>
                      <p>Images/videos you want to display</p>
                    </div>
                  </div>
                  <div className="col-lg-9 col-cust-screens-9">
                    <div className="row">
                      {videosOrImages.length > 0 ? (
                        videosOrImages.map((item) => (
                          <div className="col-lg-3 col-md-3 col-sm-4 col-6">
                            <div className="seq-wrap">
                              <div className="upld-img-wrap">
                                <div className="remve-img checkd-img">
                                  <input
                                    className="form-check-input position-static"
                                    id={`video-${item.id}`}
                                    name="videos[]"
                                    type="checkbox"
                                    value={item.image}
                                    checked={selectedVideos.includes(
                                      item.image
                                    )}
                                    onChange={handleVideoChange}
                                  />
                                </div>

                                <VideoOrImages videoName={item.image} />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h4 className="text-danger text-center">
                          No Media Found
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" mt-4">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-3 col-lg-5">
                    <div className="label-wrap">
                      <h4>Select QR</h4>
                      <p>Select QR codes you want to show</p>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-7">
                    <Select
                      isMulti
                      name="tenants"
                      options={getQrOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleQrSelection}
                      value={getQrOptions.filter((option) => option.isSelected)}
                    />
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button
                    type="submit"
                    className="btn btn-prim w-auto ms-5"
                    disabled={isLoading}
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                    Update Screen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddScreen;
