import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import NoImage from "../../../images/new images/no-photo.png";
import PlusButton from "../../../assets/new-design/plus-btn.svg";
import MinusButton from "../../../assets/new-design/minus-btn.svg";
import BackButton from "../../../assets/new-design/back-button.png";
import ForwardButton from "../../../assets/new-design/fast-forward.png";
import { Link, useParams } from "react-router-dom";
import BackIcon from "../../../assets/icons/icon-back.svg";
import CrossIcon from "../../../assets/icons/cross_icon.svg";
import Mask from "../../../assets/new-design/Mask-group.png";
import Modal from "react-modal";
import { notification } from "antd";
const Products = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const role = isAuthenticated.role;
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [availableProductList, setAvailableProduct] = useState([]);
  const [totalProduct, setTotalProduct] = useState(0);
  const [productQuantities, setProductQuantities] = useState({});
  const [updatePrice, setUpdatePrice] = useState({});
  const [credentials, setCredentials] = useState({ name: "" });
  const [availableNotFound, setAvailableNotFound] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isPrice, setIsPrice] = useState({});
  const [productID, setProductID] = useState({});
  const [credentials1, setCredentials1] = useState({ price: "" });
  const { id } = useParams();
  const itemsPerPage = 8;
  useEffect(() => {
    availableProduct(credentials, id);
  }, [currentPage]);
  const availableProduct = async (fetchCredentials, site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/products/availableProductList?page=${currentPage}&limit=${itemsPerPage}&site=${site}&role=${role}&name=${fetchCredentials.name}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.inventory.length > 0) {
        setAvailableNotFound(false);
        setAvailableProduct(json.inventory);
      } else {
        setAvailableProduct([]);
        setAvailableNotFound(true);
      }
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const handleInput = (e) => {
    setCurrentPage(1);
    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      availableProduct(updatedCredentials, id);
      return updatedCredentials;
    });
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(
        `${url}/api/inventory/updateAvailableQuantity`,
        {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            productQuantities,
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        setIsLoading(false);
        setProductQuantities("");
        setTotalProduct("");
        availableProduct(credentials, id);
        notification.success({
          message: "Success",
          description: data.message,
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: data.error,
          duration: 3,
        });
      }
      // Reset form or handle success as needed
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };

  const handleIncrement = (id) => {
    const updatedQuantities = { ...productQuantities };

    updatedQuantities[id] = (updatedQuantities[id] || 0) + 1;
    setProductQuantities(updatedQuantities);

    updateTotalProduct(updatedQuantities);
  };

  const handleDecrement = (id) => {
    const updatedQuantities = { ...productQuantities };
    updatedQuantities[id] = (updatedQuantities[id] || 0) - 1;
    setProductQuantities(updatedQuantities);
    updateTotalProduct(updatedQuantities);
  };

  const updateTotalProduct = (quantities) => {
    const total = Object.values(quantities).reduce(
      (acc, val) => acc + Math.abs(val),
      0
    );
    setTotalProduct(total);
  };
  const handleSubmitPrice = async (e) => {
    try {
      e.preventDefault();
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(`${url}/api/products/updateProductPrice`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id: productID,
          price: isPrice,
        }),
      });
      const data = await response.json();
      console.log(data, "update data");
      if (data.success) {
        setIsLoading(false);
        setIsPrice(isPrice);
        notification.success({
          message: "Success",
          description: data.message,
          duration: 3,
        });
        availableProduct(credentials, id);
        setIsModalOpen2(false);
      } else {
        notification.error({
          message: "Failed",
          description: data.error,
          duration: 3,
        });
        setIsModalOpen2(false);
      }
      // Reset form or handle success as needed
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handlePrice = async (id, price) => {
    setProductID(id);
    setIsPrice(price);
    setIsModalOpen2(true);
  };
  const handleChangeInput = (e) => {
    setIsPrice(e.target.value);
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/dashboard">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Available Products</h3>
                  <p>
                    Select product quantities and click open locker to take out
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* row */}
        <div className="main-containerbox">
          <div className="data-box">
            <div className="top-heading-data">
              <div className="row">
                <div className="col-md-5"></div>
                <div className="col-md-2 total-row">
                  <p className="total-text text-center  mx-2">Total</p>
                  <p className="total-counter">
                    {totalProduct ? totalProduct : "0"}
                  </p>
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control search-box"
                    id="fuserName"
                    name="name"
                    placeholder="Search.."
                    value={credentials.name}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-md-2">
                  <button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className="btn btn-prim"
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>
                    Open Locker
                  </button>
                </div>
              </div>
            </div>

            <div className="booked-container-details mt-3 text-center">
              {availableProductList.length > 0 &&
                availableProductList.map((item, key) => (
                  <>
                    <div className="booked-container">
                      <div className="booked-data">
                        <div className="product-images">
                          <img
                            src={item.image ? item.image : Mask}
                            alt="product-image"
                          />
                        </div>
                        <div className="product-count">
                          <p>6 in Locker 3</p>
                        </div>
                        <div className="product-name">
                          <h5 className="text-capitalize ">
                            {item.name.split(" ").length > 4
                              ? `${item.name.split(" ").slice(0, 3).join(" ")}`
                              : item.name}
                          </h5>
                        </div>
                        <div className="d-flex justify-content-between flex-row gap-2 mt-3">
                          <p
                            style={{
                              fontSize: "12px",
                              fontWeight: "700",
                            }}
                          >
                            ${item.price ? item.price : "0"}.00
                          </p>
                          <div className="product-cart">
                            <div className="d-flex justify-content-center gap-2">
                              <img
                                src={MinusButton}
                                onClick={() => handleDecrement(item._id)}
                              />
                              <p>
                                {productQuantities[item._id]
                                  ? productQuantities[item._id]
                                  : "0"}
                              </p>
                              <img
                                src={PlusButton}
                                onClick={() => handleIncrement(item._id)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <button
                              onClick={() => handleIncrement(item._id)}
                              className="btn btn-prim btn-increment"
                            >
                              Open Locker
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {availableNotFound && (
              <div className="row ">
                <div className="col-12">
                  <p className="text-center text-danger">No data found.</p>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* available products */}
        <div className="table-num mt-3">
          {availableProductList.length > 0 && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={totalPages}
              forcePage={currentPage - 1} // react-paginate uses zero-based indexing
              onPageChange={handlePageChange}
              containerClassName={" dig-num"}
              pageClassName={"dig-num"}
              previousClassName={"num-btns"}
              nextClassName={"num-btns"}
              disabledClassName={"pagination-disabled"}
              activeClassName={"pagination-active"}
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen2}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-4 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#fff" }}
        >
          <div className="d-flex justify-content-end align-items-center flex-row">
            <img
              src={CrossIcon}
              alt="cross"
              onClick={() => setIsModalOpen2(false)}
            />
          </div>

          <div
            className="p-2 rounded-2 w-sm-25  " //h-75
            style={{ background: "#fff" }}
          >
            <form onSubmit={handleSubmitPrice}>
              <div className="d-flex flex-column">
                <div>
                  <label
                    htmlFor="price"
                    className="form-label"
                    style={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    Price
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="price"
                    name="price"
                    placeholder="Enter Price.."
                    onChange={handleChangeInput}
                    value={isPrice}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center my-3">
                <button
                  type="submit"
                  disabled={isLoading}
                  href=""
                  className="btn btn-prim w-auto ms-5"
                >
                  <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Products;
