import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import styles from "./style.module.css";
import SearchIcon from "../../../images/search.png";
import FilterIcon from "../../../images/filter.png";
import { Link, useNavigate } from "react-router-dom";
import Edit from "../../../assets/icons/edit-ic.svg";
import Delete from "../../../assets/icons/delete-ic.svg";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Button, Popconfirm, message } from "antd";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import Images from "../modal/Images";
const List = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [qrTypes, setQrTypes] = useState([]);
  const [site, setsite] = useState(isAuthenticated.site);
  const [role, setrole] = useState(isAuthenticated.role);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [credentials, setCredentials] = useState({ type: "all", name: "" });
  const handleInput = (e) => {
    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      fetchData(updatedCredentials);
      return updatedCredentials;
    });
  };
  useEffect(() => {
    fetchData(credentials);
    // getTypes();
  }, [currentPage]);
  const getTypes = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/type/qr/types`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setQrTypes(json);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData = async (fetchCredentials) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(
        `${url}/api/l_bank/lockerBankList?page=${currentPage}&limit=${itemsPerPage}&name=${fetchCredentials.name}&type=${fetchCredentials.type}&site=${site}&role=${role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.locker_bank_data.length > 0) {
        setData(json.locker_bank_data);
        setIsDisplay(true);
      } else {
        setIsNotFound(true);
      }
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Delete it!",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(
          `${url}/api/l_bank/delete/lockerBank/${id}`,
          {
            mode: "cors",
            method: "DELETE",
            headers: headers,
          }
        );
        if (response.ok) {
          fetchData(credentials); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "Locker Bank deleted successfully!", "success");
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const openModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImageUrl("");
  };
  const formattedDate = (date) => {
    if (!date) {
      // If date is empty or null, use the current date
      date = new Date();
    }
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  const handleResetBtn = () => {
    const updatedCredentials = {
      name: "",
      type: "all",
    };
    // Update state and then call fetchData
    setCredentials(updatedCredentials);
    setCurrentPage(1);
    // Call fetchData with the updated credentials
    fetchData(updatedCredentials);
  };
  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap">
                <h3>Locker Bank</h3>
                <p>List of all the locker bank in the system</p>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="filter-right-sec">
                <div className="filter-wrap">
                  <div className="filter-box">
                    <img
                      src={FilterIcon}
                      alt=""
                      onClick={toggleFilterSection}
                    />
                  </div>
                </div>
                <Link to="/locker/bank/add" className="btn btn-prim w-auto">
                  Add Locker Bank
                </Link>
              </div>
            </div>
          </div>
          {isFilterVisible && (
            <>
              <motion.div
                className={`row mt-5 filter-section`}
                initial={{ opacity: 0 }} // Initial state (hidden)
                animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
                transition={{ duration: 1.2, delay: 0.1 }}
              ></motion.div>
            </>
          )}
        </div>
        {/* row */}
        <div className="table-wrap">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                          <th>qr</th>
                          <th>site</th>
                          <th>created</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <>
                            <tr>
                              <td
                                colSpan={4}
                                className="text-center"
                                style={{ background: "transparent" }}
                              >
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        {isDisplay && data.length > 0
                          ? data.map((item, i) => (
                              <motion.tr
                                key={item._id}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1.2, delay: i * 0.1 }}
                              >
                                <td>
                                  <div className="usr_det_tb">
                                    <div className="img_wrap_qr_tb">
                                      {/* <img
                                className="not_prof_img"
                                src={`/${item.qrcode}`}
                                alt=""
                              /> */}
                                      {item.qrcode && item.qrcode !== "" && (
                                        <>
                                          <img
                                            className={`not_prof_img`}
                                            src={`${item.qrcode}`}
                                            onClick={() =>
                                              openModal(`/${item.qrcode}`)
                                            }
                                            alt=""
                                          />
                                          <Images
                                            isOpen={isModalOpen}
                                            imageUrl={selectedImageUrl}
                                            onClose={closeModal}
                                          />
                                        </>
                                      )}
                                    </div>
                                    <span>{item.name}</span>
                                  </div>{" "}
                                </td>
                                <td>{item.siteName}</td>
                                <td>{formattedDate(item.createdAt)}</td>
                                <td className="text-end">
                                  <div className="d-flex justify-content-end gap-2">
                                    <button type="button" className="btn">
                                      <img src={Edit} alt="edit" />
                                    </button>
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={() => handleDelete(item._id)}
                                    >
                                      <img src={Delete} alt="delete" />
                                    </button>
                                    <Link
                                      to={`/assign/locker/bank/${item._id}`}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-success tb-btn"
                                      >
                                        Assign Locker Bank
                                      </button>
                                    </Link>

                                    <Link to={`/screen/view/${item._id}`}>
                                      <button
                                        type="button"
                                        className="btn btn-success tb-btn"
                                      >
                                        Start Screen
                                      </button>
                                    </Link>
                                    <Link to={`/view/containers/${item._id}`}>
                                      <button
                                        type="button"
                                        className="btn btn-primary tb-btn"
                                      >
                                        Locker Profile
                                      </button>
                                    </Link>
                                    <Link to={`/configure/screen/${item._id}`}>
                                      <button
                                        type="button"
                                        className="btn btn-success tb-btn"
                                      >
                                        Configure Screen
                                      </button>
                                    </Link>
                                  </div>
                                </td>
                              </motion.tr>
                            ))
                          : isNotFound && (
                              <tr>
                                <td colSpan={7} className="text-center">
                                  <p className="text-center text-danger">
                                    No data found.
                                  </p>
                                </td>
                              </tr>
                            )}
                      </tbody>
                    </table>
                  </div>
                  <div className="table-num">
                    {data.length > 0 && (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={totalPages}
                        forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                        onPageChange={handlePageChange}
                        containerClassName={" dig-num"}
                        pageClassName={"dig-num"}
                        previousClassName={"num-btns"}
                        nextClassName={"num-btns"}
                        disabledClassName={"pagination-disabled"}
                        activeClassName={"pagination-active"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default List;
