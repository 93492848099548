import React, { useState, useEffect } from "react";
import { StringeeUtil, StringeeClient, StringeeCall2 } from "stringee";
const url = process.env.REACT_APP_APP_BACK_URL;
const Agents = () => {
  const [stringeeClient, setStringeeClient] = useState(null);
  const [incomingCalls, setIncomingCalls] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [sdkVersion, setSdkVersion] = useState("");
  useEffect(() => {
    // Set stringeeClient in state
    if (stringeeClient) {
      stringeeClient.on("incomingcall", (incomingCall) => {
        setIncomingCalls((prevCalls) => [...prevCalls, incomingCall]);
      });
    }
   
  }, [stringeeClient]);
  useEffect(() => {
    setSdkVersion(
      StringeeUtil.version().version +
        "_build_" +
        StringeeUtil.version().build +
        "_sample_1"
    );
    getAccessToken();
  }, []);
  const getAccessToken = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/auth/get-token?userId=imran`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        setAccessToken(json.token);
        console.log(json.token);
        const client = new StringeeClient([
          "wss://v1.stringee.com:6899/",
          "wss://v2.stringee.com:6899/",
        ]);

        client.on("authen", (res) => {
          if (res.r === 0) {
            setAccessToken(""); // Reset access token input field
          }
        });
        client.connect(json.token); // Connect using the generated token
        setStringeeClient(client);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleAnswer = (callId) => {
    const incomingCall = incomingCalls.find((call) => call.id === callId);
    if (incomingCall) {
      incomingCall.answer();

      incomingCall.on("addlocalstream", (stream) => {
        // Handle local stream if needed
      });

      incomingCall.on("addremotestream", (stream) => {
        // Handle remote stream if needed
      });
    }
  };

  const handleHangup = (callId) => {
    const incomingCall = incomingCalls.find((call) => call.id === callId);
    if (incomingCall) {
      incomingCall.hangup();
      setIncomingCalls((prevCalls) =>
        prevCalls.filter((call) => call.id !== callId)
      );
    }
  };

  return (
    <div>
      <h1>Agent Page</h1>
      <h2>Incoming Calls</h2>
      <ul>
        {incomingCalls.map((call) => (
          <li key={call.id}>
            Call from: {call.from}
            <button onClick={() => handleAnswer(call.id)}>Answer</button>
            <button onClick={() => handleHangup(call.id)}>Hang Up</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Agents;
