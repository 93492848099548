import React, { useEffect, useRef, useState } from "react";
import { BeakerIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";
import Header from "../../partials/LockerHeader";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import loaderIcon from "../../../assets/icons/Spinner-1s-200px.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../index.css";
import styles from "./style.module.css";
import "./couresel.css";
import { Link, useNavigate } from "react-router-dom";
import PlusButton from "../../../assets/new-design/plus-btn.svg";
import MinusButton from "../../../assets/new-design/minus-btn.svg";
import yeslocker from "../../../assets/new-design/locker.png";
import SendIcon from "../../../assets/icons/send_icon.svg";
import CrossIcon from "../../../assets/icons/cross_icon.svg";
import NoImage from "../../../images/new images/no-photo.png";
import OpenLocker from "../../../images/open_locker.png";
import { useParams } from "react-router-dom";
import { notification } from "antd";
import GoBack from "../../../assets/new-design/go-back.svg";
import AddToCart from "../../../assets/new-design/add-cart.svg";
import Modal from "../../partials/Modal";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../Redux/Authentication";
import Slider from "react-slick";
import { useIdleTimer } from "react-idle-timer";
import io from "socket.io-client";

const url = process.env.REACT_APP_APP_BACK_URL;

const socket = io.connect(url);
const LockerPage = () => {
  const streaming_url = process.env.REACT_APP_STREAMIN_SOCKET_URL;
  let { slug } = useParams();
  slug = slug.toLowerCase();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDisplay, setIsDisplay] = useState(false);
  const [totalProduct, setTotalProduct] = useState(0);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pin, setPin] = useState(false);
  const [lockerId, setLockerId] = useState("");
  const [getLocker, setLocker] = useState([]);
  const isAuthenticated = useSelector((state) => state.user);
  const [user, setUser] = useState({});
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [quantity, setQuantity] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  const locker = queryParams.get("locker");
  const position = parseInt(queryParams.get("position")) || 0;

  const [credentials, setCredentials] = useState({
    number: "",
  });

  useEffect(() => {
    getSelectedLocker(locker);
    socket.on("updateProductquantity", (data) => {
      getSelectedLocker(locker);
    });
  }, [locker]);
  const [selectedLocker, setSelectedLocker] = useState(null);

  const getSelectedLocker = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/locker/getSelected/locker/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        setSelectedLocker(json.locker);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [productQuantities, setProductQuantities] = useState({});
  const owlRef = useRef(null);
  const sliderRef = useRef(null);
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(5); // Go to the 6th slide (0-based index)
    }
  }, []);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  // const addToCart = (productId, quantity) => {
  //   if (productId !== "") {
  //     const itemIndex = cart.findIndex((item) => item.productId === productId);

  //     if (itemIndex !== -1) {
  //       const updatedCart = [...cart];
  //       updatedCart[itemIndex].quantity += quantity;
  //       setCart(updatedCart);
  //     } else {
  //       setCart([...cart, { productId, quantity }]);
  //     }
  //     console.log(cart);
  //   } else {
  //     notification.error({
  //       message: "Failed",
  //       description: "No product assign to this locker!",
  //       duration: 3,
  //     });
  //   }
  // };
  const addToCart = (productId, quantityToAdd, defaultQuantity) => {

    const updatedQuantities = { ...productQuantities };
    if (updatedQuantities[productId] >= 1) {
      if (productId !== "" && updatedQuantities[productId] <= defaultQuantity) {
        const itemIndex = cart.findIndex(
          (item) => item.productId === productId
        );

        if (itemIndex !== -1) {
          const updatedCart = [...cart];
          updatedCart[itemIndex].quantity = updatedQuantities[productId];
          setCart(updatedCart);
        } else {
          setCart([
            ...cart,
            { productId, quantity: updatedQuantities[productId] },
          ]);
        }
        notification.success({
          message: "Success",
          description:
            "x" + updatedQuantities[productId] + " Products Added to Cart!",
          duration: 3,
        });
        updatedQuantities[productId] = 0;
        setProductQuantities(updatedQuantities);
      
      } else if (updatedQuantities[productId] >= defaultQuantity) {
        notification.error({
          message: "Failed",
          description: " Please select only " + defaultQuantity + " items",
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: "No product assigned to this locker!",
          duration: 3,
        });
      }
    } else {
      notification.error({
        message: "Failed",
        description: "Please add product quantity",
        duration: 3,
      });
    }
  };
  const removeFromCart = (productId) => {
    const updatedCart = cart.filter((item) => item.productId !== productId);
    setCart(updatedCart);
  };

  const incrementQuantity = (productId, quantity) => {
    const updatedQuantities = { ...productQuantities };
    updatedQuantities[productId] = (updatedQuantities[productId] || 0) + 1;
    if (updatedQuantities[productId] > quantity) {
      notification.error({
        message: "Failed",
        description: "You can select only " + quantity + " items",
        duration: 3,
      });
      return; // Exit the function early
    }
    setProductQuantities(updatedQuantities);
    updateTotalProduct(updatedQuantities);
  };

  const decrementQuantity = (productId) => {
    const updatedQuantities = { ...productQuantities };
    if (updatedQuantities[productId] >= 1) {
      updatedQuantities[productId] = (updatedQuantities[productId] || 0) - 1;
      setProductQuantities(updatedQuantities);
      updateTotalProduct(updatedQuantities);
      // if (productId !== "") {
      //   const updatedCart = [...cart];
      //   const itemIndex = updatedCart.findIndex(
      //     (item) => item.productId === productId
      //   );
      //   if (itemIndex !== -1 && updatedCart[itemIndex].quantity > 1) {
      //     updatedCart[itemIndex].quantity--;
      //   } else {
      //     updatedCart.splice(itemIndex, 1);
      //   }

      //   setCart(updatedCart);
      // } else {
      //   notification.error({
      //     message: "Failed",
      //     description: "No product assigned to this locker!",
      //     duration: 3,
      //   });
      // }
    } else {
      notification.error({
        message: "Failed",
        description: "Please add product quantity",
        duration: 3,
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        owlRef.current.prev();
      } else if (e.key === "ArrowRight") {
        owlRef.current.next();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  function closeModal() {
    setIsOpen(false);
  }
  const handleOnIdle = (event) => {
    navigate(-1);
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    console.log("user did something", event);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 1500,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(`${url}/api/locker/site/lockers/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.locker_data.length > 0) {
        setLocker(json.locker_data);
        console.log(json.locker_data, "json.locker_data");
        // getSelectedLocker(locker);
        setIsDisplay(true);
        setIsLoading(false);
      } else {
        setIsNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateTotalProduct = (quantities) => {
    const total = Object.values(quantities).reduce(
      (acc, val) => acc + Math.abs(val),
      0
    );
    setTotalProduct(total);
  };
  // check out page
  const checkOut = async (e) => {
    if (cart.length === 0) {
      notification.error({
        message: "Failed",
        description: "Please add to cart your quantity and then checkout!",
        duration: 3,
      });
    } else {
      try {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(`${url}/api/locker/addToCart`, {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            data: cart,
          }),
        });
        setIsLoading(false);
        const json = await response.json();
        if (json.success) {
          navigate(`/${slug}/locker-cart/${json.checkId}`);
        }
      } catch (error) {
        notification.error({
          message: "Failed",
          description: "something went wrong!",
          duration: 3,
        });
      }
    }
  };
  // check locker open request
  const fetchUserData = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(`${url}/api/auth/user/edit/${id}`, {
        mode: "cors",
        method: "get",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.success) {
        // setUser({ ...json.user });
        dispatch(loginSuccess(json.user));
      } else {
        notification.error({
          message: "Failed",
          description: json.message,
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "Failed",
        description: error.message,
        duration: 3,
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <img src={loaderIcon} alt="" />
      </div>
    );
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: position,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className={styles.heading}>
        <div className="body-wrap">
          <div className="container-fluid px-0 ">
            <div className="general-dashboard">
              <div className="container-fluid ">
                <Header />
                <div className="add-to-cart">
                  <div className="col-lg-12 my-auto d-flex justify-content-between flex-row align-items-center px-3">
                    <div className="user-wrap mt-4">
                      <Link to={`/${slug}/locker-page`}>
                        <img src={GoBack} width={100} />
                      </Link>
                    </div>
                    <div>
                      <img
                        src={AddToCart}
                        onClick={() => checkOut()}
                        width={100}
                      />
                    </div>
                  </div>
                </div>
                <div className="row my-5">
                  <div className="col-lg-12 locker-side  ">
                    <div className="row gap locker-row mt-3 overflow-auto">
                      <div className="d-flex justify-content-center">
                        {/* {selectedLocker && (
                          <div className="p-2 selected-locker-div-container">
                            <div className="border border-2 border-dark h-100 w-100 selected-locker-div">
                              <div className="d-flex justify-content-end">
                                <span className="px-3 py-1">
                                  {selectedLocker.number}
                                </span>
                              </div>
                              <div className="px-3">
                                <div
                                  style={{
                                    height: "150px",
                                    position: "relative",
                                  }}
                                  className="border rounded overflow-hidden relative"
                                >
                                  <img
                                    className="mx-auto w-100 h-100"
                                    style={{
                                      position: "relative",
                                      objectFit: "cover",
                                    }}
                                    src={
                                      selectedLocker.product_image
                                        ? selectedLocker.product_image
                                        : NoImage
                                    }
                                    alt=""
                                  />
                                  <div
                                    className="bg-black w-100"
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      zIndex: "1000",
                                      opacity: "0.5",
                                      minHeight: "2rem",
                                      minWidth: "10px",
                                      width: "100%",
                                      backgroundColor: "black",
                                    }}
                                  ></div>
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "2",
                                      color: "white",
                                      zIndex: "1000",
                                      right: "0",
                                      padding: "0.3rem",
                                    }}
                                  >
                                    {" "}
                                    $
                                    {selectedLocker.product_price
                                      ? selectedLocker.product_price
                                      : 0}
                                  </span>
                                </div>
                                <h6
                                  className="text-capitalize my-2 text-center"
                                  style={{ fontSize: "12px" }}
                                >
                                  {selectedLocker.product_name
                                    ? selectedLocker.product_name
                                        .split(" ")
                                        .slice(0, 2)
                                        .join(" ")
                                    : "no name"}{" "}
                                </h6>
                              </div>
                              <span
                                className="my-2 text-center"
                                style={{ fontSize: "12px" }}
                              >
                                {selectedLocker.product_unit
                                  ? selectedLocker.product_unit
                                  : 0}{" "}
                                units available
                              </span>
                              {selectedLocker.product ? (
                                <>
                                  <div className="">
                                    <div className="d-flex justify-content-center gap-2">
                                      <a
                                        onClick={() =>
                                          decrementQuantity(
                                            selectedLocker.product
                                          )
                                        }
                                      >
                                        <img src={MinusButton} />
                                      </a>
                                      <p>
                                        {" "}
                                        {productQuantities[
                                          selectedLocker.product
                                        ]
                                          ? productQuantities[
                                              selectedLocker.product
                                            ]
                                          : "0"}
                                      </p>
                                      <a
                                        onClick={() =>
                                          incrementQuantity(
                                            selectedLocker.product
                                          )
                                        }
                                      >
                                        <img src={PlusButton} />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="">
                                    <div className="d-flex justify-content-center flex-row gap-1">
                                      <button
                                        className="btn btn-prim-slider btn-transparent-slider w-auto"
                                        onClick={(e) =>
                                          addToCart(
                                            selectedLocker.product,
                                            0,
                                            selectedLocker.product_unit
                                          )
                                        }
                                      >
                                        Add to Cart
                                      </button>
                                      <button
                                        className="btn btn-prim-slider w-auto"
                                        onClick={() => checkOut()}
                                      >
                                        Checkout
                                      </button>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              <div className="p-3">
                                {[1, 1, 1, 1].map(() => (
                                  <div
                                    className="w-100 my-1"
                                    style={{
                                      background: "#A9A9A9",
                                      height: "3px",
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="locker-sliders-container">
                  <div
                    className="container "
                    style={{ paddingInline: "10px", position: "relative" }}
                  >
                    <Slider
                      {...settings}
                      className="mx-auto "
                      style={{ width: "90%", position: "relative" }}
                    >
                      {getLocker.map((item, key) => (
                        <div
                          className="p-2 locker-div-container mx-auto"
                          onClick={() => getSelectedLocker(item._id)}
                        >
                          <div className="locker-div border border-1 border-dark h-100 w-100 d-flex flex-column justify-space-between">

                            <div className="d-flex justify-content-end">
                              <span className="px-3 py-1">{item.number}</span>
                            </div>

                            <div className="d-flex justify-content-start px-2 pb-2">
                              <img src={OpenLocker} alt="" style={{width : '8%'}} />
                               
                            </div>
                            <div className="px-1" style={{ flexGrow: "1" }}>
                              <div
                                style={{
                                  position: "relative",
                                }}
                                className="border rounded overflow-hidden relative"
                              >
                                <img
                                  className="mx-auto w-100 rounded"
                                  style={{
                                    minHeight: "100px",
                                    objectFit: "cover",
                                    position: "relative",
                                  }}
                                  src={
                                    item.isImageExists
                                      ? item.product_image
                                      : NoImage
                                  }
                                  alt="product-image"
                                />
                                <div
                                  className="w-100"
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    zIndex: "1000",
                                    opacity: "0.5",
                                    minHeight: "2rem",
                                    minWidth: "10px",
                                    width: "100%",
                                    backgroundColor: "#01306a",
                                  }}
                                ></div>
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "0",
                                    color: "white",
                                    zIndex: "1000",
                                    padding: "0.3rem",
                                    fontSize: "11px",
                                    fontWeight: "700",
                                  }}
                                >
                                  ${item.product_price ? item.product_price : 0}
                                </span>
                              </div>
                              <h6
                                className="text-capitalize my-2 text-center text-break"
                                style={{ fontSize: "12px" }}
                              >
                                {item.product_name.length > 3
                                  ? item.product_name
                                      .split(" ")
                                      .slice(0, 2)
                                      .join(" ")
                                  : "no name"}{" "}
                              </h6>
                              <div className="locker-content">
                                <span
                                  className="my-2 text-center d-flex justify-content-center text-muted"
                                  style={{ fontSize: "8px" }}
                                >
                                  {item.product_unit ? item.product_unit : 0}{" "}
                                  Units Available
                                </span>
                                <div className="">
                                  <div className="d-flex justify-content-center gap-2">
                                    <a
                                      onClick={() =>
                                        decrementQuantity(item.product)
                                      }
                                    >
                                      <img src={MinusButton} width={15} />
                                    </a>
                                    <p
                                      style={{
                                        fontSize: "9px",
                                      }}
                                    >
                                      {" "}
                                      {productQuantities[item.product]
                                        ? productQuantities[item.product]
                                        : "0"}
                                    </p>
                                    <a
                                      onClick={() =>
                                        incrementQuantity(
                                          item.product,
                                          item.product_unit
                                        )
                                      }
                                    >
                                      <img src={PlusButton} width={15} />
                                    </a>
                                  </div>
                                </div>
                                <div className="">
                                  <div className="d-flex justify-content-center flex-row gap-1">
                                    <button  className="btn btn-prim-slider btn-transparent-slider w-auto rounded-0" style = {{background: "#DDD"}}
                                      onClick={(e) =>
                                        addToCart(
                                          item.product,
                                          0,
                                          item.product_unit
                                        )
                                      }
                                    >
                                      Add to Cart
                                    </button>
                                    <button
                                      className="btn btn-prim-slider w-auto rounded-0"
                                      onClick={() => checkOut()}
                                    >
                                      Checkout
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="px-3 pb-3">
                              {[1, 1, 1].map(() => (
                                <div
                                  className="w-100 my-1"
                                  style={{
                                    background: "#A9A9A9",
                                    height: "3px",
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onClose={closeModal}
          className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
          shouldCloseOnOverlayClick={false}
          style={{
            content: {
              fontFamily: "Arial",
              fontWeight: "800",
              fontSize: "30px",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
            },
          }}
        >
          <div className="d-flex justify-content-between align-items-center flex-row">
            <img src={SendIcon} alt="send" height={40} width={40} />
            <img
              src={CrossIcon}
              alt="cross"
              onClick={closeModal}
              height={37}
              width={37}
            />
          </div>

          <div className="modal-body">
            <div
              className="p-3 w-sm-50 h-sm-50 col-12 rounded-3"
              style={{ background: "#fff" }}
            >
              <div>
                <h5 className="fs-6 m-2" style={{ fontWeight: "700" }}>
                  Please Enter Your Pin
                </h5>
              </div>
              <div
                className="p-1 rounded-2 w-sm-25  " //h-75
                style={{ background: "#fff" }}
              >
                <div className="d-flex flex-column">
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      id="number"
                      name="number"
                      value={credentials.number}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center flex-row flex-wrap gap-2 mt-4">
                  <button
                    className="  border border-2 border-blue rounded-pill px-3 py-2 shadow "
                    style={{
                      fontWeight: "700",
                      fontFamily: "Arial",
                      fontSize: "12px",
                      textDecoration: "none",
                      background: "#ff0000",
                      color: "#fff",
                    }}
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className=" border border-2 border-blue rounded-pill px-3 py-2 shadow "
                    style={{
                      fontWeight: "700",
                      fontFamily: "Arial",
                      fontSize: "12px",
                      textDecoration: "none",
                      background: "#012267",
                      color: "#fff",
                    }}
                  >
                    Enter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default LockerPage;
