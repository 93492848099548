import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from "../../../assets/icons/icon-back.svg";
import UploadIcon from "../../../images/icon-upload.png";
import { useSelector } from "react-redux";
import { notification } from "antd";
import styles from "./style.module.css";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
const Edit = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const site = isAuthenticated.site;
  const role = isAuthenticated.role;
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const [appendedHTML, setAppendedHTML] = useState("");
  useEffect(() => {
    getCabnet(id);
  }, [id]);
  useEffect(() => {
    getLockerSize();
    getSite();
  }, []);

  const [credentials, setCredentials] = useState({
    name: "",
    size: "",
    port: "",
    status: "",
    site: "",
    relay: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [getSites, setSite] = useState([]);
  const [getLockerS, setLockerSize] = useState([]);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const getSite = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/sites/getSite/${site}?role=${role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();

      if (json.success) {
        setSite(json.site);
      }

      // setCredentials({ ...json.site });
    } catch (error) {
      console.error(error);
    }
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      const response = await fetch(`${url}/api/cabnet/updateCabnet/${id}`, {
        mode: "cors",
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          name: credentials.name,
          size: credentials.size,
          port: credentials.port,
          status: credentials.status,
          site: credentials.site,
          relay: credentials.relay,
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        notification.success({
          message: "Success",
          description: "Cabnet Updated Successfully",
          duration: 3,
        });
      } else {
        setIsLoading(false);
        notification.error({
          message: "Failed",
          description: json.error,
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLockerSize = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/locker/getLockerSize`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setLockerSize(json.locker_size);
    } catch (error) {
      console.error(error);
    }
  };

  // get site data
  // get role
  const getCabnet = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/cabnet/getCabnetData/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setCredentials({ ...json.cabnet });
    } catch (error) {
      console.error(error);
    }
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/cabnets">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Update Cabnet</h3>
                  <p>List of all the cabnets registered in the system</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Cabnet Name</h4>
                      <p>Enter the name of the cabnet</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="sitec"
                        placeholder="Site Name"
                        name="name"
                        value={credentials?.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Size</h4>
                      <p>Select size of Cabnet</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="size"
                        value={credentials.size}
                        onChange={handleInput}
                        required
                      >
                        <option value="">Choose Size</option>
                        {getLockerS.map((per) => (
                          <option key={per._id} value={per._id}>
                            {per.size}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Site</h4>
                      <p>Select site of locker bank</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="site"
                        value={credentials.site}
                        onChange={handleInput}
                        required
                      >
                        <option value="" selected>
                          Choose Site
                        </option>
                        {getSites &&
                          getSites.map((per) => (
                            <option key={per._id} value={per._id}>
                              {per.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Status</h4>
                      <p>Select the status of the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="status"
                        required
                        value={credentials.status}
                        onChange={handleInput}
                      >
                        <option value="">Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">In Active</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Cabnet Port</h4>
                      <p>Enter the PORT address of the cabnet</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        placeholder="5000"
                        name="port"
                        required
                        value={credentials.port}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Relay#</h4>
                      <p>Enter the relay number of locker</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="relay"
                        name="relay"
                        placeholder="Enter realy"
                        value={credentials.relay}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <a href="" className="btn btn-prim btn-transparent w-auto">
                    Reset Form
                  </a>
                  <button
                    type="submit"
                    className="btn btn-prim w-auto ms-5"
                    disabled={isLoading}
                  >
                    {isLoading ? "Updating..." : "Update Cabnet"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Edit;
