import React from "react";
import QrCode from "../../../assets/new-design/scanner.svg";
import { useSelector } from "react-redux";
const Qrcode = () => {
  const isAuthenticated = useSelector((state) => state.user);
  return (
    <div className="qrcode-content">
      <div className="qrcode-content-wrap">
        <div className="qr-code-scanning d-flex justify-content-center flex-column gap-4">
          <img src={isAuthenticated.qr_image} />
          <div className="scanner-buttons d-flex justify-content-between flex-row gap-3">
            <button className="btn btn-prim-scan btn-transparent-scan ">
              Download
            </button>
            <button className="btn btn-prim-scan px-5">Share</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Qrcode;
