import React, { useEffect, useState } from "react";
import Header from "../../partials/LockerHeader";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Mask from "../../../assets/new-design/Mask-group.png";
import CompleteTran from "../../../assets/new-design/complete-trans.svg";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
const LockerSlider = ({ user, i }) => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;

  const handleConfirm = () => {
    setShowData(true);
  };

  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <Header />
        {showData && (
          <div
            className={`${styles.trans_complete} d-flex justify-content-center align-items-center flex-column gap-1`}
          >
            <img src={CompleteTran} width={150} />
            <h4 className="mt-5">Transaction Complete</h4>
            <p
              style={{
                color: "#999999",
              }}
            >
              {" "}
              Your transaction has been completed and you can have the products
            </p>
          </div>
        )}

        {/* row */}
        <div className="table-wrap">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div className={styles.tables_wrap}>
                  <div className={styles.table_pad}>
                    <div className="table-responsive">
                      <table className="table align-middle">
                        <thead>
                          <tr>
                            <th>Product</th>

                            <th className="text-end">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading && (
                            <>
                              <tr>
                                <td
                                  colSpan={6}
                                  className="text-center"
                                  style={{ background: "transparent" }}
                                >
                                  <HashLoader
                                    color={`#0b0d41`}
                                    loading={isLoading}
                                    size={40}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                </td>
                              </tr>
                            </>
                          )}

                          <motion.tr
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1.2, delay: i * 0.1 }}
                          >
                            <td>
                              <div className="usr_det_tb_locker">
                                <div className="img_wrap_user_tb_locker">
                                  <img src={Mask} />
                                </div>

                                <span className="text-capitalize">
                                  Motion Tracking Camera E27 - auto smart zoom
                                </span>
                              </div>{" "}
                            </td>

                            <td>
                              <p className="text-end">3</p>
                            </td>
                          </motion.tr>

                          {isNotFound && (
                            <tr>
                              <td colSpan={7} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {showData ? (
                        <div className="d-flex justify-content-center align-align-items-center ">
                          <button className="btn btn-prim w-auto px-5">
                            Continue Shopping
                          </button>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-align-items-center ">
                          <button
                            className="btn btn-prim w-auto px-5"
                            onClick={handleConfirm}
                          >
                            Confirm
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="table-num">
                      {data.length > 0 && (
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={totalPages}
                          forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                          // onPageChange={handlePageChange}
                          containerClassName={" dig-num"}
                          pageClassName={"dig-num"}
                          previousClassName={"num-btns"}
                          nextClassName={"num-btns"}
                          disabledClassName={"pagination-disabled"}
                          activeClassName={"pagination-active"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockerSlider;
