import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from "../../../assets/icons/icon-back.svg";
import trackImg from "../../../images/track-ship.png";
import styles from "./style.module.css";
import { Html5QrcodeScanner } from "html5-qrcode";
import HashLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
const ShippingTrack = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [scannerInitialized, setScannerInitialized] = useState(false);
  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const [scannerDisplay, setScannerDisplay] = useState(false);
  const [carrier, setCarrier] = useState("");
  const [trackData, setTrackData] = useState({});
  let scanner = null;
  useEffect(() => {
    if (scannerDisplay) {
      scanner = new Html5QrcodeScanner("reader", {
        qrbox: {
          width: 250,
          height: 250,
        },
        fps: 5,
      });
      scanner.render(success, error);
      setScannerInitialized(true);
    }
    function success(result) {
      scanner.clear();
      setIsLoading(true);
      fetchTrackingInfo(result);
    }
    function error(err) {
      console.warn(err);
    }
  }, [scannerDisplay, scannerInitialized]);
  const handleCarrierChange = async (e) => {
    let value = e.target.value;
    setCarrier(e.target.value);
    setTrackData({});
    if (value === "") {
      // if (scannerInitialized && scanner) {
      //     // Stop and destroy the scanner
      //     scanner.stop().then(() => {
      //       scanner.clear();
      //       scanner.destroy();
      //     });
      // }
      setScannerDisplay(false);
      setScannerInitialized(false);
    } else {
      setScannerDisplay(true);
      setScannerInitialized(false);
    }
  };
  const fetchTrackingInfo = async (result) => {
    try {
      const trackingId = result.toString();
      console.log(trackingId);
      setIsLoading(true);
      const response = await fetch(
        `${url}/api/shipping/tracking/${trackingId}/${carrier}`,
        {
          mode: "cors",
          method: "GET",
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      console.log(json);
      if (json.success) {
        setTrackData(result);
      } else {
        notification.error({
          message: "Failed",
          description: json.error,
          duration: 3,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const readerStyle = {
    padding: "0px",
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/dashboard">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Live Shipping Tracking</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <div className="row">
          <div className="col-lg-4 text-center">
            <img src={trackImg} />
          </div>
          <div className="col-lg-8">
            <div className="form-box card-box">
              <div className="col-lg-12">
                <div className="inpt-wrap">
                  <label className="text-muted">Shipping Carrier</label>
                  <select
                    name="size"
                    onChange={(e) => {
                      handleCarrierChange(e);
                    }}
                    value={carrier}
                  >
                    <option value="">Choose carrier</option>
                    <option value="usps">U.S. Postal Service</option>
                    <option value="fedex">Fedex</option>
                    <option value="ups">UPS</option>
                    <option value="dhl_express">DHL Express</option>
                    <option value="dhl_global_mail">DHL ECommerce</option>
                    <option value="dhl_ecommerce_au">
                      DHL eCommerce Australia
                    </option>
                    <option value="stamps_com">Stamps.com</option>
                  </select>
                </div>
              </div>
              {scannerDisplay && (
                <div className="col-12 scanning-section">
                  {isLoading ? (
                    <div>
                      <HashLoader
                        color={`#0b0d41`}
                        loading={isLoading}
                        size={40}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  ) : (
                    <div id="reader" style={readerStyle}></div>
                  )}
                </div>
              )}
              {trackData.length > 0 && (
                <>
                  <div className="form-group col-lg-12 col-sm-12">
                    <span className="fw-bold fs-6">Tracking Number: </span>
                    <span className="fw-bold">#{trackData.trackingNumber}</span>
                    <badge
                      className="badge bg-success p-2"
                      style={{ float: "right" }}
                    >
                      {trackData.statusDescription}
                    </badge>
                  </div>
                  <div className="form-group col-lg-12 col-sm-12">
                    <span className="fw-bold fs-6">
                      Description: {trackData.carrierStatusDescription}
                    </span>
                  </div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Loction</th>
                        <th>Desciption</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    {trackData.events.map((event, index) => (
                      <tr>
                        <td>{event.cityLocality}</td>
                        <td>{event.description}</td>
                        <td>{event.occurredAt}</td>
                      </tr>
                    ))}
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShippingTrack;
