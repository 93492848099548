import React, { useEffect, useState } from "react";
import Header from "../../partials/LockerHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import GoBack from "../../../assets/new-design/go-back.svg";
import PlusButton from "../../../assets/new-design/plus-btn.svg";
import MinusButton from "../../../assets/new-design/minus-btn.svg";
import CompleteTran from "../../../assets/new-design/complete-trans.svg";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../Redux/Authentication";
import { notification } from "antd";
import Modal from "../../partials/Modal";
import SendIcon from "../../../assets/icons/send_icon.svg";
import CrossIcon from "../../../assets/icons/cross_icon.svg";
import { Html5QrcodeScanner } from "html5-qrcode";
import NoImage from "../../../images/new images/no-photo.png";
import { useIdleTimer } from "react-idle-timer";
import "sweetalert2/dist/sweetalert2.min.css";
import loaderIcon from "../../../assets/icons/Spinner-1s-200px.svg";
import Swal from "sweetalert2";
const LockerSlider = ({ user, i }) => {
  let scanner;
  const { id } = useParams();
  let { slug } = useParams();
  slug = slug.toLowerCase();
  let navigate = useNavigate();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [displayscaner, setdisplayscaner] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState("");

  const isAuthenticated = useSelector((state) => state.user);
  const [showData, setShowData] = useState(false);
  const [completeTrans, setCompleteTrans] = useState(false);
  const [shoppingCart, setShoppingCart] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [scannerInitialized, setScannerInitialized] = useState(false);
  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const [barcode, setBarcode] = useState("");
  useEffect(() => {
    if (!scannerInitialized && displayscaner) {
      scanner = new Html5QrcodeScanner("reader", {
        qrbox: {
          width: 250,
          height: 250,
        },
        fps: 5,
      });
      scanner.render(success, error);
      setScannerInitialized(true);
    }
    function success(result) {
      scanner.clear();
      // setIsLoading(true);

      fetchUserData(result);
    }
    function error(err) {
      console.warn(err);
    }
  }, [displayscaner, scannerInitialized]);
  useEffect(() => {
    if (authenticationFailed) {
      // Reinitialize the scanner if authentication fails
      setScannerInitialized(false);
      setAuthenticationFailed(false);
    }
  }, [authenticationFailed]);
  useEffect(() => {
    let interval;
    const handleKeyDown = (evt) => {
      if (interval) clearInterval(interval);
      if (evt.code === "Enter") {
        if (barcode) fetchUserData(barcode);
        setBarcode("");
        return;
      }
      if (evt.key !== "Shift")
        setBarcode((prevBarcode) => prevBarcode + evt.key);
      interval = setInterval(() => setBarcode(""), 20);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [barcode]);
  const handleConfirm = async () => {
    try {
      if (isAuthenticated) {
        const result = await Swal.fire({
          title: "Do you want to open all lockers?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes!",
          cancelButtonText: "No",
          reverseButtons: true,
        });

        if (result.isConfirmed) {
          setShowData(true);
          let headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Access-Control-Allow-Origin", "*");
          headers.append("Access-Control-Allow-Credentials", "true");
          const response = await fetch(`${url}/api/locker/checkOutLocker`, {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              id: id,
              user_id: isAuthenticated._id,
            }),
          });
          const json = await response.json();
          console.log(json)
          if (json.success) {
            setShowData(true);
            setShowData(true);
          }
        }
      } else {
        setIsOpen(true);
        setdisplayscaner(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // const submitForm = async (id) => {
  //   try {
  //     setShowData(true);
  //     let headers = new Headers();
  //     headers.append("Content-Type", "application/json");
  //     headers.append("Access-Control-Allow-Origin", "*");
  //     headers.append("Access-Control-Allow-Credentials", "true");
  //     const response = await fetch(`${url}/api/locker/checkOutLocker`, {
  //       mode: "cors",
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify({
  //         id: id,
  //         user_id: isAuthenticated._id,
  //       }),
  //     });
  //     const json = await response.json();
  //     if (json.success) {
  //       setShowData(true);
  //     }
  //   } catch (error) {
  //     notification.error({
  //       message: "Failed",
  //       description: error.message,
  //       duration: 3,
  //     });
  //   }
  // };
  const [credentials, setCredentials] = useState({
    number: "",
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  function closeModal() {
    setIsOpen(false);
  }
  const handleOnIdle = (event) => {
    navigate(-1);
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    console.log("user did something", event);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 300,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  const handleTrans = () => {
    setCompleteTrans(true);
    setShoppingCart(false);
  };
  useEffect(() => {
    fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      const response = await fetch(`${url}/api/locker/checkOut/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setIsLoading(false);

        setData(json.locker_data);
        console.log(json.locker_data, "json.locker_data");
        setTotal(json.total);
      }
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Failed",
        description: error.message,
        duration: 3,
      });
    }
  };

  const sendPind = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/locker/checkUserPin`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          pin: credentials.number,
        }),
      });

      const json = await response.json();
      if (json.success) {
        dispatch(loginSuccess(json.user));
        setIsOpen(false);
        notification.success({
          message: "Success",
          description: "Successfully Logged In",
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: json.error,
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "Failed",
        description: error.message,
        duration: 3,
      });
    }
  };
  const fetchUserData = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(`${url}/api/auth/user/edit/${id}`, {
        mode: "cors",
        method: "get",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      setIsOpen(false);
      if (json.success) {
        notification.success({
          message: "Success",
          description: "Successfully Logged In",
          duration: 3,
        });

        dispatch(loginSuccess(json.user));
      } else {
        notification.error({
          message: "Failed",
          description: json.message,
          duration: 3,
        });
      }
    } catch (error) {
      setIsOpen(false);
      notification.error({
        message: "Failed",
        description: error.message,
        duration: 3,
      });
    }
  };
  // const [cartItems, setCartItems] = useState(user);

  const [cartItems, setCartItems] = useState([]);

  const handleIncrement = (index) => {
    console.log(data);
    const updatedCartItems = [...data];
    updatedCartItems[index].product_unit = parseInt(
      updatedCartItems[index].product_unit
    );
    updatedCartItems[index].quantity = parseInt(
      updatedCartItems[index].quantity
    );
    updatedCartItems[index].price = parseFloat(updatedCartItems[index].price);
    if (
      updatedCartItems[index].quantity >= updatedCartItems[index].product_unit
    ) {
      notification.error({
        message: "Failed",
        description:
          "You can select only " +
          updatedCartItems[index].product_unit +
          " items",
        duration: 3,
      });
      return; // Exit the function without updating state
    }
    updatedCartItems[index].quantity += 1;
    setTotal(parseInt(total) + updatedCartItems[index].price);
    updatedCartItems[index].totalprice =
      updatedCartItems[index].quantity * updatedCartItems[index].price;

    setCartItems(updatedCartItems);
  };

  const handleDecrement = (index) => {
    const updatedCartItems = [...data];
    if (updatedCartItems[index].quantity > 1) {
      updatedCartItems[index].quantity = parseInt(
        updatedCartItems[index].quantity
      );
      updatedCartItems[index].price = parseFloat(updatedCartItems[index].price);
      updatedCartItems[index].quantity -= 1;
      setTotal(parseInt(total) - updatedCartItems[index].price);
      updatedCartItems[index].totalprice =
        updatedCartItems[index].quantity * updatedCartItems[index].price;
      setCartItems(updatedCartItems);
    }
  };

  const readerStyle = {
    width: "400px",
    position: "relative",
    padding: "0px",
  };
  // const qrShade = {
  //   position: "absolute",
  //   borderWidth: "62px 124px",
  //   borderStyle: "solid",
  //   borderColor: "rgba(0, 0, 0, 0.48)",
  //   boxSizing: "border-box",
  //   inset: "0px",
  // };
  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <img src={loaderIcon} alt="" />
      </div>
    );
  }
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <Header />

        <div className="dashboard-top-row">
          <div className="row">
            <div className="my-auto px-3 mt-4">
              <div className="d-flex align-items-center my-2 gap-3 container">
                <span
                  style={{ fontWeight: 700, fontSize: "30px" }}
                  className=" w-100 text-center"
                >
                  Your Shopping Cart
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center ">
              <Link to={`/${slug}/locker-page`}>
                <img src={GoBack} width={100} />
              </Link>
            </div>
          </div>
        </div>
        {shoppingCart && (
          <>
            <div className="table-wrap">
              <div className="row">
                <div className="col-12">
                  <div className={styles.card_box}>
                    <div className={styles.tables_wrap}>
                      <div className="table-responsive">
                        <table className="table align-middle">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Locker No</th>
                              <th>Unit Price</th>
                              <th>Quantity</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoading && (
                              <>
                                <tr>
                                  <td
                                    colSpan={6}
                                    className="text-center"
                                    style={{ background: "transparent" }}
                                  >
                                    <HashLoader
                                      color={`#0b0d41`}
                                      loading={isLoading}
                                      size={40}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  </td>
                                </tr>
                              </>
                            )}

                            {data.length > 0
                              ? data.map((item, i) => (
                                  <motion.tr
                                    key={item._id}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{
                                      duration: 1.2,
                                      delay: i * 0.1,
                                    }}
                                  >
                                    <td>
                                      <div className="usr_det_tb_locker">
                                        <div className="img_wrap_user_tb_locker">
                                          <img
                                            src={
                                              item.isImageExists
                                                ? item.image
                                                : NoImage
                                            }
                                          />
                                        </div>
                                        <span className="text-capitalize ">
                                          {item.name}
                                        </span>
                                      </div>{" "}
                                    </td>
                                    <td>{item.locker}</td>
                                    <td>${item.price}</td>
                                    <td>
                                      <div className="product-cart mt-3">
                                        <div className="d-flex justify-content-start gap-2">
                                          <img
                                            src={MinusButton}
                                            onClick={() => handleDecrement(i)}
                                          />
                                          <p>{item.quantity}</p>
                                          <img
                                            src={PlusButton}
                                            onClick={() => handleIncrement(i)}
                                          />
                                        </div>
                                      </div>
                                    </td>

                                    <td>${item.totalprice}</td>
                                  </motion.tr>
                                ))
                              : isNotFound && (
                                  <tr>
                                    <td colSpan={7} className="text-center">
                                      <p className="text-center text-danger">
                                        No data found.
                                      </p>
                                    </td>
                                  </tr>
                                )}
                            <tr>
                              <td></td>
                              <td></td>

                              <td></td>
                              <td>
                                <h5>Total Price</h5>
                              </td>

                              <td>
                                <h5>${total}</h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="d-flex justify-content-end align-align-items-center ">
                          <button
                            className="btn btn-prim w-auto px-5"
                            onClick={handleTrans}
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* row */}

        {completeTrans && (
          <>
            {showData && (
              <div
                className={`${styles.trans_complete} d-flex justify-content-center align-items-center flex-column gap-1`}
              >
                <img src={CompleteTran} width={150} />
                <h4 className="mt-5">Transaction Complete</h4>
                <p
                  style={{
                    color: "#999999",
                  }}
                >
                  {" "}
                  Your transaction has been completed and you can have the
                  products
                </p>
              </div>
            )}
            {!showData && (
              <div className={""}>
                <div className="table-responsive">
                  <table className="table align-middle">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Locker</th>
                        <th className="text-end">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0
                        ? data.map((item, i) => (
                            <>
                              <tr>
                                <td
                                  colSpan={6}
                                  className="text-center"
                                  style={{ background: "transparent" }}
                                >
                                  <HashLoader
                                    color={`#0b0d41`}
                                    loading={isLoading}
                                    size={40}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                </td>
                              </tr>

                              <motion.tr
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1.2, delay: i * 0.1 }}
                              >
                                <td>
                                  <div className="usr_det_tb_locker">
                                    <div className="img_wrap_user_tb_locker">
                                      <img
                                        src={
                                          item.isImageExists
                                            ? item.image
                                            : NoImage
                                        }
                                      />
                                    </div>

                                    <span className="text-capitalize">
                                      {item.name}
                                    </span>
                                  </div>{" "}
                                </td>

                                <td>
                                  <p>{item.locker}</p>
                                </td>
                                <td>
                                  <p className="text-end">X{item.quantity}</p>
                                </td>
                              </motion.tr>
                            </>
                          ))
                        : isNotFound && (
                            <tr>
                              <td colSpan={7} className="text-center">
                                <p className="text-center text-danger">
                                  No data found.
                                </p>
                              </td>
                            </tr>
                          )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            <div className="table-wrap">
              <div className="row">
                <div className="col-12">
                  <div className={""}>
                    <div className={""}>
                      <div className={""}>
                        {showData ? (
                          <div className="d-flex justify-content-center align-align-items-center ">
                            <button
                              className="btn btn-prim w-auto px-5"
                              onClick={() => navigate("/invid/locker-page")}
                            >
                              Continue Shopping
                            </button>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center align-align-items-center ">
                            <button
                              className="btn btn-prim w-auto px-5"
                              onClick={handleConfirm}
                            >
                              Confirm & Open Lockers And Take Products
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* row */}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onClose={closeModal}
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div className="d-flex justify-content-between align-items-center flex-row">
          <img src={SendIcon} alt="send" height={40} width={40} />
          <img
            src={CrossIcon}
            alt="cross"
            onClick={closeModal}
            height={37}
            width={37}
          />
        </div>

        <div className="modal-body">
          {isLoading ? (
            <div className="text-center">
              <HashLoader
                color={`#0b0d41`}
                loading={isLoading}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            displayscaner && <div id="reader" style={readerStyle}></div>
          )}
          <div
            className="p-3 w-sm-50 h-sm-50 col-12 rounded-3"
            style={{ background: "#fff" }}
          >
            <div>
              <h5 className="fs-6 m-2" style={{ fontWeight: "700" }}>
                Please Enter Your Pin
              </h5>
            </div>
            <div
              className="p-1 rounded-2 w-sm-25  " //h-75
              style={{ background: "#fff" }}
            >
              <div className="d-flex flex-column">
                <div>
                  <input
                    type="number"
                    className="form-control"
                    id="number"
                    name="number"
                    value={credentials.number}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center flex-row flex-wrap gap-2 mt-4">
                <button
                  className=" btn btn-prim btn-transparent w-auto px-4"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button className="btn btn-prim w-auto px-4" onClick={sendPind}>
                  Enter
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LockerSlider;
