import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from '../../../assets/icons/icon-back.svg'
import styles from './style.module.css'
import { useSelector } from 'react-redux';
import { Link, useNavigate ,useParams } from "react-router-dom";
import { notification } from "antd";
const Add = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const { slug } = useParams();

  const site = isAuthenticated.site;
  const role = isAuthenticated.role;

  const url = process.env.REACT_APP_APP_BACK_URL;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getSites, setSite] = useState([]);
  const [getLockerS, setLockerSize] = useState([]);
  const [getLockerB, setLockerBank] = useState([]);
  const [suplyLocker, setsuplyLocker] = useState(false);
  useEffect(() => {
    getSite();
    getLockerSize();
    getLockerBank();
  }, []);
  const [credentials, setCredentials] = useState({ locker_no: "", size: "", site: "", row: "", column: "", l_bank: "", comment: "", relay: "", containers: "" ,product:"",port:""});
  const handleInput = (e) => {
    if (e.target.name.toLowerCase() === "site") {
      getProducts(e.target.value);
    }
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/locker/createLockers`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          locker_no: credentials.locker_no,
          size: credentials.size,
          site: credentials.site,
          row: credentials.row,
          column: credentials.column,
          l_bank: credentials.l_bank,
          comment: credentials.comment,
          relay: credentials.relay,
          containers: credentials.containers,
          product:credentials.product,
          url: base_url,
          port:credentials.port,
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setCredentials({
        locker_no: "", size: "", site: "", row: "", column: "", l_bank: "", comment: "", relay: "", containers: "",product:"",port:"",
      });
      notification.success({
        message: 'Success',
        description: 'Lockers Created Successfully',
        duration: 3
      });
    } else {
      notification.error({
        message: 'Failed',
        description: json.error,
        duration: 3
      });
    }
  };
  const getSite = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSite/${site}?role=${role}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
    
      if(json.success){
        setSite(json.site);
      }

      // setCredentials({ ...json.site });
    } catch (error) {
      console.error(error);
    }
  };
  
  const getLockerSize = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/locker/getLockerSize`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setLockerSize(json.locker_size);
      // setCredentials({ ...json.site });
    } catch (error) {
      console.error(error);
    }
  };
  const getLockerBank = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/locker/getLockerBank/${site}?role=${role}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      console.log(json.locker_bank);
      setLockerBank(json.locker_bank);
      // setCredentials({ ...json.site });
    } catch (error) {
      console.error(error);
    }
  };
  const getProducts = async (site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/inventory/getSiteProducts?site=${site}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      setProducts(json.products);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSuplyChange = (event) => {
    const { value, checked } = event.target;
    setsuplyLocker((prevSelectedVideos) =>
      checked
        ? true
        : false
    );
  };
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to={`/${slug}/lockers`}>
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Create Locker</h3>
                  <p>
                    Enter the credentials to add new locker  to the
                    system
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="label-wrap">
                      <h4>Locker No</h4>
                      <p>Enter the precise no of locker</p>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="locker_no"
                        name="locker_no"
                        placeholder="1,2,3,4...."
                        value={credentials.locker_no}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="label-wrap">
                      <h4>Size</h4>
                      <p>Select size of locker</p>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="inpt-wrap">
                      <select name="size" value={credentials.size} onChange={handleInput} required>
                        <option value="">Choose Size</option>
                        {getLockerS.map((per) => (
                          <option key={per._id} value={per._id}>
                            {per.size}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="label-wrap">
                      <h4>Row</h4>
                      <p>Enter number of rows for locker</p>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="row"
                        name="row"
                        placeholder="10"
                        value={credentials.row}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="label-wrap">
                      <h4>Column</h4>
                      <p>Enter number of column for locker</p>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="column"
                        name="column"
                        placeholder="5"
                        value={credentials.column}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="label-wrap">
                      <h4>Port</h4>
                      <p>Enter number of port for locker</p>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="port"
                        name="port"
                        placeholder="5000"
                        value={credentials.port}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="label-wrap">
                      <h4>Site</h4>
                      <p>Select site of locker bank</p>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="inpt-wrap">
                      <select name="site" value={credentials.site} onChange={handleInput} required>
                        <option value="" selected>Choose Site</option>
                        {getSites && getSites.map((per) => (
                        <option key={per._id} value={per._id}>
                          {per.name}
                        </option>
                        ))} 
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              {role==='company admin' &&(
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="label-wrap">
                      <h4>Locker Bank</h4>
                      <p>Select locker bank</p>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="inpt-wrap">
                      <select name="l_bank" value={credentials.l_bank} onChange={handleInput} >
                        <option value="" selected>Choose Locker Bank</option>
                        {getLockerB.map((per) => (
                          <option key={per._id} value={per._id}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              ) }
            
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="label-wrap">
                      <h4>Relay#</h4>
                      <p>Enter the  relay number of locker</p>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="relay"
                        name="relay"
                        placeholder=""
                        value={credentials.relay}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="label-wrap">
                      <h4>Products</h4>
                      <p>Select product of selected site</p>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-8">
                    <div className="inpt-wrap">
                      <select name="product" value={credentials.product} onChange={handleInput} required>
                        <option value="" selected>Choose Product</option>
                        {products && products.map((per) => (
                        <option key={per._id} value={per._id}>
                          {per.name}
                        </option>
                        ))} 
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              {/* <div className="col-lg-6">
           
                <div className="row">
                  <div className="col-xl-3 col-lg-3">
                    <div className="label-wrap">
                      <h4>Supply Locker</h4>
                      <p>Check supply locker</p>
                    </div>
                  </div>
                  <div className="col-xl-1 col-lg-1">
                    <div className="label-wrap">
                      <input
                        className="form-check-input position-static"
                        id='suply-locker'
                        name="suply-locker"
                        type="checkbox"
                        onChange={handleSuplyChange}
                      />
                    </div>
                  </div>
                </div>
              
              </div>
              {suplyLocker && (
                <div className="col-lg-6">
                
                  <div className="row">
                    <div className="col-xl-4 col-lg-4">
                      <div className="label-wrap">
                        <h4>Containers</h4>
                        <p>How many container in locker</p>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-8">
                      <div className="inpt-wrap">
                        <input
                          type="text"
                          className="form-control"
                          id="containers"
                          name="containers"
                          placeholder="10"
                          value={credentials.containers}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                  </div>
                 
                </div>
              )} */}
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Add;
