import React, { useEffect, useState, useRef } from 'react'
import Modal from "react-modal";
import Header from "../../partials/LockerHeader";
import Locker from "../../../images/new images/white-locker.png"
import Mockup from "../../../images/new images/mockup-locker.png"
import { toast } from "react-toastify";
const url = process.env.REACT_APP_APP_BACK_URL;




const SingalCabinets = () => {

    const [isReadyModalOpen, setIsReadyModalOpen] = useState(false);
    const [getLocker, setLocker] = useState([]);
    const [selectedLocker, setselectedLocker] = useState("");
    const [isDisplay, setIsDisplay] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let slug = "ritzdemo";

    const siteLockers = async () => {
        try {
            let headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Access-Control-Allow-Origin", "*");
            headers.append("Access-Control-Allow-Credentials", "true");
            const response = await fetch(`${url}/api/sites/lockers/${slug}`, {
                mode: "cors",
                method: "GET",
                headers: headers,
            });
            const json = await response.json();

            if (json.success) {
                setLocker(json.lockers);
                console.log(json.lockers, "ritz")
                setselectedLocker("");
                setIsDisplay(true);
            } else {
                toast.error(json.error);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };
    useEffect(() => {
        siteLockers();
    }, []);

    const displayOpen = async (id) => {
        // backgroundAudioClick.play();
        setIsReadyModalOpen(true);
        // setselectedLocker(id);
    };

    const handleConfirm = () => {
        // openLocker(selectedLocker);
        setIsReadyModalOpen(false);
        // setselectedLocker("");
    };

    const closeModal = () => {
        setIsReadyModalOpen(false);
        setselectedLocker("");
        // setIsModalOpen(false);
    };
    return (
        <div className="body-wrap">
            <div className="container-fluid px-0">
                <div className="general-dashboard">
                    <div className="container-fluid">
                        <Header />
                        <div className='d-flex justify-content-center align-items-center flex-row gap-2 '>
                            <div className='d-flex justify-content-center align-items-center flex-column gap-2 '>
                                <img src={Mockup} width={130} height={240} className='mt-2' />
                                {getLocker[0] && (
                                    <>
                                        <div
                                            className={` ${isLoading && selectedLocker !== getLocker[0]._id
                                                ? "loading-disabled"
                                                : ""
                                                }`}
                                        >
                                            <div className="lockers-manual position-relative ">
                                                <span className="dot">{1}</span>
                                                <img
                                                    src={Locker}
                                                    alt="yes"
                                                    width={150}
                                                    onClick={() => displayOpen(getLocker[0]._id)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>
                            {/* <div className='d-flex justify-content-center align-items-center flex-column gap-2 '>

                                <img src={Mockup} width={130} height={240} className='mt-2' />
                                {getLocker[1] && (
                                    <>
                                        <div
                                            className={` ${isLoading && selectedLocker !== getLocker[1]._id
                                                ? "loading-disabled"
                                                : ""
                                                }`}
                                        >
                                            <div className="lockers-manual position-relative ">
                                                <span className="dot">{2}</span>
                                                <img
                                                    src={Locker}
                                                    alt="yes"
                                                    width={150}
                                                    onClick={() => displayOpen(getLocker[1]._id)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isReadyModalOpen}
                contentLabel="Example Modal"
                className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
                shouldCloseOnOverlayClick={false}
                style={{
                    content: {
                        fontFamily: "Creepster",
                        fontWeight: "800",
                        fontSize: "30px",
                    },
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
                    },
                }}
            >
                <div
                    className="p-3 w-sm-50 h-sm-50 col-8 rounded-3"
                    style={{ background: "#456e01" }}
                >
                    <div className="d-flex flex-row justify-content-center ">
                        <h2 className="text-white ">Ready</h2>
                    </div>
                    <div
                        className="p-4 rounded-2 w-sm-25 d-flex justify-content-center align-items-center flex-column gap-3" //h-75
                        style={{ background: "#7bb717" }}
                    >
                        <h2 className="text-center text-white">Do you want to open this locker?</h2>
                        <div className="d-flex justify-content-center align-items-center flex-row gap-3">
                            <button className="btn btn-prim w-auto px-4" onClick={handleConfirm}>
                                Yes
                            </button>
                            <button className="btn btn-prim w-auto px-4" onClick={closeModal}>
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>

    )
}

export default SingalCabinets