import React, { useState } from "react";
import { Link } from "react-router-dom";
import { notification } from "antd";
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import logo_site from "../../assets/logo_black.png";
import logo from "../../images/logo.svg";
import frgt_hero from "../../images/locker.jpg";

const ForgotPassword = () => {
  const imageUrl1 = "/assets/images/logo/logo-fast-lobby-white.png";
  const [credentials, setCredentials] = useState({ email: "" });
  const url = process.env.REACT_APP_APP_BACK_URL;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");

    setIsLoading(true);

    const response = await fetch(`${url}/api/auth/forgot/password`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        email: credentials.email,
        password: credentials.password,
        base_url: base_url,
      }),
    });
    setIsLoading(false);

    const json = await response.json();
    if (json.success) {
      setIsEmailSent(true);

      notification.success({
        message: "Success",
        description: json.message,
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };

  return (
    <div className={styles.heading}>
      <section className={styles.sign_sec}>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 my-auto">
              <div className="row">
                <div className="col-lg-6 p-0 mob-2">
                  <div className={styles.sign_hero_wrap}>
                    <img src={frgt_hero} alt="logo hero" />
                  </div>
                </div>
                <div className="col-lg-6 p-0 text-center mob-1">
                  <div className={styles.cont_form_wrap}>
                    <div className={styles.signup_wrap}>
                      <div className={styles.logo_wrap}>
                        <img
                          src={logo_site}
                          alt="logo"
                          height={80}
                          width={230}
                        />
                      </div>
                      <h1 className="text-capitalize">retrieve password</h1>
                      {!isEmailSent ? (
                        <form className="form-wrap" onSubmit={formSubmit}>
                          <div className="row justify-content-center">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Email Address"
                                  value={credentials.email}
                                  onChange={handleInput}
                                />
                              </div>
                              {/* <button type="submit" className="btn btn-prim">get reset link</button> */}
                              <button
                                type="submit"
                                className="btn btn-prim text-capitalize "
                                disabled={isLoading}
                              >
                                <i
                                  className="fa fa-spinner fa-spin "
                                  style={iocnStyle}
                                ></i>{" "}
                                get reset link
                              </button>
                            </div>
                          </div>
                        </form>
                      ) : (
                        <div className="form-wrap text-capitalize">
                          <Link to="/login">back to login</Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgotPassword;
