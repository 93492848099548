import React, { useState, useRef, useEffect } from "react";
import Header from "../../partials/LockerHeader";
import {
  StringeeUtil,
  StringeeClient,
  StringeeCall,
  StringeeCall2,
} from "stringee";
import DecVideCall from "../../../images/dec-video-call.png";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import SwtchVideo from "../../../assets/new-design/unmute_vdo_icon.svg";
import MuteSwitch from "../../../assets/new-design/muted_mic_icon.svg";
import UnmuteSwitch from "../../../assets/new-design/unmuted_mic_ico n.svg";
import UnmuteVideo from "../../../assets/new-design/muted_vdo_icon.svg";
import AttendVideoIcon from "../../../images/attend-video.png";
import DeclineIcon from "../../../images/decline-call.png";
import Modal from "../../partials/Modal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import yeslocker from "../../../assets/new-design/locker.png";
import shortyes from "../../../assets/new-design/small-locker.png";
import SendIcon from "../../../assets/icons/send_icon.svg";
import CrossIcon from "../../../assets/icons/cross_icon.svg";
import VideoAssist from "../../../images/new images/video.png";
import NoImage from "../../../images/new images/no-photo.png";
import ringTone from "../../../assets/ringcall.mp3";
import backgroundMusicClick from "../../../assets/click.mp3";
import io from "socket.io-client";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const VideoCallStringee = () => {
  const streaming_url = process.env.REACT_APP_STREAMIN_SOCKET_URL;
  let { slug } = useParams();
  slug = slug.toLowerCase();

  let navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [stringeeClient, setStringeeClient] = useState(null);
  const [call, setCall] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [sdkVersion, setSdkVersion] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [loggedUserId, setLoggedUserId] = useState("");
  const [muted, setMuted] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [lockerId, setLockerId] = useState("");
  const [getLocker, setLocker] = useState([]);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selectedLocker, setselectedLocker] = useState("");
  const [visible, setVisible] = useState(true);
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [isFloating, setIsFloating] = useState(false);
  const [isCallFloating, setIsCallFloating] = useState(false);
  const [connectionDestroy, setConnectionDestroy] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [callConnected, setCallConnected] = useState(false);
  const [isAttendantBusy, setIsAttendantBusy] = useState(false);
  const isAttendantBusyRef = useRef(isAttendantBusy);
  const [isAttendantModal, setIsAttendantModal] = useState(true);
  const isAttendantModalRef = useRef(isAttendantModal);
  const [incomingCalls, setCallQueue] = useState([]);
  const [callQueue, setCallingQueue] = useState([]);
  const [callerId, setCallerId] = useState("");
  const [backgroundAudio] = useState(new Audio(ringTone));
  const [backgroundAudioClick] = useState(new Audio(backgroundMusicClick));
  const remoteButtonRef = useRef(null);
  const leaveCallButtonRef = useRef(null);
  const ringCall = useRef(null);
  const clickButton = useRef(null);
  const clickJoin = useRef(null);
  useEffect(() => {
    isAttendantBusyRef.current = isAttendantBusy;
  }, [isAttendantBusy]);
  useEffect(() => {
    isAttendantModalRef.current = isAttendantModal;
  }, [isAttendantModal]);
  const handleVideoClick = () => {
    const { width } = getWindowDimensions();
    const surfaceTabletWidth = 768;
    if (width < surfaceTabletWidth) {
      setIsFloating(!isFloating);
      setIsCallFloating(false);
    } else {
      setIsFloating(false);
    }
  };

  useEffect(() => {
    setSdkVersion(
      StringeeUtil.version().version +
        "_build_" +
        StringeeUtil.version().build +
        "_sample_1"
    );
    getAccessToken();
  }, []);

  // socket.on("destroyConnection", (data) => {
  //   console.log("Received destroyConnection event:", data);
  //   // Handle the destroyConnection event as needed
  // });

  const getAccessToken = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/auth/get-token?userId=shoaib`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });

      const json = await response.json();
      if (json.success) {
        setAccessToken(json.token);
        handleLogin(json.token, true);
        setCallerId(json.token);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleLogin = (token, modal) => {
    const client = new StringeeClient();

    client.on("connect", function () {});

    client.on("authen", function (res) {
      if (res.r === 0) {
        setLoggedUserId(res.userId);
      } else {
        setLoggedUserId(res.message);
      }
    });

    client.on("disconnect", function () {
      // alert("disconnect");
    });

    client.on("incomingcall", function (incomingcall) {
      if (isAttendantBusyRef.current) {
        console.log("calling true", incomingcall, callQueue);
        // Add incoming call to the queue
        setCallQueue((prevQueue) => {
          // Check if the incoming call is already in the queue
          const callExists = prevQueue.some(
            (call) => call.callId === incomingcall.callId
          );
          if (!callExists) {
            return [...prevQueue, incomingcall];
          }
          return prevQueue; // Return the unchanged queue if the call already exists
        });

        // Notify the user that the call is added to the queue
        // incomingcall.reject(function (res) {
        //   console.log("Call rejected and added to queue", res);
        // });
      } else {
        // Proceed with handling the new call
        // setCallQueue([incomingcall]);
        setCallQueue([]);
        setIsAttendantBusy(true);
        handleIncomingCall(incomingcall, modal);
      }
    });
    // Function to handle incoming calls

    client.on("requestnewtoken", function () {
      //please get new access_token from YourServer and call:
      //client.connect(new_access_token);
    });

    setStringeeClient(client);
    client.connect(token);
    // client.on("callInfo", function (info) {
    //   if (info && info.action === "hangup") {
    //     alert("call info");
    //   }
    // });
  };

  const handleIncomingCall = async (incomingcall, modal) => {
    // Set the current call to the incoming call
    setCall(incomingcall);
    // Handle the call connection
    await callConnection(incomingcall, modal);
    // Set call as active
    setIsCallActive(true);
    // Show call interface
    if (isAttendantModalRef.current) {
      setCallQueue([]);
      setIsOpen(true);
    } else {
      setConnectionDestroy(true);
      setTimeout(() => {
        if (clickJoin.current) {
          clickJoin.current.click();
        }
      }, 1000);
    }

    // Auto answer if possible
    if (ringCall.current) {
      ringCall.current.click();
    }
    // Handle incoming call events
    incomingcall.ringing(function (res) {});
  };
  const callConnection = async (newCall, modal) => {
    newCall.on("addlocalstream", (stream) => {
      setLocalStream(stream);
    });

    newCall.on("addremotestream", (stream) => {
      setRemoteStream(stream);
    });
    newCall.on("error", function (info) {
      console.log("on error: " + JSON.stringify(info));
    });

    newCall.on("signalingstate", function (state) {
      console.log(state.code, "state.code");

      if (state.code === 3) {
        // call connected
        setIsCallActive(true);
        console.log("Call state set to true");
      } else if (state.code === 5) {
        console.log("Call is busy");
      } else if (state.code === 6) {
        // Call declined
        // Close the video tab
        setConnectionDestroy(false);
        setIsCallActive(false);
      }
      // console.log('signalingstate ', state);
      // var reason = state.reason;
      // $('#callStatus').html(reason);
      // if (state.code === 6) {//call Ended
      //     $('#incoming-call-div').hide();
      //     callEnded();
      // indicates that the call is in the "No Answer" state.
      // } else if (state.code === 5) {//busy
      //     callEnded();
      // }// indicates that the call is in the "Busy" state.
      // }else if (state.code === 9) {//busy
      //     callEnded();
    });

    newCall.on("mediastate", function (state) {});

    newCall.on("info", function (info) {});

    newCall.on("otherdevice", function (data) {
      if (
        (data.type === "CALL_STATE" && data.code >= 200) ||
        data.type === "CALL_END"
      ) {
        // callEnded();
      }
    });
    // if (!modal) {
    //   setTimeout(() => {
    //     joinRoom();
    //   }, 3000); // 3000 milliseconds = 3 seconds
    // }
  };
  function joinRoom() {
    if (clickButton.current) {
      clickButton.current.click();
    }
    setIsOpen(false);
    setConnectionDestroy(true);
    setIsCallActive(true);
    isAttendantBusyRef.current = true;
    call.answer(function (res) {});
    backgroundAudio.pause();
  }
  const handleHangup = async () => {
    try {
      let nextCall;
      if (clickButton.current) {
        clickButton.current.click();
      }

      // Hang up the call if there is one
      if (call) {
        call.hangup();
        setCall(null);
      }
      isAttendantBusyRef.current = false;
      isAttendantModalRef.current = true;
      setIsAttendantBusy(false);
      // Update state variables
      setIsOpen(false);
      setLocalStream(null);
      setRemoteStream(null);
      setConnectionDestroy(false);
      setCall(null);

      socket.emit("destroyCallConnection", callerId);
      if (incomingCalls.length > 0) {
        // Dequeue the next call

        // const currentCall = call;

        // Handle the next call
        // setCall(incomingCalls?incomingCalls[0]:'');
        // Handle the call connection

        // await handleIncomingCall(
        //   nextCall && incomingCalls ? incomingCalls[0] : ""
        // );
        // Set call as active
        // setIsCallActive(true);
        // Show call interface
        // setIsOpen(true);
        nextCall = incomingCalls.shift();
        isAttendantModalRef.current = false;
        setCallQueue((prevQueue) =>
          prevQueue.filter((call) => call !== nextCall)
        );
        await handleLogin(accessToken, false);
      } else {
        // await handleLogin(accessToken, true);
        // Emit 'destroyCallConnection' event to server
        // Pause background audio
        // backgroundAudio.pause();
        // toast.success("Call hang up successfully!");

        // No more calls in the queue, reset the call state
        setCallQueue((prevQueue) => prevQueue.filter((data) => call !== call));
        setCallingQueue([]);
      }
    } catch (error) {
      console.error("Error hanging up the call:", error);
      // Handle error if needed
    }
  };
  const newClientInitialize = (token, modal) => {
    const client = new StringeeClient();

    client.on("connect", function () {});

    client.on("authen", function (res) {
      if (res.r === 0) {
        setLoggedUserId(res.userId);
      } else {
        setLoggedUserId(res.message);
      }
    });
    // client.on("incomingcall", function (incomingcall) {
    // Proceed with handling the new call
    client.on("incomingcall", function (incomingcall) {
      handleIncomingCall(incomingcall, modal);
    });
    client.on("disconnect", function () {
      // alert("disconnect");
    });

    // Function to handle incoming calls

    client.on("requestnewtoken", function () {
      //please get new access_token from YourServer and call:
      //client.connect(new_access_token);
    });

    setStringeeClient(client);
    client.connect(token);
  };
  const handleMuteToggle = () => {
    if (call) {
      call.mute(!muted);
      setMuted(!muted);
    }

    if (clickButton.current) {
      clickButton.current.click();
    }
  };
  const handleVideoToggle = () => {
    if (call) {
      const updatedVideoEnabled = !videoEnabled;
      call.enableLocalVideo(updatedVideoEnabled);
      setVideoEnabled(updatedVideoEnabled);
    }
    if (clickButton.current) {
      clickButton.current.click();
    }
  };

  // const handleVideoToggle = () => {
  //   if (call) {
  //     setIsLoading(true); // Set loading state to true when processing starts
  //     const updatedVideoEnabled = !videoEnabled;
  //     call.enableLocalVideo(updatedVideoEnabled);
  //     setTimeout(() => {
  //       setVideoEnabled(updatedVideoEnabled);
  //       setIsLoading(false); // Set loading state to false after processing
  //     }, 1000); // Simulating a delay for demonstration purpose
  //   }
  // };

  useEffect(() => {
    fetchData();
  }, []);
  const ring = async () => {
    backgroundAudio.play();
  };
  const clickButtonFun = async () => {
    backgroundAudioClick.play();
  };

  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(`${url}/api/locker/site/lockers/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.locker_data.length > 0) {
        setLocker(json.locker_data);
        setIsDisplay(true);
      } else {
        setIsNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openLocker = async (id) => {
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLockerOpen(true);
    setIsLoading(true);
    const response = await fetch(`${url}/api/sites/relay/state/update`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        id: id,
        state: state,
      }),
    });

    setIsLockerOpen(false);
    const json = await response.json();
    if (json.success) {
      toast.success(json.message);
    } else {
      toast.error(json.error);
    }
    setIsLoading(false);
    fetchData();
  };

  const displayOpen = async (id) => {
    const result = await Swal.fire({
      title: "Do you want to open this locker?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setselectedLocker(id);
      // if (selectedLocker === id) {
      openLocker(id);
      // }
    }
  };

  return (
    <div className="body-wrap">
      <div className="container-fluid px-0">
        <div className="general-dashboard">
          <div className="container-fluid">
            <Header />
            <div className="container-fluid text-center ">
              <div style={{ position: "relative" }}>
                <div className="form-box card-box-locker ">
                  <div className="row">
                    <div className="col-sm-9 locker-side  mt-2">
                      <div className="row gap locker-row mt-3 overflow-auto">
                        {getLocker[0] && (
                          <>
                            <div
                              className={`col-2  ${
                                isLoading && selectedLocker !== getLocker[0]._id
                                  ? "loading-disabled"
                                  : ""
                              }`}
                            >
                              <div className="lockers-stringee">
                                <span className="stringee-locker-num">{1}</span>
                                <>
                                  {selectedLocker === getLocker[0]._id && (
                                    <div className="locker-button-text-stringee">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[0]._id, 0);
                                      }
                                    }}
                                  />
                                  <div className="product-images-locker-stringee">
                                    <img
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[0]._id, 0);
                                        }
                                      }}
                                      src={
                                        getLocker[0].isImageExists
                                          ? getLocker[0].product_image
                                          : NoImage
                                      }
                                      alt="product-image"
                                    />
                                  </div>
                                  <div className="product-name-locker-stringee">
                                    <h6 className="text-capitalize text-break">
                                      {getLocker[0]?.product_name.length > 10
                                        ? getLocker[0]?.product_name.slice(
                                            0,
                                            9
                                          ) + "..."
                                        : getLocker[0]?.product_name ??
                                          "no name"}{" "}
                                    </h6>
                                  </div>
                                </>
                              </div>
                            </div>
                          </>
                        )}
                        {getLocker[3] && (
                          <div
                            className={`col-2  ${
                              isLoading && selectedLocker !== getLocker[3]._id
                                ? "loading-disabled"
                                : ""
                            }`}
                          >
                            <div className="lockers-stringee">
                              <span className="stringee-locker-num">{4}</span>
                              <>
                                {selectedLocker === getLocker[3]._id && (
                                  <div className="locker-button-text-stringee">
                                    OPEN
                                  </div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => {
                                    if (visible) {
                                      displayOpen(getLocker[3]._id, 3);
                                    }
                                  }}
                                />
                                <div className="product-images-locker-stringee">
                                  <img
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[3]._id, 3);
                                      }
                                    }}
                                    src={
                                      getLocker[3].isImageExists // Check if isImageExists is true
                                        ? getLocker[3].product_image // If true, use product_image
                                        : NoImage // If false, use placeholder image
                                    }
                                    alt="product-image"
                                  />
                                </div>
                                <div className="product-name-locker-stringee">
                                  <h6 className="text-capitalize text-break ">
                                    {getLocker[3]?.product_name.length > 10
                                      ? getLocker[3]?.product_name.slice(0, 9) +
                                        "..."
                                      : getLocker[3]?.product_name ?? "no name"}
                                  </h6>
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                        <div className="col-2 ">
                          <div className="lockers-stringee">
                            {getLocker[6] && (
                              <div
                                className={`lockers-short-stringee ${
                                  isLoading &&
                                  selectedLocker !== getLocker[6]._id
                                    ? "loading-disabled"
                                    : ""
                                }`}
                              >
                                <span className="stringee-locker-num1">
                                  {7}
                                </span>
                                <>
                                  {selectedLocker === getLocker[6]._id && (
                                    <div className="locker-button-text-stringee locker-button-text-stringee-small ">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={shortyes}
                                    alt="yes"
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[6]._id, 6);
                                      }
                                    }}
                                  />
                                  <div className="product-images-locker-short-stringee">
                                    <img
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[6]._id, 6);
                                        }
                                      }}
                                      src={
                                        getLocker[6].isImageExists
                                          ? getLocker[6].product_image
                                          : NoImage
                                      }
                                      alt="product-image"
                                    />
                                  </div>
                                  <div className="product-name-locker-stringee-short">
                                    <h6 className="text-capitalize text-break ">
                                      {getLocker[6]?.product_name.length > 10
                                        ? getLocker[6].product_name.slice(
                                            0,
                                            9
                                          ) + "..."
                                        : getLocker[6]?.product_name ??
                                          "no name"}
                                    </h6>
                                  </div>
                                </>
                              </div>
                            )}
                            {getLocker[7] && (
                              <div
                                className={`lockers-short-stringee half-locker  ${
                                  isLoading &&
                                  selectedLocker !== getLocker[7]._id
                                    ? "loading-disabled"
                                    : ""
                                }`}
                              >
                                <span className="stringee-locker-num2">8</span>
                                <>
                                  {selectedLocker === getLocker[7]._id && (
                                    <div className="locker-button-text-stringee locker-button-text-stringee-small">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={shortyes}
                                    alt="yes"
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[7]._id, 7);
                                      }
                                    }}
                                  />
                                  <div className="product-images-locker-short-stringee-2">
                                    <img
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[7]._id, 7);
                                        }
                                      }}
                                      src={
                                        getLocker[7].isImageExists
                                          ? getLocker[7].product_image
                                          : NoImage
                                      }
                                      alt="product-image"
                                    />
                                  </div>
                                  <div className="product-name-locker-stringee-short-2">
                                    <h6 className="text-capitalize text-break ">
                                      {getLocker[7]?.product_name.length > 10
                                        ? getLocker[7].product_name.slice(
                                            0,
                                            9
                                          ) + "..."
                                        : getLocker[7].product_name ??
                                          "no name"}
                                    </h6>
                                  </div>
                                </>
                              </div>
                            )}
                          </div>
                        </div>
                        {getLocker[10] && (
                          <div
                            className={`col-2  ${
                              isLoading && selectedLocker !== getLocker[10]._id
                                ? "loading-disabled"
                                : ""
                            }`}
                          >
                            <div className="lockers-stringee">
                              <span className="stringee-locker-num">{11}</span>
                              <>
                                {selectedLocker === getLocker[10]._id && (
                                  <div className="locker-button-text-stringee">
                                    OPEN
                                  </div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => {
                                    if (visible) {
                                      displayOpen(getLocker[10]._id, 10);
                                    }
                                  }}
                                />
                                <div className="product-images-locker-stringee">
                                  <img
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[10]._id, 10);
                                      }
                                    }}
                                    src={
                                      getLocker[10].isImageExists
                                        ? getLocker[10].product_image
                                        : NoImage
                                    }
                                    alt="product-image"
                                  />
                                </div>
                                <div className="product-name-locker-stringee">
                                  <h6 className="text-capitalize text-break ">
                                    {getLocker[10]?.product_name.length > 10
                                      ? getLocker[10]?.product_name.slice(
                                          0,
                                          9
                                        ) + "..."
                                      : getLocker[10]?.product_name ??
                                        "no name"}{" "}
                                  </h6>
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                        {getLocker[13] && (
                          <>
                            <div
                              className={`col-2  ${
                                isLoading &&
                                selectedLocker !== getLocker[13]._id
                                  ? "loading-disabled"
                                  : ""
                              }`}
                            >
                              <div className="lockers-stringee">
                                <span className="stringee-locker-num">
                                  {14}
                                </span>
                                <>
                                  {selectedLocker === getLocker[13]._id && (
                                    <div className="locker-button-text-stringee">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[13]._id, 13);
                                      }
                                    }}
                                  />
                                  <div className="product-images-locker-stringee">
                                    <img
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[13]._id, 13);
                                        }
                                      }}
                                      src={
                                        getLocker[13].isImageExists
                                          ? getLocker[13].product_image
                                          : NoImage
                                      }
                                      alt="product-image"
                                    />
                                  </div>
                                  <div className="product-name-locker-stringee">
                                    <h6 className="text-capitalize text-break ">
                                      {getLocker[13]?.product_name.length > 15
                                        ? getLocker[13]?.product_name.slice(
                                            0,
                                            14
                                          ) + "..."
                                        : getLocker[13]?.product_name ??
                                          "no name"}
                                    </h6>
                                  </div>
                                </>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row locker-row mt-3">
                        {getLocker[1] && (
                          <>
                            <div
                              className={`col-2  ${
                                isLoading && selectedLocker !== getLocker[1]._id
                                  ? "loading-disabled"
                                  : ""
                              }`}
                            >
                              <div className="lockers-stringee">
                                <span className="stringee-locker-num">{2}</span>
                                <>
                                  {selectedLocker === getLocker[1]._id && (
                                    <div className="locker-button-text-stringee">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[1]._id, 1);
                                      }
                                    }}
                                  />
                                  <div className="product-images-locker-stringee">
                                    <img
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[1]._id, 1);
                                        }
                                      }}
                                      src={
                                        getLocker[1].isImageExists
                                          ? getLocker[1].product_image
                                          : NoImage
                                      }
                                      alt="product-image"
                                    />
                                  </div>
                                  <div className="product-name-locker-stringee">
                                    <h6 className="text-capitalize text-break ">
                                      {getLocker[1]?.product_name.length > 10
                                        ? getLocker[1]?.product_name.slice(
                                            0,
                                            9
                                          ) + "..."
                                        : getLocker[1]?.product_name ??
                                          "no name"}{" "}
                                    </h6>
                                  </div>
                                </>
                              </div>
                            </div>
                          </>
                        )}
                        {getLocker[4] && (
                          <div
                            className={`col-2  ${
                              isLoading && selectedLocker !== getLocker[4]._id
                                ? "loading-disabled"
                                : ""
                            }`}
                          >
                            <div className="lockers-stringee">
                              <span className="stringee-locker-num">{5}</span>
                              <>
                                {selectedLocker === getLocker[4]._id && (
                                  <div className="locker-button-text-stringee">
                                    OPEN
                                  </div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => {
                                    if (visible) {
                                      displayOpen(getLocker[4]._id, 4);
                                    }
                                  }}
                                />
                                <div className="product-images-locker-stringee">
                                  <img
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[4]._id, 4);
                                      }
                                    }}
                                    src={
                                      getLocker[4].isImageExists
                                        ? getLocker[4].product_image
                                        : NoImage
                                    }
                                    alt="product-image"
                                  />
                                </div>
                                <div className="product-name-locker-stringee">
                                  <h6 className="text-capitalize text-break ">
                                    {getLocker[4].product_name
                                      ? getLocker[4].product_name.split(" ")
                                          .length > 2
                                        ? `${getLocker[4].product_name
                                            .split(" ")
                                            .slice(0, 1)
                                            .join(" ")}...`
                                        : getLocker[4].product_name
                                      : "no name"}{" "}
                                  </h6>
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                        <div className="col-2 ">
                          <div className="locker video-attendant-stringee">
                            <img
                              src={VideoAssist}
                              alt="yes"
                              // onClick={handleVideoCall}
                            />
                          </div>
                        </div>
                        {getLocker[11] && (
                          <div
                            className={`col-2  ${
                              isLoading && selectedLocker !== getLocker[11]._id
                                ? "loading-disabled"
                                : ""
                            }`}
                          >
                            <div className="lockers-stringee">
                              <span className="stringee-locker-num">{12}</span>
                              <>
                                {selectedLocker === getLocker[11]._id && (
                                  <div className="locker-button-text-stringee">
                                    OPEN
                                  </div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => {
                                    if (visible) {
                                      displayOpen(getLocker[11]._id, 11);
                                    }
                                  }}
                                />
                                <div className="product-images-locker-stringee">
                                  <img
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[11]._id, 11);
                                      }
                                    }}
                                    src={
                                      getLocker[11].isImageExists
                                        ? getLocker[11].product_image
                                        : NoImage
                                    }
                                    alt="product-image"
                                  />
                                </div>
                                <div className="product-name-locker-stringee">
                                  <h6 className="text-capitalize text-break ">
                                    {getLocker[11]?.product_name.length > 10
                                      ? getLocker[11]?.product_name.slice(
                                          0,
                                          9
                                        ) + "..."
                                      : getLocker[11]?.product_name ??
                                        "no name"}{" "}
                                  </h6>
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                        {getLocker[14] && (
                          <>
                            <div
                              className={`col-2  ${
                                isLoading &&
                                selectedLocker !== getLocker[14]._id
                                  ? "loading-disabled"
                                  : ""
                              }`}
                            >
                              <div className="lockers-stringee">
                                <span className="stringee-locker-num">
                                  {15}
                                </span>
                                <>
                                  {selectedLocker === getLocker[14]._id && (
                                    <div className="locker-button-text-stringee">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[14]._id, 14);
                                      }
                                    }}
                                  />
                                  <div className="product-images-locker-stringee">
                                    <img
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[14]._id, 14);
                                        }
                                      }}
                                      src={
                                        getLocker[14].isImageExists
                                          ? getLocker[14].product_image
                                          : NoImage
                                      }
                                      alt="product-image"
                                    />
                                  </div>
                                  <div className="product-name-locker-stringee">
                                    <h6 className="text-capitalize text-break ">
                                      {getLocker[14]?.product_name.length > 10
                                        ? getLocker[14]?.product_name.slice(
                                            0,
                                            9
                                          ) + "..."
                                        : getLocker[14]?.product_name ??
                                          "no name"}{" "}
                                    </h6>
                                  </div>
                                </>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="row locker-row mt-3">
                        {getLocker[2] && (
                          <>
                            <div
                              className={`col-2  ${
                                isLoading && selectedLocker !== getLocker[2]._id
                                  ? "loading-disabled"
                                  : ""
                              }`}
                            >
                              <div className="lockers-stringee">
                                <span className="stringee-locker-num">{3}</span>
                                <>
                                  {selectedLocker === getLocker[2]._id && (
                                    <div className="locker-button-text-stringee">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[2]._id, 2);
                                      }
                                    }}
                                  />
                                  <div className="product-images-locker-stringee">
                                    <img
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[2]._id, 2);
                                        }
                                      }}
                                      src={
                                        getLocker[2].isImageExists
                                          ? getLocker[2].product_image
                                          : NoImage
                                      }
                                      alt="product-image"
                                    />
                                  </div>
                                  <div className="product-name-locker-stringee">
                                    <h6 className="text-capitalize text-break ">
                                      {getLocker[2]?.product_name.length > 10
                                        ? getLocker[2]?.product_name.slice(
                                            0,
                                            9
                                          ) + "..."
                                        : getLocker[2]?.product_name ??
                                          "no name"}{" "}
                                    </h6>
                                  </div>
                                </>
                              </div>
                            </div>
                          </>
                        )}
                        {getLocker[5] && (
                          <div
                            className={`col-2  ${
                              isLoading && selectedLocker !== getLocker[5]._id
                                ? "loading-disabled"
                                : ""
                            }`}
                          >
                            <div className="lockers-stringee">
                              <span className="stringee-locker-num">{6}</span>
                              <>
                                {selectedLocker === getLocker[5]._id && (
                                  <div className="locker-button-text-stringee">
                                    OPEN
                                  </div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => {
                                    if (visible) {
                                      displayOpen(getLocker[5]._id, 5);
                                    }
                                  }}
                                />
                                <div className="product-images-locker-stringee">
                                  <img
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[5]._id, 5);
                                      }
                                    }}
                                    src={
                                      getLocker[5].isImageExists
                                        ? getLocker[5].product_image
                                        : NoImage
                                    }
                                    alt="product-image"
                                  />
                                </div>
                                <div className="product-name-locker-stringee">
                                  <h6 className="text-capitalize text-break ">
                                    {getLocker[5]?.product_name.length > 10
                                      ? getLocker[5]?.product_name.slice(0, 9) +
                                        "..."
                                      : getLocker[5]?.product_name ??
                                        "no name"}{" "}
                                  </h6>
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                        <div className="col-2 ">
                          <div className="lockers-stringee">
                            {getLocker[8] && (
                              <div
                                className={`lockers-short-stringee ${
                                  isLoading &&
                                  selectedLocker !== getLocker[8]._id
                                    ? "loading-disabled"
                                    : ""
                                }`}
                              >
                                <span className="stringee-locker-num1">
                                  {9}
                                </span>
                                <>
                                  {selectedLocker === getLocker[8]._id && (
                                    <div className="locker-button-text-stringee">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={shortyes}
                                    alt="yes"
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[8]._id, 8);
                                      }
                                    }}
                                  />
                                  <div className="product-images-locker-short-stringee">
                                    <img
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[8]._id, 8);
                                        }
                                      }}
                                      src={
                                        getLocker[8].isImageExists
                                          ? getLocker[8].product_image
                                          : NoImage
                                      }
                                      alt="product-image"
                                    />
                                  </div>
                                  <div className="product-name-locker-stringee-short">
                                    <h6 className="text-capitalize text-break ">
                                      {getLocker[8].product_name.length > 10
                                        ? getLocker[8].product_name.slice(
                                            0,
                                            10
                                          ) + "..."
                                        : getLocker[8].product_name ??
                                          "no name"}
                                    </h6>
                                  </div>
                                </>
                              </div>
                            )}
                            {getLocker[9] && (
                              <div
                                className={`lockers-short-stringee half-locker ${
                                  isLoading &&
                                  selectedLocker !== getLocker[9]._id
                                    ? "loading-disabled"
                                    : ""
                                }`}
                              >
                                <span className="stringee-locker-num2">
                                  {10}
                                </span>
                                <>
                                  {selectedLocker === getLocker[9]._id && (
                                    <div className=" locker-button-text-stringee-small">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={shortyes}
                                    alt="yes"
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[9]._id, 9);
                                      }
                                    }}
                                  />
                                  <div className="product-images-locker-short-stringee-2">
                                    <img
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[9]._id, 9);
                                        }
                                      }}
                                      src={
                                        getLocker[9].isImageExists
                                          ? getLocker[9].product_image
                                          : NoImage
                                      }
                                      alt="product-image"
                                    />
                                  </div>
                                  <div className="product-name-locker-stringee-short-2">
                                    <h6 className="text-capitalize text-break ">
                                      {getLocker[9].product_name.length > 10
                                        ? getLocker[9].product_name.slice(
                                            0,
                                            10
                                          ) + "..."
                                        : getLocker[9].product_name ??
                                          "no name"}
                                    </h6>
                                  </div>
                                </>
                              </div>
                            )}
                          </div>
                        </div>
                        {getLocker[12] && (
                          <div
                            className={`col-2  ${
                              isLoading && selectedLocker !== getLocker[12]._id
                                ? "loading-disabled"
                                : ""
                            }`}
                          >
                            <div className="lockers-stringee">
                              <span className="stringee-locker-num">{13}</span>
                              <>
                                {selectedLocker === getLocker[12]._id && (
                                  <div className="locker-button-text-stringee">
                                    OPEN
                                  </div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => {
                                    if (visible) {
                                      displayOpen(getLocker[12]._id, 12);
                                    }
                                  }}
                                />
                                <div className="product-images-locker-stringee">
                                  <img
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[12]._id, 12);
                                      }
                                    }}
                                    src={
                                      getLocker[12].isImageExists
                                        ? getLocker[12].product_image
                                        : NoImage
                                    }
                                    alt="product-image"
                                  />
                                </div>
                                <div className="product-name-locker-stringee">
                                  <h6 className="text-capitalize text-break ">
                                    {getLocker[12]?.product_name.length > 10
                                      ? getLocker[12]?.product_name.slice(
                                          0,
                                          9
                                        ) + "..."
                                      : getLocker[12]?.product_name ??
                                        "no name"}{" "}
                                  </h6>
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                        {getLocker[15] && (
                          <>
                            <div
                              className={`col-2  ${
                                isLoading &&
                                selectedLocker !== getLocker[15]._id
                                  ? "loading-disabled"
                                  : ""
                              }`}
                            >
                              <div className="lockers-stringee">
                                <span className="stringee-locker-num">
                                  {16}
                                </span>
                                <>
                                  {selectedLocker === getLocker[15]._id && (
                                    <div className="locker-button-text-stringee">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() => {
                                      if (visible) {
                                        displayOpen(getLocker[15]._id, 15);
                                      }
                                    }}
                                  />
                                  <div className="product-images-locker-stringee">
                                    <img
                                      onClick={() => {
                                        if (visible) {
                                          displayOpen(getLocker[15]._id, 15);
                                        }
                                      }}
                                      src={
                                        getLocker[15].isImageExists
                                          ? getLocker[15].product_image
                                          : NoImage
                                      }
                                      alt="product-image"
                                    />
                                  </div>
                                  <div className="product-name-locker-stringee">
                                    <h6 className="text-capitalize text-break ">
                                      {getLocker[15]?.product_name.length > 10
                                        ? getLocker[15]?.product_name.slice(
                                            0,
                                            9
                                          ) + "..."
                                        : getLocker[15]?.product_name ??
                                          "no name"}{" "}
                                    </h6>
                                  </div>
                                </>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3 call-side mt-3">
                      <div className="d-flex justify-content-center flex-row flex-wrap gap-2">
                        <div className="d-flex justify-content-center flex-row flex-wrap gap-2 mt-2">
                          <div>
                            {connectionDestroy && (
                              <div className="user-video-screens">
                                <div
                                  className=""
                                  style={{
                                    background:
                                      "linear-gradient(#323232, #4c4c4c)",
                                    position: "relative",
                                  }}
                                >
                                  {localStream && (
                                    <video
                                      ref={(ref) => {
                                        if (ref) ref.srcObject = localStream;
                                      }}
                                      autoPlay
                                      playsInline
                                      muted
                                      className="local-stream"
                                    ></video>
                                  )}
                                  {remoteStream && (
                                    <video
                                      ref={(ref) => {
                                        if (ref) ref.srcObject = remoteStream;
                                      }}
                                      autoPlay
                                      playsInline
                                      style={{
                                        position: "relative",
                                      }}
                                      width="100%"
                                      height="100%"
                                    ></video>
                                  )}
                                </div>
                                {call && (
                                  <div className="bottom-bar-call seperat-row mb-2">
                                    <div className="icons-wrap-end-call">
                                      <div className="icon-call">
                                        {videoEnabled ? (
                                          <img
                                            src={SwtchVideo}
                                            alt="Unmute Video"
                                            className="mx-2 d-flex justify-content-center"
                                            width={27}
                                            height={27}
                                            onClick={handleVideoToggle}
                                          />
                                        ) : (
                                          <img
                                            src={UnmuteVideo}
                                            alt="Mute Video"
                                            className="mx-2 d-flex justify-content-center  rounded"
                                            width={27}
                                            height={27}
                                            onClick={handleVideoToggle}
                                          />
                                        )}
                                      </div>

                                      <div
                                        className="icon-call dec-call-video"
                                        onClick={() => handleHangup()}
                                      >
                                        <img src={DecVideCall} alt="" />
                                      </div>
                                      <div className="icon-call">
                                        {!muted ? (
                                          <img
                                            src={UnmuteSwitch}
                                            alt="UnMute Call"
                                            className="mx-2 d-flex justify-content-center"
                                            width={27}
                                            height={27}
                                            onClick={handleMuteToggle}
                                          />
                                        ) : (
                                          <img
                                            src={MuteSwitch}
                                            alt="Mute Call"
                                            className="mx-2 d-flex justify-content-center  rounded "
                                            width={27}
                                            height={27}
                                            onClick={handleMuteToggle}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-center flex-column flex-wrap ">
                          <div className="admin-video-screens">
                            {" "}
                            {localStream && (
                              <video
                                ref={(ref) => {
                                  if (ref) ref.srcObject = localStream;
                                }}
                                autoPlay
                                playsInline
                                muted
                                style={{
                                  position: "absolute",
                                  top: "9%",
                                  right: "1%",
                                  zIndex: "99999",
                                  objectFit: "cover",
                                }}
                                height="8%"
                                width="8%"
                              ></video>
                            )}
                          </div>
                        </div> */}
                      </div>
                      <div className="mt-3">
                        {incomingCalls.length > 0 && (
                          <>
                            <h5>Users in Queue</h5>
                            <div
                              className="mt-3 table-responsive  "
                              style={{
                                border: "2px solid black",
                                marginRight: "16px",
                              }}
                            >
                              <table
                                className="table"
                                style={{
                                  borderCollapse: "collapse",
                                  width: "100%",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th>User</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {incomingCalls.map((call, index) => (
                                    <tr>
                                      <td style={{}}>
                                        <span>User {index + 1}</span>
                                      </td>
                                      <td>
                                        <button className="btn btn-success tb-btn">
                                          <img src={DecVideCall} alt="" />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
                      </div>
                      <Modal isOpen={modalIsOpen} onClose={handleHangup}>
                        <div className="modal-body">
                          <div className="row h-100">
                            <div className="col-12 mt-auto">
                              <div className="incoming-call-sec">
                                <h3 className="text-center">
                                  Inviting you for a video chat <br />
                                </h3>
                                <div className="call-detail-bar">
                                  <div className="call-options-wrap">
                                    <div className="icons-wrap-options">
                                      {/* <div className="icon-dec">
                                        <img
                                          src={DeclineIcon}
                                          alt=""
                                          onClick={handleHangup}
                                        />
                                      </div> */}

                                      <div
                                        className="icon-att-vid"
                                        onClick={joinRoom}
                                      >
                                        <img src={AttendVideoIcon} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
                {/* <div className="live-streaming bg-white py-4 my-2 ">
                <div className="d-flex justify-content-center w-full ">
                  <canvas id="canvas"></canvas>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <a ref={remoteButtonRef} onClick={() => answerCall(slug)}></a> */}
      <a ref={leaveCallButtonRef} onClick={handleHangup}></a>
      <a ref={ringCall} onClick={ring}></a>
      <a ref={clickButton} onClick={clickButtonFun}></a>
      <a ref={clickJoin} onClick={joinRoom}></a>
    </div>
  );
};

export default VideoCallStringee;
