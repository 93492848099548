import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import FilterIcon from "../../../images/filter.png";
import { Link, useNavigate ,useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import Edit from "../../../assets/icons/edit-ic.svg";
import Delete from "../../../assets/icons/delete-ic.svg";
import BackIcon from '../../../assets/icons/icon-back.svg'

import Details from "../../../assets/icons/infoo.svg";
import HashLoader from "react-spinners/ClipLoader";
import Images from "../modal/Images";
import { notification } from "antd";
import CrossIcon from "../../../assets/icons/cross_icon.svg";
import Modal from "react-modal";
// import { Link,  } from "react-router-dom";
const List = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [qrTypes, setQrTypes] = useState([]);
  const [site, setsite] = useState(isAuthenticated.site);
  const [role, setrole] = useState(isAuthenticated.role);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [assignProductModal, setAssignProductModal] = useState(false);
  const [credentials, setCredentials] = useState({ type: "all", name: "" });
  const [lockerID, setLockerID] = useState({});
  const [productID, setProductID] = useState({});
  const [saveProduct, setSaveProduct] = useState({});

  const handleInput = (e) => {
    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      fetchData(updatedCredentials);
      return updatedCredentials;
    });
  };
  useEffect(() => {
    fetchData(credentials);
    // getTypes();
  }, [currentPage]);
  const getTypes = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/type/qr/types`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setQrTypes(json);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData = async (fetchCredentials) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(
        `${url}/api/locker/lockerList?page=${currentPage}&limit=${itemsPerPage}&name=${fetchCredentials.name}&type=${fetchCredentials.type}&site=${site}&role=${role}&slug=${slug}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.locker_data.length > 0) {
        setData(json.locker_data);
        setIsDisplay(true);
      } else {
        setIsNotFound(true);
      }
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(
          `${url}/api/l_bank/delete/lockerBank/${id}`,
          {
            mode: "cors",
            method: "DELETE",
            headers: headers,
          }
        );
        if (response.ok) {
          fetchData(credentials); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "Locker Bank deleted successfully!", "success");
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };
  const HandleOpenLocker = async (id) => {
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLockerOpen((prevState) => ({
      ...prevState,
      [id]: true,
    }));
    const response = await fetch(`${url}/api/sites/relay/state/update`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        id: id,
        state: state,
      }),
    });
    setIsLockerOpen(false);
    const json = await response.json();
    console.log(json);
    if (json.success) {
      notification.success({
        message: "Success",
        description: json.message,
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const openModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImageUrl("");
  };
  const formattedDate = (date) => {
    if (!date) {
      // If date is empty or null, use the current date
      date = new Date();
    }
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  const handleResetBtn = () => {
    const updatedCredentials = {
      name: "",
      type: "all",
    };
    // Update state and then call fetchData
    setCredentials(updatedCredentials);
    setCurrentPage(1);
    // Call fetchData with the updated credentials
    fetchData(updatedCredentials);
  };
  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }
  const assignProduct = (id, site, product) => {
    setSaveProduct(product);
    setLockerID(id);
    getProducts(site);
    setAssignProductModal(true);
  };
  const getProducts = async (site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/inventory/getSiteProducts?site=${site}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      setProducts(json.products);
    } catch (error) {
      console.error(error);
    }
  };
  const handleChangeInput = (e) => {
    setProductID(e.target.value);
  };
  const handleSubmitAssignProduct = async (e) => {
    try {
      e.preventDefault();
      setIsLoadingProduct(true);
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/products/updateProductLocker/${lockerID}`,
        {
          mode: "cors",
          method: "PUT",
          headers: headers,
          body: JSON.stringify({
            productID: productID,
          }),
        }
      );
      const data = await response.json();
      setIsLoadingProduct(false);
      if (data.success) {
        notification.success({
          message: "Success",
          description: data.message,
          duration: 3,
        });
        fetchData(credentials);
        setAssignProductModal(false);
      } else {
        notification.error({
          message: "Failed",
          description: data.error,
          duration: 3,
        });
        setAssignProductModal(false);
      }
      // Reset form or handle success as needed
    } catch (error) {
      setIsLoadingProduct(false);
      console.error("Error submitting form:", error);
    }
  };

  const iocnStyle = {
    display: isLoadingProduct ? "inline-block" : "none",
  };
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
           
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/site/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                <h3>{slug} Lockers</h3>
                  <p>List of all the {slug} lockers in the system</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="filter-right-sec">
                <div className="filter-wrap">
                  <div className="filter-box">
                    <img
                      src={FilterIcon}
                      alt=""
                      onClick={toggleFilterSection}
                    />
                  </div>
                </div>
                {role.toLowerCase() !== "site user" && (
                  <Link to={`/${slug}/locker/add`} className="btn btn-prim w-auto">
                    Add {slug} Lockers
                  </Link>
                )}
              </div>
            </div>
          </div>
          {isFilterVisible && (
            <>
              <motion.div
                className={`row mt-5 filter-section`}
                initial={{ opacity: 0 }} // Initial state (hidden)
                animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
                transition={{ duration: 1.2, delay: 0.1 }}
              ></motion.div>
            </>
          )}
        </div>
        {/* row */}
        <div className="table-wrap">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                          <th>Locker no</th>
                          <th>Site</th>
                          <th>Port</th>
                          <th>Relay#</th>
                          <th>Size</th>
                          <th>Row</th>
                          <th>Column</th>
                          <th>Product</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <>
                            <tr>
                              <td
                                colSpan={10}
                                className="text-center"
                                style={{ background: "transparent" }}
                              >
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        {isDisplay && data.length > 0
                          ? data.map((item, i) => (
                              <motion.tr
                                key={item._id}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1.2, delay: i * 0.1 }}
                              >
                                <td>
                                  <div className="usr_det_tb">
                                    <div className="img_wrap_qr_tb">
                                      {item.product_image &&
                                        item.product_image !== "" && (
                                          <>
                                            <img
                                              className={`not_prof_img`}
                                              src={`${item.product_image}`}
                                              onClick={() =>
                                                openModal(
                                                  `${item.product_image}`
                                                )
                                              }
                                              alt=""
                                            />
                                            <Images
                                              isOpen={isModalOpen}
                                              imageUrl={selectedImageUrl}
                                              onClose={closeModal}
                                            />
                                          </>
                                        )}
                                    </div>
                                    <span>{item.number}</span>
                                  </div>{" "}
                                </td>
                                <td>{item.siteName}</td>
                                <td>{item.port}</td>
                                <td>{item.relay}</td>
                                <td>{item.size}</td>
                                <td>{item.row}</td>
                                <td>{item.col}</td>
                                <td>{item.product_name}</td>
                                <td>
                                  {item.status && item.status !== "" && (
                                    <>
                                      {item.status === "available" && (
                                        <span className="status success">
                                          Available
                                        </span>
                                      )}
                                      {item.status === "occupied" && (
                                        <span className="status error">
                                          Occupied
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td className="text-center d-flex justify-content-center flex-row gap-2">
                                  {role.toLowerCase() === "company admin" && (
                                    <button
                                      type="button"
                                      className="btn btn-success tb-btn"
                                      onClick={() => {
                                        assignProduct(
                                          item._id,
                                          item.site,
                                          item.product
                                        );
                                      }}
                                    >
                                      Associate Product
                                      {}
                                    </button>
                                  )}
                                  <button
                                    type="button"
                                    className="btn btn-delete tb-btn"
                                    onClick={() => {
                                      HandleOpenLocker(item._id);
                                    }}
                                    disabled={isLockerOpen[item._id]}
                                  >
                                    {isLockerOpen[item._id]
                                      ? "Opening..."
                                      : "Open Locker"}
                                  </button>
                                  <Link to={`/${slug}/lockers/logs/${item._id}`} className="btn btn-prim w-auto mt-0">
                                        Lockers logs
                                      </Link>
                                  {role.toLowerCase() !== "site user" && (
                                    <>
                                      <Link
                                        to={`/locker/${item._id}`}
                                        className=""
                                        style={{ border: "none" }}
                                      >
                                        <img src={Details} alt="detail" />
                                      </Link>
                                      <Link to={`/${slug}/locker/edit/${item._id}`}>
                                        <button
                                          type="button"
                                          className=""
                                          style={{ border: "none" }}
                                        >
                                          <img src={Edit} />
                                        </button>
                                      </Link>
                                      <button
                                        type="button"
                                        className="mb-1"
                                        style={{ border: "none" }}
                                        onClick={() => handleDelete(item._id)}
                                      >
                                        <img src={Delete} />
                                      </button>
                                      
                                    </>
                                  )}
                                </td>
                              </motion.tr>
                            ))
                          : isNotFound && (
                              <tr>
                                <td colSpan={7} className="text-center">
                                  <p className="text-center text-danger">
                                    No data found.
                                  </p>
                                </td>
                              </tr>
                            )}
                      </tbody>
                    </table>
                  </div>
                  <div className="table-num">
                    {/* {data.length > 0 && ( */}
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={totalPages}
                        forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                        onPageChange={handlePageChange}
                        containerClassName={" dig-num"}
                        pageClassName={"dig-num"}
                        previousClassName={"num-btns"}
                        nextClassName={"num-btns"}
                        disabledClassName={"pagination-disabled"}
                        activeClassName={"pagination-active"}
                      />
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={assignProductModal}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-4 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#fff" }}
        >
          <div className="d-flex justify-content-end align-items-center flex-row">
            <img
              src={CrossIcon}
              alt="cross"
              onClick={() => setAssignProductModal(false)}
            />
          </div>

          <div
            className="p-2 rounded-2 w-sm-25  " //h-75
            style={{ background: "#fff" }}
          >
            <form onSubmit={handleSubmitAssignProduct}>
              <div className="d-flex flex-column">
                <div>
                  <label
                    htmlFor="price"
                    className="form-label"
                    style={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    Product
                  </label>
                  <select
                    name="site"
                    className="form-control"
                    value={saveProduct}
                    onChange={handleChangeInput}
                    required
                  >
                    <option value="">Choose Product</option>
                    {products &&
                      products.map((per) => (
                        <option key={per._id} value={per._id}>
                          {per.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center my-3">
                <button
                  type="submit"
                  disabled={isLoadingProduct}
                  href=""
                  className="btn btn-prim w-auto ms-5"
                >
                  <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default List;
