import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import SearchIcon from "../../../images/search.png";
import FilterIcon from "../../../images/filter.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Edit from "../../../assets/icons/edit-ic.svg";
import Delete from "../../../assets/icons/delete-ic.svg";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import Images from "../modal/Images";
import { notification } from "antd";
import Modal from "../../partials/Modal";
import SendIcon from "../../../assets/icons/send_icon.svg";
import CrossIcon from "../../../assets/icons/cross_icon.svg";
import io from "socket.io-client";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);

const Site = () => {
  const [data, setData] = useState([]);
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [isNotFound, setIsNotFound] = useState(false);
  const [pin, setPin] = useState(false);
  const [siteId, setSiteId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isImageLarge, setIsImageLarge] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [credentials, setCredentials] = useState({ status: "all", name: "" });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const handleInput = (e) => {
    setCurrentPage(1);
    setCredentials((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      fetchData(updatedCredentials);
      return updatedCredentials;
    });
  };
  const [credentials1, setCredentials1] = useState({
    number: "",
  });
  const checkPin = async () => {
    if (credentials1.number === pin) {
      try {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(`${url}/api/sites/update/request/status`, {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            id: siteId,
            userId: isAuthenticated._id,
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const json = await response.json();
        if (json.success) {
          fetchData(credentials);
          setIsOpen(false);
        }
      } catch (error) {
        notification.error({
          message: "Failed",
          description: "Something went wrong!",
          duration: 3,
        });
      }
    } else {
      notification.error({
        message: "Failed",
        description: "Invalid Pin",
        duration: 3,
      });
    }
  };
  const handleInput1 = (e) => {
    setCredentials1({ ...credentials1, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    fetchData(credentials);
  }, [currentPage]);
  const fetchData = async (fetchCredentials) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(
        `${url}/api/sites/getSites?page=${currentPage}&limit=${itemsPerPage}&name=${fetchCredentials.name}&status=${fetchCredentials.status}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.site_data.length > 0) {
        setIsDisplay(true);
        setData(json.site_data);
        console.log("=== json site data ===", json.site_data);
      } else {
        setIsNotFound(true);
      }
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  function closeModalPin() {
    setIsOpen(false);
  }
  const enterPin = async (site, pin) => {
    setPin(pin);
    setSiteId(site);
    setIsOpen(true);
  };
  const tokenRequestExpiration = async (date) => {
    const createdAt = new Date(date);
    const diffInMs = new Date() - createdAt;
    const hoursSinceCreation = diffInMs / (1000 * 60 * 60);
    const isExpired = hoursSinceCreation >= 24;
    if (isExpired) {
      return true;
    } else {
      return false;
    }
  };
  const handleResetBtn = () => {
    const updatedCredentials = {
      name: "",
      status: "all",
    };
    // Update state and then call fetchData
    setCredentials(updatedCredentials);
    setCurrentPage(1);
    // Call fetchData with the updated credentials
    fetchData(updatedCredentials);
  };
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Delete it!",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(`${url}/api/sites/site/delete/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        });
        if (response.ok) {
          fetchData(credentials); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "Site deleted successfully!", "success");
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  const toggleImageSize = () => {
    setIsImageLarge(!isImageLarge);
  };
  const openModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImageUrl("");
  };
  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const sendPin = async (id) => {
    try {
      setIsLoading1(true);
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/create/access/pin`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id: id,
          userId: isAuthenticated._id,
        }),
      });
      setIsLoading1(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.success) {
        socket.emit("userAccessRequest");
        notification.success({
          message: "Success",
          description: "Request send successfully",
          duration: 3,
        });
      }
    } catch (error) {
      setIsLoading1(false);
      notification.error({
        message: "Failed",
        description: "Something went wrong!",
        duration: 3,
      });
    }
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap">
                <h3>Sites</h3>
                <p>List of all the sites registered in the system</p>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="filter-right-sec">
                <div className="filter-wrap">
                  <div className="filter-box" onClick={toggleFilterSection}>
                    <img src={FilterIcon} alt="" />
                  </div>
                </div>
                <Link to="/add/site" className="btn btn-prim w-auto">
                  Add New Site
                </Link>
              </div>
            </div>
          </div>
          {isFilterVisible && (
            <>
              <motion.div
                className={`row mt-5 filter-section`}
                initial={{ opacity: 0 }} // Initial state (hidden)
                animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
                transition={{ duration: 1.2, delay: 0.1 }}
              >
                <div className="col-12">
                  <div className="filter-right-sec filter-wrap-chat">
                    <div className="search-wrap">
                      <input
                        type="search"
                        placeholder="search by username"
                        name="name"
                        value={credentials.name}
                        onChange={handleInput}
                      />
                      <div className="search-box">
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <div className="filter-select d-flex align-items-center">
                      <label htmlFor="">Status</label>
                      <select
                        name="status"
                        id=""
                        value={credentials.status}
                        onChange={handleInput}
                      >
                        <option value="all">Choose Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                    <Link
                      to=""
                      onClick={handleResetBtn}
                      className="btn btn-prim w-auto"
                    >
                      RESET
                    </Link>
                  </div>
                </div>
              </motion.div>
            </>
          )}
        </div>
        {/* row */}
        <div className="table-wrap">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                          <th>Site QR</th>
                          <th>Site Name</th>
                          <th>Status</th>
                          <th>Complete Address</th>
                          <th>Site IP</th>
                          <th>Created at</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <>
                            <tr>
                              <td
                                colSpan={5}
                                className="text-center"
                                style={{ background: "transparent" }}
                              >
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        {isDisplay &&
                          data.map((item, i) => (
                            <motion.tr
                              key={item._id}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 1.2, delay: i * 0.1 }}
                            >
                              <td>
                                <div className="usr_det_tb">
                                  <div className="img_wrap_qr_tb">
                                    {item.qr_code && item.qr_code !== "" && (
                                      <>
                                        <img
                                          className={`not_prof_img`}
                                          src={`/${item.qr_code}`}
                                          onClick={() =>
                                            openModal(`/${item.qr_code}`)
                                          }
                                          alt=""
                                        />
                                        <Images
                                          isOpen={isModalOpen}
                                          imageUrl={selectedImageUrl}
                                          onClose={closeModal}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>{" "}
                              </td>
                              <td className="text-capitalize">{item.name}</td>
                              <td>
                                <span className="status success">
                                  {item.status}
                                </span>
                              </td>
                              <td>{item.address}</td>
                              <td>{item.ip_address}</td>
                              <td>{formattedDate(item.createdAt)}</td>
                              <td>
                                <div className="d-flex justify-content-end gap-1">
                                  {isAuthenticated.role.toLowerCase() ===
                                    "super admin" &&
                                    tokenRequestExpiration(
                                      item.req_created
                                    ) && (
                                      <button
                                        type="button"
                                        className="btn btn-success tb-btn"
                                        onClick={() => {
                                          sendPin(item._id);
                                        }}
                                        disabled={isLoading1}
                                      >
                                        {isLoading1
                                          ? "Getting..."
                                          : "Get Site Access"}

                                        {}
                                      </button>
                                    )}
                                  {isAuthenticated.role.toLowerCase() ===
                                    "super admin" &&
                                    item.req_status === "approved" && (
                                      <button
                                        type="button"
                                        className="btn btn-success tb-btn"
                                        onClick={() => {
                                          enterPin(item._id, item.pin);
                                        }}
                                      >
                                        Enter Pin
                                      </button>
                                    )}

                                  <Link
                                    to={`/${item.slug}/lockers`}
                                    className="btn btn-prim w-auto mt-0"
                                  >
                                    Lockers
                                  </Link>

                                  <Link to={`/edit/site/${item._id}`}>
                                    <button
                                      type="button"
                                      className="border-0 btn"
                                    >
                                      <img src={Edit} alt="edit" />
                                    </button>
                                  </Link>
                                  <button
                                    type="button"
                                    className="border-0 btn"
                                    onClick={() => handleDelete(item._id)}
                                  >
                                    <img src={Delete} alt="delete" />
                                  </button>
                                </div>
                              </td>
                            </motion.tr>
                          ))}
                        {isNotFound && (
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="table-num">
                    {data.length > 0 && (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={totalPages}
                        forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                        onPageChange={handlePageChange}
                        containerClassName={" dig-num"}
                        pageClassName={"dig-num"}
                        previousClassName={"num-btns"}
                        nextClassName={"num-btns"}
                        disabledClassName={"pagination-disabled"}
                        activeClassName={"pagination-active"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onClose={closeModal}
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div className="d-flex justify-content-between align-items-center flex-row">
          <img src={SendIcon} alt="send" height={40} width={40} />
          <img
            src={CrossIcon}
            alt="cross"
            onClick={closeModalPin}
            height={37}
            width={37}
          />
        </div>

        <div className="modal-body">
          <div
            className="p-3 w-sm-50 h-sm-50 col-12 rounded-3"
            style={{ background: "#fff" }}
          >
            <div>
              <h5 className="fs-6 m-2" style={{ fontWeight: "700" }}>
                Please Enter Your Pin
              </h5>
            </div>
            <div
              className="p-1 rounded-2 w-sm-25  " //h-75
              style={{ background: "#fff" }}
            >
              <div className="d-flex flex-column">
                <div>
                  <input
                    type="number"
                    className="form-control"
                    id="number"
                    name="number"
                    value={credentials1.number}
                    onChange={handleInput1}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center flex-row flex-wrap gap-2 mt-4">
                <button
                  className="  border border-2 border-blue rounded-pill px-3 py-2 shadow "
                  style={{
                    fontWeight: "700",
                    fontFamily: "Arial",
                    fontSize: "12px",
                    textDecoration: "none",
                    background: "#ff0000",
                    color: "#fff",
                  }}
                  onClick={closeModalPin}
                >
                  Cancel
                </button>
                <button
                  className=" border border-2 border-blue rounded-pill px-3 py-2 shadow "
                  style={{
                    fontWeight: "700",
                    fontFamily: "Arial",
                    fontSize: "12px",
                    textDecoration: "none",
                    background: "#012267",
                    color: "#fff",
                  }}
                  onClick={checkPin}
                >
                  Enter
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Site;
