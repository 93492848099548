import React, { useEffect, useCallback, useRef, useState } from "react";
import Modal from "./Modal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import DeclineIcon from "../../images/decline-call.png";
import AttendVideoIcon from "../../images/attend-video.png";
import InvidLogo from "../../assets/invid-logo.png";
import qr_img from "../../assets/new-design/scanner.svg";
import ringTone from "../../assets/ringcall.mp3";
import { toast } from "react-toastify";
import { Html5QrcodeScanner } from "html5-qrcode";
import { loginSuccess } from "../Redux/Authentication";
import { notification } from "antd";
import SendIcon from "../../assets/icons/send_icon.svg";
import CrossIcon from "../../assets/icons/cross_icon.svg";
import HashLoader from "react-spinners/ClipLoader";
import Cross from "../../assets/new-design/cross_icon.svg";
import PinIcon from "../../assets/new-design/Pin-icon.png";
// const url = process.env.REACT_APP_APP_BACK_URL;
const url = process.env.REACT_APP_APP_BACK_URL;
const imageUrl = process.env.REACT_APP_APP_IMAGE_URL;
const socket = io.connect(url);
// import { useSocket } from "../templates/Sockets/SocketProvider";
const Header = (props) => {
  // const url = process.env.REACT_APP_APP_BACK_URL;
  let navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen1, setIsOpen1] = React.useState(false);
  const ringCall = useRef(null);
  let { slug } = useParams();
  const [displayscaner, setdisplayscaner] = useState(false);
  const [room, setRoom] = useState(null);
  const [sender, setSender] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [getNotification, setNotification] = useState([]);
  const [result, setResult] = useState("");
  const [backgroundAudio] = useState(new Audio(ringTone));
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user);
  const [temparature, settemparature] = useState("66.0");
  const [siteLogo, setSiteLogo] = useState(url + "/locker_logo.png");
  const [notificationData, setNotificationData] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [notLoggegIn, setLoggedIn] = React.useState(false);
  const [scannerInitialized, setScannerInitialized] = useState(false);
  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [fileExists, setFileExists] = useState(null);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [credentials, setCredentials] = useState({
    number: "",
  });
  const [enterOTP, setEnterOTP] = useState(false);
  const [qrCodeTimestamp, setQrCodeTimestamp] = useState(Date.now());
  const firstInputRef = useRef(null);
  let scanner;
  useEffect(() => {
    if (!scannerInitialized && modalIsOpen1) {
      scanner = new Html5QrcodeScanner("reader", {
        qrbox: {
          width: 250,
          height: 250,
        },
        fps: 5,
      });
      scanner.render(success, error);
      setScannerInitialized(true);
    }
    function success(result) {
      scanner.clear();
      // setIsLoading(true);

      fetchUserData(result);
    }
    function error(err) {
      console.warn(err);
    }
    return () => {
      if (scannerInitialized) {
        // scanner.clear();
        setScannerInitialized(false);
      }
    };
  }, [scannerInitialized, modalIsOpen1]);
  useEffect(() => {
    if (authenticationFailed) {
      // Reinitialize the scanner if authentication fails
      setScannerInitialized(false);
      setAuthenticationFailed(false);
    }
  }, [modalIsOpen1, authenticationFailed]);
  useEffect(() => {
    let interval;
    const handleKeyDown = (evt) => {
      if (interval) clearInterval(interval);
      if (evt.code === "Enter") {
        if (barcode) fetchUserData(barcode);
        setBarcode("");
        return;
      }
      if (evt.key !== "Shift")
        setBarcode((prevBarcode) => prevBarcode + evt.key);
      interval = setInterval(() => setBarcode(""), 20);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [barcode]);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);
  const formatDate = (date) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return (
      <>
        <div>{dayOfWeek}</div>
        <div>
          {day} {month} {year} {hours}:{minutes}:{seconds}
        </div>
      </>
    );
  };
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    socket.emit("login");
    socket.on("notification", (data) => {
      if (ringCall.current) {
        ringCall.current.click();
      }
      setSender(data.senderId);
      setIsOpen(true);
    });
    socket.on("messageNotification", (data) => {
      // SmsNotificationList();
    });
  }, [isAuthenticated]);
  let subtitle;
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
    setIsOpen1(false);
    setEnterOTP(false);
    setOtp(["", "", "", ""]);
  }
  // function joinRoom() {
  //   setIsOpen(false);
  //   navigate(`/video/call/${sender}/?admin=true`);
  // }
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSiteDataSlug/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSiteLogo(imageUrl + json.site.image);
        setQrCode(json.site.qr_code);
        checkFileExists(json.site.qr_code);
      } else {
        toast.error("No site found! Enter valid site");
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    siteData();
  }, [qrCode]);
  function joinRoom() {
    backgroundAudio.pause();
    // Pause the video playback
    setIsOpen(false);
    navigate(`/room/${sender}/?remote=true`);
  }
  const ring = async () => {
    backgroundAudio.play();
  };
  const handleLogout = async (e) => {
    const id = localStorage.getItem("token");
    const logouts = await localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", id);
    const response = await fetch(
      `${url}/api/auth/user/update/chatStatusOffline`,
      {
        mode: "cors",
        method: "GET",
        headers: headers,
      }
    );
  };
  const fetchNewQRCode = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/update-qr`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          slug: slug,
        }),
      });
      const json = await response.json();
      if (json.success) {
        setQrCodeTimestamp(Date.now());
      }
    } catch (error) {
      // notification.error({
      //   message: "Failed",
      //   description: error.message,
      //   duration: 3,
      // });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Fetch the new QR code here
      fetchNewQRCode();
      // setCurrentDateTime(new Date());
    }, 10000); // Update every 5 seconds

    return () => {
      clearInterval(intervalId); // Cleanup interval on unmount
    };
  }, []);
  const sendPind = async () => {
    try {
      const formattedOtp = otp.join("");
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/locker/checkUserPin`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          pin: formattedOtp,
        }),
      });

      const json = await response.json();
      if (json.success) {
        dispatch(loginSuccess(json.user));
        setEnterOTP(false);
        notification.success({
          message: "Success",
          description: "Successfully Logged In",
          duration: 3,
        });
        setOtp(["", "", "", ""]);
      } else {
        notification.error({
          message: "Failed",
          description: json.error,
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "Failed",
        description: error.message,
        duration: 3,
      });
    }
  };
  const handleButton = async () => {
    setEnterOTP(true);
  };
  const handleButton1 = async () => {
    setdisplayscaner(true);
    setIsOpen1(true);
  };
  const handleReset = () => {
    setOtp(["", "", "", ""]);
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  };

  const fetchUserData = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(`${url}/api/auth/user/edit/${id}`, {
        mode: "cors",
        method: "get",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      setIsOpen(false);
      setIsOpen1(false);
      if (json.success) {
        notification.success({
          message: "Success",
          description: "Successfully Logged In",
          duration: 3,
        });

        dispatch(loginSuccess(json.user));
      } else {
        notification.error({
          message: "Failed",
          description: json.message,
          duration: 3,
        });
      }
    } catch (error) {
      setIsOpen(false);
      setIsOpen1(false);
      notification.error({
        message: "Failed",
        description: error.message,
        duration: 3,
      });
    }
  };
  // fetch data
  const checkFileExists = async (img) => {
    try {
      const response = await fetch(img);
      if (response.status === 200) {
        setFileExists(true);
      } else {
        setFileExists(false);
      }
    } catch (error) {
      setFileExists(false);
    }
  };
  // const focusNext = (current) => {
  //   const maxLength = parseInt(current.getAttribute("maxlength"));
  //   const currentLength = current.value.length;

  //   if (currentLength >= maxLength) {
  //     const next = current.nextElementSibling;
  //     if (next && next.tagName === "INPUT") {
  //       next.focus();
  //     }
  //   }
  // };

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const focusNext = (index, value) => {
    if (index < 3 && value !== "") {
      const nextInput = document.getElementsByClassName("otp-input")[index + 1];
      nextInput.focus();
    }
  };
  const readerStyle = {
    width: "400px",
    position: "relative",
    padding: "0px",
  };
  // const qrShade = {
  //   position: "absolute",
  //   borderWidth: "62px 124px",
  //   borderStyle: "solid",
  //   borderColor: "rgba(0, 0, 0, 0.48)",
  //   boxSizing: "border-box",
  //   inset: "0px",
  // };
  return (
    <div className="mb-2 mb-sm-0">
      <header className="header mb-5 mb-sm-0">
        <div className="row mb-2">
          <div className="prof-sett px-4">
            <div className="col-4 d-flex justify-content-start ">
              <div className="main-heading">
                <div className="locker-logo">
                  <div className="locker-style-image d-flex justify-content-center ">
                    <img
                      className="logo-image-style img-fluid mx-auto"
                      src={InvidLogo}
                      alt="Logo"
                      height={80}
                      width={150}
                      onClick={() => navigate("/dashboard")}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-4 d-flex justify-content-center ">
              <h3 className="fw-700 bg-white p-2 rounded shadow-sm">
                {temparature}°F
              </h3>
            </div> */}
            <div className="col-4 d-flex justify-content-center ">
              <div className="">
                {fileExists ? (
                  <img
                    src={`/${qrCode}?t=${qrCodeTimestamp}`}
                    className="d-none d-sm-block"
                    alt="QR Scanner"
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "5px",
                      marginBottom: "3px",
                      marginTop: "10px",
                      marginRight: "15px",
                    }}
                  />
                ) : (
                  <img
                    src={qr_img}
                    className="d-none d-sm-block"
                    alt="QR Scanner"
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "5px",
                      marginBottom: "3px",
                      marginTop: "10px",
                      marginRight: "15px",
                    }}
                  />
                )}
              </div>

              <div className="d-flex flex-column justify-content-center gap-1">
                <button
                  className="btn btn-prim w-auto py-1"
                  onClick={handleButton1}
                >
                  Scan QR Code
                </button>
                <button
                  className="btn btn-prim btn-transparent w-auto py-1"
                  onClick={handleButton}
                >
                  Enter Pin
                </button>
                {/* <button className="btn btn-prim-slider" onClick={handleButton}>
                  Enter Pin
                </button> */}
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end ">
              <div className="d-flex justify-content-center flex-column">
                <p className="text-end fw-bold">
                  {formatDate(currentDateTime)}
                </p>
                {/* <div className="d-flex justify-content-center flex-row gap-2">
                  {formatDate(currentDateTime)}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>
      <a ref={ringCall} onClick={ring}></a>
      {/* <Modal isOpen={modalIsOpen} onClose={closeModal}>
        <div className="modal-body">
          <div className="row h-100">
            <div className="col-12 mt-auto">
              <div className="incoming-call-sec">
                <h3 className="text-center">Inviting you for a video chat</h3>
                <div className="call-detail-bar">
                  <div className="call-options-wrap">
                    <div className="icons-wrap-options">
                      <div className="icon-dec">
                        <img src={DeclineIcon} alt="" onClick={closeModal} />
                      </div>
                      <div className="icon-att-vid" onClick={joinRoom}>
                        <img src={AttendVideoIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
      <Modal
        isOpen={modalIsOpen}
        onClose={closeModal}
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div className="d-flex justify-content-between align-items-center flex-row">
          <img src={SendIcon} alt="send" height={40} width={40} />
          <img
            src={CrossIcon}
            alt="cross"
            onClick={closeModal}
            height={37}
            width={37}
          />
        </div>

        <div className="modal-body">
          {isLoading ? (
            <div className="text-center">
              <HashLoader
                color={`#0b0d41`}
                loading={isLoading}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            displayscaner && <div id="reader" style={readerStyle}></div>
          )}
          <div
            className="p-3 w-sm-50 h-sm-50 col-12 rounded-3"
            style={{ background: "#fff" }}
          >
            <div>
              <h5 className="fs-6 m-2" style={{ fontWeight: "700" }}>
                Please Enter Your Pin
              </h5>
            </div>
            <div
              className="p-1 rounded-2 w-sm-25  " //h-75
              style={{ background: "#fff" }}
            >
              <div className="d-flex flex-column">
                <div>
                  <input
                    type="number"
                    className="form-control"
                    id="number"
                    name="number"
                    value={credentials.number}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center flex-row flex-wrap gap-2 mt-4">
                <button
                  className="btn btn-prim btn-transparent w-auto px-4"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button className="btn btn-prim w-auto px-4" onClick={sendPind}>
                  Enter
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen1}
        onClose={closeModal}
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div className="d-flex justify-content-between align-items-center flex-row">
          <img src={SendIcon} alt="send" height={40} width={40} />
          <img
            src={CrossIcon}
            alt="cross"
            onClick={closeModal}
            height={37}
            width={37}
          />
        </div>

        <div className="modal-body">
          {isLoading ? (
            <div className="text-center">
              <HashLoader
                color={`#0b0d41`}
                loading={isLoading}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            displayscaner && <div id="reader" style={readerStyle}></div>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={enterOTP}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <img src={PinIcon} alt="pin" width={28} />
          <img src={Cross} alt="cross" onClick={closeModal} width={28} />
        </div>
        <div className="p-4 w-sm-50 h-sm-50 col-12 rounded-3">
          <div>
            <h2>Enter Your PIN</h2>
            <p
              style={{
                fontSize: "12px",
                color: "#999999",
              }}
            >
              Enter one time pin you have received via message
            </p>

            <div className="otp-input-container">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  className="otp-input"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => {
                    handleChange(index, e.target.value);
                    focusNext(index, e.target.value);
                  }}
                  onKeyUp={(e) => focusNext(index, e.target.value)}
                  ref={index === 0 ? firstInputRef : null}
                />
              ))}
            </div>
            <div className="d-flex justify-content-center flex-row gap-2">
              <button
                className="btn btn-prim btn-transparent"
                onClick={handleReset}
              >
                Reset
              </button>
              <button className="btn btn-prim " onClick={sendPind}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Header;
