import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import pickupImg from "../../../images/locker_detail.png";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "sweetalert2/dist/sweetalert2.min.css";
import { notification } from "antd";
import ContentLoader from "react-content-loader";
import BackIcon from "../../../assets/icons/icon-back.svg";
const LockerDetail = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const isAuthenticated = useSelector((state) => state.user);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [isDoorOpen, setDoorOpen] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisplay, setIsDisplay] = useState(false);
  const [credentials, setCredentials] = useState({});
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(`${url}/api/locker/request/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.success) {
        setCredentials({ ...json.data[0] });
      } else {
        setIsNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  const approvedRequest = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setDoorOpen(true);
      const response = await fetch(`${url}/api/locker/approved/request/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setDoorOpen(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.success) {
        fetchData();
        notification.success({
          message: "Success",
          description: "Request approved successfully",
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: "Something went wrong!",
          duration: 3,
        });
      }
    } catch (error) {
      setDoorOpen(false);
      notification.error({
        message: "Failed",
        description: error.message,
        duration: 3,
      });
    }
  };

  const declineRequest = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLockerOpen(true);
      const response = await fetch(`${url}/api/locker/declined/request/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLockerOpen(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.success) {
        fetchData();
        notification.success({
          message: "Success",
          description: "Request declined successfully",
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: "Something went wrong!",
          duration: 3,
        });
      }
    } catch (error) {
      setIsLockerOpen(false);
      notification.error({
        message: "Failed",
        description: error.message,
        duration: 3,
      });
    }
  };
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 text-start">
            {isLoading && (
              <div className="col-12 px-4">
                <ContentLoader
                  viewBox="0 0 400 160"
                  height={160}
                  width={400}
                  backgroundColor="#142E98"
                >
                  <circle cx="150" cy="86" r="8" />
                  <circle cx="194" cy="86" r="8" />
                  <circle cx="238" cy="86" r="8" />
                </ContentLoader>
              </div>
            )}

            <div className="form-box card-box" style={{ padding: "0px" }}>
              <div className="p-4">
                <div className="table-responsive">
                  <table className="table table-sm table-striped">
                    <tbody>
                      <tr>
                        <td className={styles.locker_label}>Locker #</td>
                        <th className={styles.locker_label_value}>
                          {credentials.locker}
                        </th>
                      </tr>

                      <tr>
                        <td className={styles.locker_label}>User</td>
                        <th className={styles.locker_label_value}>
                          {credentials.user}
                        </th>
                      </tr>
                      <tr>
                        <td className={styles.locker_label}>Site</td>
                        <th className={styles.locker_label_value}>
                          {credentials.site}
                        </th>
                      </tr>
                      <tr>
                        <td className={styles.locker_label}>Current Status</td>
                        <td>
                          <badge className="badge bg-warning">
                            {credentials.status}
                          </badge>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.locker_label}>Requested Date</td>
                        <th className={styles.locker_label_value}>
                          {formattedDate(credentials.createdAt)}
                        </th>
                      </tr>
                      <tr>
                        <td className={styles.locker_label}>
                          {credentials.status === "pendding" && (
                            <>
                              <Link
                                relay
                                className="btn btn-warning btn-sm m-1"
                                onClick={approvedRequest}
                              >
                                {isDoorOpen
                                  ? "Please wait..."
                                  : "Aprroved Request"}
                              </Link>

                              <Link
                                className="btn btn-danger btn-sm m-1"
                                onClick={declineRequest}
                                disabled={isLockerOpen}
                              >
                                {" "}
                                {isLockerOpen
                                  ? "Please wait..."
                                  : "Decline Request"}
                              </Link>
                            </>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-7 text-end">
            <img src={pickupImg} style={{ width: "100%", height: "100%" }} />
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default LockerDetail;
