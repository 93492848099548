import React, { useEffect, useState } from "react";
import BackIcon from "../../../assets/icons/icon-back.svg";
import UploadIcon from "../../../images/icon-upload.png";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";
import InputMask from "react-input-mask";
import { Auth } from "aws-amplify";
import { awsExports } from "../../../aws-exports";
import { CognitoIdentityServiceProvider } from "aws-sdk";
function PhoneInput(props) {
  return (
    <InputMask
      mask="+1999 999 9999"
      value={props.value}
      onChange={props.onChange}
      placeholder="+1123 123 1234"
      name="phone"
      className="form-control"
    ></InputMask>
  );
}

const cognito = new CognitoIdentityServiceProvider({
  region: awsExports.REGION, // e.g., 'us-east-1'
  accessKeyId: awsExports.AWS_ACCESS_KEY_ID,
  secretAccessKey: awsExports.AWS_SECRET_ACCESS_KEY,
});
const UserEdit = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const [getRoles, setRoles] = useState([]);
  const [getSite, setSite] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [mediaShow, setMediaShow] = useState(false);
  const [getLocker, setLockerData] = useState([]);
  const [lockers, setLockers] = useState(false);
  const [defaultPermission, setDefaultPermission] = useState([]);
  const handlePhoneInput = (e) => {
    setPhone(e.target.value);
  };
  useEffect(() => {
    roleData();
    siteData();
    getUser();
  }, []);
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    site: "",
    pin_code: "",
    address: "",
    level: "",
    status: "",
    aws_userid: "",
    aws_username: "",
  });
  const handleInput = (e) => {
    if (e.target.name.toLowerCase() === "site" && credentials.level === "2") {
      getSiteLockers(e.target.value);
    }
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (credentials.aws_username) {
      const updatedAttributes = [
        {
          Name: "given_name",
          Value: credentials.name,
        },
        {
          Name: "family_name",
          Value: credentials.name,
        },
        {
          Name: "address",
          Value: credentials.address,
        },
      ];
      const params = {
        UserPoolId: awsExports.USER_POOL_ID, // Replace with your Cognito User Pool ID
        Username: credentials.aws_username, // The Cognito user you want to update
        UserAttributes: updatedAttributes, // Array of attributes you want to update
      };
      await cognito.adminUpdateUserAttributes(params).promise();
    }

    // const awsUserId = credentials.aws_userid; // Assuming you have stored the AWS user ID
    // const currentUser = await Auth.currentAuthenticatedUser();
    // if (currentUser) {
    //   await Auth.updateUserAttributes(currentUser, {
    //     email: credentials.email,
    //     given_name: credentials.name,
    //     family_name: credentials.name,
    //     address: credentials.address,
    //   });
    // }
    // Step 1: Update User in AWS Cognito (if needed, you can skip this if only updating in MongoDB)

    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    formData.append("id", id);
    formData.append("name", credentials.name);
    formData.append("address", credentials.address);
    formData.append("aws_userid", credentials.aws_userid);
    formData.append("email", credentials.email);
    formData.append("role", credentials.role);
    formData.append("site", credentials.site);
    formData.append("pin_code", credentials.pin_code);
    formData.append("status", credentials.status);
    formData.append("level", credentials.level);
    defaultPermission.forEach((permissionId) => {
      formData.append("permissions", permissionId);
    });
    const response = await fetch(`${url}/api/auth/user/update`, {
      method: "POST",
      body: formData,
    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setPhone("");
      notification.success({
        message: "Success",
        description: "User updated Successfully",
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: "Failed to update user",
        duration: 3,
      });
    }
  };
  const roleData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/roles/getRoles`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setRoles(json);
    } catch (error) {
      console.error(error);
    }
  };
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/list`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setSite(json.site_data);
    } catch (error) {
      console.error(error);
    }
  };
  const getUser = async () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(`${url}/api/auth/user/edit/${id}`, {
      mode: "cors",
      method: "get",
      headers: headers,
    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      let usr = json.user;

      if (usr.level === "2") {
        setDefaultPermission(usr.level_permission);
        getSiteLockers(usr.site);
        setLockers(true);
      }
      setCredentials({
        name: usr.name,
        email: usr.email,
        phone: usr.phone,
        role: usr.role,
        site: usr.site,
        level: usr.level,
        pin_code: usr.pin_code,
        status: usr.status,
        aws_userid: usr.aws_userid,
        aws_username: usr.aws_username,
        address: usr.address,
      });
    } else {
    }
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setMediaShow(false);
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        setFile(file);
        setError(null);
        // Create a FileReader
        const reader = new FileReader();
        // Add a progress event listener to the FileReader
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const percentUploaded = Math.round((e.loaded / e.total) * 100);
            setUploadProgress(percentUploaded);
          }
        };
        // Read the file (you can also add other event listeners like 'onload')
        reader.readAsDataURL(file);
      } else {
        setFile(null);
        setError("Invalid file type. Please select an image file.");
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };
  const getSiteLockers = async (site) => {
    try {
      if (site !== "") {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(`${url}/api/locker/siteLockers/${site}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        });
        const json = await response.json();
        if (json.success) {
          setLockerData(json.data);
        }
      } else {
        notification.error({
          message: "Failed",
          description: "Please select site first to assign level!",
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "Failed",
        description: "Internel server issue",
        duration: 3,
      });
    }
  };
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    // If the checkbox is checked, add the value to the state; otherwise, remove it
    if (checked) {
      setDefaultPermission((prevPermissions) => [...prevPermissions, value]);
    } else {
      setDefaultPermission((prevPermissions) =>
        prevPermissions.filter((permission) => permission !== value)
      );
    }
  };
  const handleLevel = (e) => {
    if (e.target.value.toLowerCase() === "2") {
      getSiteLockers(credentials.site);
      setLockers(true);
    } else {
      setLockers(false);
    }
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/user/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Edit User</h3>
                  <p>Enter the credentials to add new user to the system</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Name</h4>
                      <p>Enter the precise name of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fuserName"
                        placeholder="Stephen"
                        name="name"
                        value={credentials.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Email</h4>
                      <p>Enter email address of the user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="email"
                        className="form-control"
                        id="emailadd"
                        placeholder="Email Address"
                        name="email"
                        value={credentials.email}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>User Role</h4>
                      <p>Select the role that the user will play</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="role"
                        value={credentials.role}
                        onChange={handleInput}
                        required
                      >
                        <option value="">Choose Role</option>
                        {getRoles.map((per) => (
                          <option key={per._id} value={per.name}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>User Status</h4>
                      <p>Select the current status of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="status"
                        value={credentials.status}
                        onChange={handleInput}
                      >
                        <option value="">Choose Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>User Site</h4>
                      <p>Select the site for user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="site"
                        value={credentials.site}
                        onChange={handleInput}
                        required
                      >
                        <option value="">Choose Site</option>
                        {getSite.map((per) => (
                          <option
                            key={per._id}
                            value={per._id}
                            selected={per._id === credentials.site}
                          >
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Pin_Code</h4>
                      <p>Enter the pin_code of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="pin_code"
                        placeholder="Stephen"
                        name="pin_code"
                        value={credentials.pin_code}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Address</h4>
                      <p>Enter the address of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        placeholder="Enter address"
                        name="address"
                        value={credentials.address}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Picture</h4>
                      <p>Upload the clear picture of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <div className="file-wrap">
                        <input type="file" onChange={handleFileSelect} />
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="file_name">
                            {file ? file.name : "No file selected"}
                          </span>{" "}
                          <img src={UploadIcon} alt="Upload Icon" />
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "red",
                          fontStyle: "italic",
                        }}
                      >
                        {uploadProgress > 0 && uploadProgress < 100 && (
                          <span>{uploadProgress}% uploaded</span>
                        )}
                      </div>
                      {error && (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {error}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Level Assigned</h4>
                      <p>Select the current level of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="level"
                        value={credentials.level}
                        onChange={handleLevel}
                      >
                        <option value="">Choose Level</option>
                        <option value="1">1-CAN OPEN ANY LOCKER</option>
                        <option value="2">2-CAN OPEN SOME LOCKERS</option>
                        <option value="3">3-NEEDS PERMISSION FROM</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              {lockers && (
                <div className="col-lg-12">
                  {/* input row */}
                  <div className="row">
                    <div className="col-xl-5 col-lg-5">
                      <div className="label-wrap">
                        <h4>Lockers</h4>
                        <p>Check lockers to open user</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {getLocker.map((item, key) => (
                      <>
                        <div className="col-xl-2 col-lg-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item._id}
                            id="flexCheckDefault"
                            checked={
                              defaultPermission.includes(item._id)
                                ? "checked"
                                : ""
                            }
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            {" "}
                            {item.number}
                          </label>{" "}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              )}
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <a href="" className="btn btn-prim btn-transparent w-auto">
                    Reset Form
                  </a>
                  <button
                    type="submit"
                    disabled={isLoading}
                    href=""
                    className="btn btn-prim w-auto ms-5"
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                    Update User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default UserEdit;
