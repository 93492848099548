import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { notification } from "antd";
import profileImg from "../../../images/editProfile.png";
import avatar from "../../../images/avatar1.png";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../Redux/Authentication";
import InputMask from "react-input-mask";
import BackIcon from "../../../assets/icons/icon-back.svg";
import { Auth } from "aws-amplify";
function PhoneInput(props) {
  return (
    <InputMask
      mask="+1999 999 9999"
      value={props.value}
      onChange={props.onChange}
      placeholder="+1123 123 1234"
      className="form-control"
    ></InputMask>
  );
}
const EditProfile = () => {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const isAuthenticated = useSelector((state) => state.user);
  const [ProLoading, setProLoading] = useState(false);
  const [PassLoading, setPassLoading] = useState(false);
  const [ImgLoading, setImgLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    name: isAuthenticated ? isAuthenticated.name : "",
    email: isAuthenticated ? isAuthenticated.email : "",
    pin_code: isAuthenticated ? isAuthenticated.pin_code : "",
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const [phone, setPhone] = useState(
    isAuthenticated ? isAuthenticated.phone : ""
  );
  const handlePhoneInput = (e) => {
    setPhone(e.target.value);
  };
  const [profileImage, setProfileImg] = useState(
    isAuthenticated?.image || avatar
  );
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [seletectedFile, setSelectedFile] = useState("");
  const profileFormSubmit = async (e) => {
    e.preventDefault();
    if (isAuthenticated && isAuthenticated.aws_userid) {
      const currentUser = await Auth.currentAuthenticatedUser();
      if (currentUser) {
        await Auth.updateUserAttributes(currentUser, {
          email: credentials.email,
          given_name: credentials.name,
          family_name: credentials.name,
        });
      }
    }
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", localStorage.getItem("token"));
    setProLoading(true);
    const response = await fetch(`${url}/api/profile/setting`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        name: credentials.name,
        email: credentials.email,
        phone: phone,
        pin_code: credentials.pin_code,
      }),
    });
    setProLoading(false);

    const json = await response.json();
    if (json.success) {
      dispatch(loginSuccess(json.user));
      notification.success({
        message: "Success",
        description: "Profile updated successfully",
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };
  const passwordFormSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", localStorage.getItem("token"));
    setPassLoading(true);
    const response = await fetch(`${url}/api/profile/password/change`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        current_password: credentials.current_password,
        new_password: credentials.new_password,
        confirm_password: credentials.confirm_password,
      }),
    });
    setPassLoading(false);

    const json = await response.json();
    console.log(json);
    if (json.success) {
      localStorage.setItem("token", json.authToken);
      dispatch(loginSuccess(json.user));
      setCredentials({
        current_password: "",
        new_password: "",
        confirm_password: "",
      });
      notification.success({
        message: "Success",
        description: "Password updated successfully",
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];

      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
        setError(null);

        // Create a FileReader
        const reader = new FileReader();

        // Add a progress event listener to the FileReader
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const percentUploaded = Math.round((e.loaded / e.total) * 100);
            setUploadProgress(percentUploaded);
          }
        };
        reader.onload = (e) => {
          const dataURL = e.target.result; // This is the data URL of the selected image
          setProfileImg(dataURL); // Set the data URL as the profileImg
        };
        // Read the file (you can also add other event listeners like 'onload')
        reader.readAsDataURL(file);
      } else {
        //setFile(null);

        setError("Invalid file type. Please select an image file.");
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };

  const imgFormSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      if (seletectedFile) {
        formData.append("file", seletectedFile);
      }
      formData.append("id", isAuthenticated._id);
      setImgLoading(true);
      const response = await fetch(`${url}/api/profile/image/change`, {
        method: "POST",
        body: formData,
      });
      setImgLoading(false);
      const json = await response.json();
      if (json.success) {
        dispatch(loginSuccess(json.user));
        notification.success({
          message: "Success",
          description: "Image uploaded Successfully",
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: "failed to upload image",
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/profile">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Edit Profile</h3>
                  <p>Enter the credentials for edit the profile</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <div className="row">
          <div className="col-lg-5 text-center">
            <form onSubmit={imgFormSubmit}>
              <div className="form-box card-box d-flex justify-content-center flex-column text-center mb-5">
                <img
                  src={profileImage}
                  style={{ width: "80px", height: "80px" }}
                />
                <h6 className="mt-2 fw-bold text-capitalize">
                  {isAuthenticated.name}
                </h6>
                <div className="inpt-wrap mt-2">
                  <input
                    type="file"
                    className="form-control"
                    name="file"
                    onChange={handleFileSelect}
                    required
                  />
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontStyle: "italic",
                  }}
                >
                  {uploadProgress > 0 && uploadProgress < 100 && (
                    <span>{uploadProgress}% uploaded</span>
                  )}
                </div>
                {error && (
                  <div
                    style={{
                      fontSize: "12px",
                      color: "red",
                      fontStyle: "italic",
                    }}
                  >
                    {error}
                  </div>
                )}
                <div className="col-12 text-end">
                  <button
                    type="submit"
                    className="btn btn-prim w-auto"
                    disabled={ImgLoading}
                  >
                    Change Photo
                  </button>
                </div>
              </div>
            </form>
            <img src={profileImg} height={250} width={380} />
          </div>
          <div className="col-lg-7">
            <div className="form-box card-box d-flex justify-content-center flex-column ">
              <h6 style={{ color: "#170187", fontWeight: "700" }}>
                Edit Profile
              </h6>
              <form onSubmit={profileFormSubmit}>
                <div className="row mt-2 p-5">
                  <div className="col-lg-12">
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-3 col-lg-3">
                        <div className="label-wrap">
                          <h4>Name</h4>
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-8">
                        <div className="inpt-wrap">
                          <input
                            type="text"
                            className="form-control"
                            id="sitec"
                            placeholder="Name"
                            name="name"
                            defaultValue={
                              isAuthenticated && isAuthenticated.name
                            }
                            onChange={handleInput}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-3 col-lg-3">
                        <div className="label-wrap">
                          <h4>Email Address</h4>
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-8">
                        <div className="inpt-wrap">
                          <input
                            type="text"
                            className="form-control"
                            id="sitec"
                            placeholder="Email"
                            name="email"
                            defaultValue={
                              isAuthenticated && isAuthenticated.email
                            }
                            onChange={handleInput}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-3 col-lg-3">
                        <div className="label-wrap">
                          <h4>Phone Number</h4>
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-8">
                        <div className="inpt-wrap">
                          <PhoneInput
                            value={phone}
                            onChange={handlePhoneInput}
                          ></PhoneInput>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-3 col-lg-3">
                        <div className="label-wrap">
                          <h4>Your Pin</h4>
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-8">
                        <div className="inpt-wrap">
                          <input
                            type="text"
                            className="form-control"
                            id="sitec"
                            placeholder="Pin"
                            name="pin_code"
                            defaultValue={
                              isAuthenticated && isAuthenticated.pin_code
                            }
                            onChange={handleInput}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                  </div>
                  <div className="col-12 text-end">
                    <button
                      type="submit"
                      className="btn btn-prim w-auto"
                      disabled={ProLoading}
                    >
                      Edit Profile
                    </button>
                  </div>
                </div>
              </form>

              <h6
                style={{
                  color: "#170187",
                  fontWeight: "700",
                }}
              >
                Change Password
              </h6>
              <form onSubmit={passwordFormSubmit}>
                <div className="row mt-2 p-5">
                  <div className="col-lg-12">
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-3 col-lg-3">
                        <div className="label-wrap">
                          <h4>Current Password</h4>
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-8">
                        <div className="inpt-wrap">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Current Password"
                            name="current_password"
                            onChange={handleInput}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-3 col-lg-3">
                        <div className="label-wrap">
                          <h4>New Password</h4>
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-8">
                        <div className="inpt-wrap">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="New Password"
                            name="new_password"
                            onChange={handleInput}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-3 col-lg-3">
                        <div className="label-wrap">
                          <h4>Confirm Password</h4>
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-8">
                        <div className="inpt-wrap">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                            name="confirm_password"
                            onChange={handleInput}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                  </div>
                  <div className="col-12 text-end">
                    <button
                      type="submit"
                      className="btn btn-prim w-auto"
                      disabled={PassLoading}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditProfile;
