import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import RootLayout from "../layout/RootLayout";
import { Auth } from "aws-amplify";
// const PrivateRoute = () => {
//     const authed = localStorage.getItem('token');
//     const storedToken = localStorage.getItem('token-expiration');
//     const currentTime = Math.floor(Date.now() / 1000);
//     if (authed && storedToken > currentTime) {
//         return <RootLayout><Outlet /></RootLayout>;
//     } else {
//         return <Navigate to="/" replace />
//     }
// }
function PrivateRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setIsAuthenticated(true);
        setLoading(false);
      })
      .catch(() => {
        setIsAuthenticated(false);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const authed = localStorage.getItem("token");
  const storedToken = localStorage.getItem("token-expiration");
  const currentTime = Math.floor(Date.now() / 1000);
  if (isAuthenticated || (authed && storedToken > currentTime)) {
    return (
      <RootLayout>
        <Outlet />
      </RootLayout>
    );
  } else {
    return <Navigate to="/" replace />;
  }
  // return isAuthenticated ? children : <Navigate to="/" replace />;
}
export default PrivateRoute;
