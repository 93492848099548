import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useSelector } from "react-redux";
import "./couresel.css";
import yeslocker from "../../../assets/new-design/locker.png";
import NoImage from "../../../images/new images/no-photo.png";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { notification } from "antd";
const PractSlider = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const streaming_url = process.env.REACT_APP_STREAMIN_SOCKET_URL;
  let slug = "invid";
  // slug = slug.toLowerCase();
  const [isDisplay, setIsDisplay] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pin, setPin] = useState(false);
  const [selectedLocker, setselectedLocker] = useState("");
  const [lockerId, setLockerId] = useState("");
  const [getLocker, setLocker] = useState([]);
  const isAuthenticated = useSelector((state) => state.user);
  const [user, setUser] = useState({});
  const displayOpen = async (id) => {
    setLockerId(id);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(`${url}/api/locker/site/lockers/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.locker_data.length > 0) {
        console.log(json.locker_data);
        setLocker(json.locker_data);
        setIsDisplay(true);
      } else {
        setIsNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <h1>test</h1>
      <OwlCarousel
        className="owl-carousel owl-theme"
        loop
        margin={10}
        autoWidth
        center
        autoplay
        autoplayTimeout={3000}
        autoplayHoverPause
        responsiveClass={true}
        responsive={{
          0: { items: 1 },
          600: { items: 4 },
          1000: { items: 5 },
        }}
      >
        {getLocker.map((item, key) => (
          <div key={key} className="item">
            <div className="lockers">
              <span className="locker-num">{item.number}</span>
              <>
                <img
                  src={yeslocker}
                  alt="yes"
                  onClick={() => {
                    if (visible) {
                      displayOpen(item._id);
                    }
                  }}
                />
                <div className="product-images-locker">
                  <img
                    onClick={() => {
                      if (visible) {
                        displayOpen(item._id);
                      }
                    }}
                    src={item.product_image ? item.product_image : NoImage}
                    alt="product-image"
                  />
                </div>
                <div className="product-name-locker">
                  <h6 className="text-capitalize">
                    {item.product_name ? item.product_name : "no name"}{" "}
                  </h6>
                </div>
              </>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </>
  );
};

export default PractSlider;
