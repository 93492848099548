import React, { useState, useEffect } from "react";
import { StringeeUtil, StringeeClient,StringeeCall, StringeeCall2 } from "stringee";
const url = process.env.REACT_APP_APP_BACK_URL;
const Customer = () => {
  const [stringeeClient, setStringeeClient] = useState(null);
  const [call, setCall] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [sdkVersion, setSdkVersion] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [loggedUserId, setLoggedUserId] = useState("");
  const [callBtnDisabled, setCallBtnDisabled] = useState(true);
  const [videoCallBtnDisabled, setVideoCallBtnDisabled] = useState(true);
  const [incomingCallDivVisible, setIncomingCallDivVisible] = useState(false);
  useEffect(() => {
    setSdkVersion(
      StringeeUtil.version().version +
        "_build_" +
        StringeeUtil.version().build +
        "_sample_1"
    );
    getAccessToken();
  }, []);

  const handleCall = () => {

    if (!call && stringeeClient) {
      
      const from = "shoaib_4455"; // Customer's user ID
      const to = "842471098576"; // Agent's user ID
      const isVideoCall = true;
      const newCall = new StringeeCall2(stringeeClient, from, to, isVideoCall);
      
      newCall.on("addlocalstream", (stream) => {
        setLocalStream(stream);
      });
  
      newCall.on("addremotestream", (stream) => {
        setRemoteStream(stream);
      });
  
      newCall.makeCall((res) => {
        console.log('call connected',res);
        if (res.r == 0) {
          setCall(newCall);
        }
      });
    }
  };
  const handleNewCall = () => {
    // if (!call && stringeeClient) {
        const fromNumber = "shoaib_4455"; // Customer's user ID
        const toNumber = "shoaib"; // Agent's user ID
        const isVideoCall = true;
        const newCall = new StringeeCall(stringeeClient, fromNumber, toNumber, isVideoCall);

        newCall.on("addlocalstream", (stream) => {
            setLocalStream(stream);
        });

        newCall.on("addremotestream", (stream) => {
            setRemoteStream(stream);
        });

        newCall.makeCall((res) => {
            console.log('make call callback: ' + JSON.stringify(res));
            // if (res.status === 'connected') {
                setCall(newCall);
            // }
        });
    // }
};

  const handleHangup = () => {
    if (call) {
      call.hangup();
      setCall(null);
      setLocalStream(null);
      setRemoteStream(null);
    }
  };

  const getAccessToken = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/auth/get-token?userId=shoaib_4455`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });

      const json = await response.json();
      if (json.success) {
        setAccessToken(json.token);
        handleLogin(json.token);
        // const client = new StringeeClient([
        //   "wss://v1.stringee.com:6899/",
        //   "wss://v2.stringee.com:6899/",
        // ]);

        // client.on("authen", (res) => {
        //   if (res.r === 0) {
        //     setLoggedUserId(res.userId);
        //     setAccessToken(""); // Reset access token input field
        //   }
        // });
        //  console.log(json.token);
        // client.connect(json.token); // Connect using the generated token
        // setStringeeClient(client); // Set stringeeClient in state
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleLogin = (token) => {


    const client = new StringeeClient();

    client.on('connect', function () {
        console.log('++++++++++++++ connected to StringeeServer');
    });

    client.on('authen', function (res) {
        console.log('authen', res);
        if (res.r === 0) {
            setLoggedUserId(res.userId);
            setCallBtnDisabled(false);
            setVideoCallBtnDisabled(false);
        } else {
            setLoggedUserId(res.message);
        }
    });

    client.on('disconnect', function () {
        setCallBtnDisabled(true);
        setVideoCallBtnDisabled(true);
        console.log('++++++++++++++ disconnected');
    });

    client.on('incomingcall', function (incomingcall) {
          
        setCall(incomingcall);
        // Assuming settingCallEvent is a separate function, you can call it here
        // settingCallEvent(incomingcall);
        setIncomingCallDivVisible(true);
        incomingcall.ringing(function (res) {});
        console.log('++++++++++++++ incomingcall', incomingcall);
    });

    client.on('requestnewtoken', function () {
        console.log('++++++++++++++ requestnewtoken; please get new access_token from YourServer and call client.connect(new_access_token)+++++++++');
        //please get new access_token from YourServer and call: 
        //client.connect(new_access_token);
    });

    setStringeeClient(client);
    client.connect(token);
};
  return (
    <div>
      <h1>Customer Page</h1>
      {!call ? (
        <>
        <button onClick={handleLogin} id="loginBtn">Login</button>
        <div id="loggedUserId">{loggedUserId}</div>
        <button onClick={handleNewCall}>Call Agent</button>
        </>
      ) : (
        <>
          {localStream && (
            <video
              ref={(ref) => {
                if (ref) ref.srcObject = localStream;
              }}
              autoPlay
              playsInline
              muted
            ></video>
          )}
          {remoteStream && (
            <video
              ref={(ref) => {
                if (ref) ref.srcObject = remoteStream;
              }}
              autoPlay
              playsInline
            ></video>
          )}
          <button onClick={handleHangup}>Hang Up</button>
        </>
      )}
    </div>
  );
};

export default Customer;
