import React, { useEffect, useState } from "react";
import BackIcon from "../../../assets/icons/icon-back.svg";
import { Link } from "react-router-dom";
import { notification } from "antd";
import { Authenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
// import "@aws-amplify/ui-react/styles.css";
import InputMask from "react-input-mask";
function PhoneInput(props) {
  return (
    <InputMask
      mask="+1999 999 9999"
      value={props.value}
      onChange={props.onChange}
      placeholder="+1123 123 1234"
      className="form-control"
    ></InputMask>
  );
}

const Add = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const [getRoles, setRoles] = useState([]);
  const [getSite, setSite] = useState([]);
  const [site, setSites] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const handlePhoneInput = (e) => {
    setPhone(e.target.value);
  };
  const [formData, setFormData] = useState({
    given_name: "",
    family_name: "",
    email: "",
    address: "",
    username: "",
    password: "",
    site: "",
    role: "",
    phone: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignUp = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      // Validate form fields
      const validationErrors = {};
      if (!formData.given_name)
        validationErrors.given_name = "First Name is required";
      if (!formData.family_name)
        validationErrors.family_name = "Last Name is required";
      if (!formData.email) validationErrors.email = "Email is required";
      if (!formData.username)
        validationErrors.username = "Username is required";
      if (!formData.password)
        validationErrors.password = "Password is required";

      if (Object.keys(validationErrors).length) {
        setErrors(validationErrors);
        return;
      }

      // Step 1: Create User in Amazon Cognito
      const signUpResponse = await Auth.signUp({
        username: formData.username,
        password: formData.password,
        attributes: {
          email: formData.email,
          given_name: formData.given_name,
          family_name: formData.family_name,
          address: formData.address,
        },
      });

      // Extract Cognito user ID
      const awsUserId = signUpResponse.userSub;

      // Step 2: Store User Details in MongoDB
      if (awsUserId) {
        const response = await fetch(`${url}/api/auth/createUser`, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            aws_userid: awsUserId,
            aws_username: formData.username,
            name: formData.given_name + " " + formData.family_name,
            email: formData.email,
            password: formData.password,
            site: formData.site,
            role: formData.role,
            phone: phone,
            address: formData.address,
            status: "active",
          }),
        });

        const json = await response.json();
        setIsLoading(false);
        if (json.success) {
          setFormData({
            given_name: "",
            family_name: "",
            email: "",
            address: "",
            username: "",
            password: "",
            site: "",
            role: "",
            phone: "",
          });
          notification.success({
            message: "Success",
            description: "User created successfully",
            duration: 3,
          });
        } else {
          notification.error({
            message: "Failed",
            description: json.error,
            duration: 3,
          });
        }
      } else {
        setIsLoading(false);
        notification.error({
          message: "Error",
          description: "An error occurred during sign-up",
          duration: 3,
        });
      }
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Error",
        description: error.message,
        duration: 3,
      });
    }
  };
  const roleData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/roles/getRoles?role=${isAuthenticated.role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      setRoles(json);
    } catch (error) {
      console.error(error);
    }
  };
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/sites/list?role=${isAuthenticated.role}&site=${isAuthenticated.site}&company=${isAuthenticated.company_id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      setSite(json.site_data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    roleData();
    siteData();
  }, []);
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/user/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Create User</h3>
                  <p>Enter the credentials to add a new user to the system</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* User form */}
        <form onSubmit={handleSignUp}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>First Name</h4>
                      <p>Enter the precise name of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        name="given_name"
                        placeholder="Please enter your first name"
                        value={formData.given_name}
                        onChange={handleInputChange}
                      />
                      {errors.given_name && <p>{errors.given_name}</p>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Last Name</h4>
                      <p>Enter the precise last name of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        name="family_name"
                        placeholder="Please enter your last name"
                        value={formData.family_name}
                        onChange={handleInputChange}
                      />
                      {errors.family_name && <p>{errors.family_name}</p>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>UserName</h4>
                      <p>Enter unique name of the user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        name="username"
                        placeholder="Please enter a username"
                        value={formData.username}
                        onChange={handleInputChange}
                      />
                      {errors.username && <p>{errors.username}</p>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Password</h4>
                      <p>Enter password of the user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="password"
                        name="password"
                        placeholder="Please enter a password"
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                      {errors.password && <p>{errors.password}</p>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Email</h4>
                      <p>Enter email address of the user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        name="email"
                        placeholder="Please enter a valid email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      {errors.email && <p>{errors.email}</p>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Address</h4>
                      <p>Enter address of the user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        name="address"
                        placeholder="Please enter your address"
                        value={formData.address}
                        onChange={handleInputChange}
                      />
                      {errors.address && <p>{errors.address}</p>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>User Role</h4>
                      <p>Select the role that the user will play</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="role"
                        value={formData.role}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Choose Role</option>
                        {getRoles.map((per) => (
                          <option key={per._id} value={per.name}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>

              {site && (
                <div className="col-lg-6">
                  {/* input row */}
                  <div className="row">
                    <div className="col-xl-5 col-lg-5">
                      <div className="label-wrap">
                        <h4>User Site</h4>
                        <p>Select the site for user</p>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7">
                      <div className="inpt-wrap">
                        <select
                          name="site"
                          value={formData.site}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="" selected>
                            Choose Site
                          </option>
                          {getSite.map((per) => (
                            <option key={per._id} value={per._id}>
                              {per.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* input row */}
                </div>
              )}

              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Phone Number</h4>
                      <p>Enter phone number of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <PhoneInput
                        value={phone}
                        onChange={handlePhoneInput}
                      ></PhoneInput>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>

              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <a href="" className="btn btn-prim btn-transparent w-auto">
                    Reset Form
                  </a>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn btn-prim w-auto ms-5"
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                    Create User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Add;
