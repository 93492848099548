import React, { useState, useEffect } from "react";
import { StringeeUtil, StringeeClient, StringeeCall2 } from "stringee";
const url = process.env.REACT_APP_APP_BACK_URL;
const VideoCall = () => {
  const [sdkVersion, setSdkVersion] = useState("");
  const [loggedUserId, setLoggedUserId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [toNumber, setToNumber] = useState("842471015425");
  const [call, setCall] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [stringeeClient, setStringeeClient] = useState(null);

  useEffect(() => {
    setSdkVersion(
      StringeeUtil.version().version +
        "_build_" +
        StringeeUtil.version().build +
        "_sample_1"
    );
    getAccessToken();
  }, []);

  const handleLogin = () => {
    if (stringeeClient) {
      stringeeClient.disconnect();
    }
    const client = new StringeeClient([
      "wss://v1.stringee.com:6899/",
      "wss://v2.stringee.com:6899/",
    ]);
    // const token = getAccessToken(); // Generate access token
    client.on("connect", () => {
      console.log("StringeeClient connected");
    });
  
    // Add event listener for disconnect event
    client.on("disconnect", () => {
      console.log("StringeeClient disconnected");
    })
    client.on("authen", (res) => {
      if (res.r === 0) {
        setLoggedUserId(res.userId);
        setAccessToken(""); // Reset access token input field
        // Enable buttons
      }
    });
     console.log(accessToken);
    client.connect(accessToken); // Connect using the generated token
    setStringeeClient(client); // Set stringeeClient in state
  };

  const getAccessToken = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/auth/get-token?userId=shoaib`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });

      const json = await response.json();
      if (json.success) {
        console.log(json.token);
        setAccessToken(json.token);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMakeCall = () => {
    const fromNumber = loggedUserId;
    const callObject = new StringeeCall2(
      stringeeClient,
      fromNumber,
      toNumber,
      true
    );

    callObject.on("addlocalstream", (stream) => {
      setLocalStream(stream);
    });

    callObject.on("addremotestream", (stream) => {
      setRemoteStream(stream);
    });

    // Other event listeners

    callObject.makeCall((res) => {
      if (res.r === 0) {
        setCall(callObject);
        // Update UI
      }
    });
  };

  return (
    <div className="mt-5 text-center ">
      <textarea
        rows="6"
        cols="60"
        value={accessToken}
        onChange={(e) => setAccessToken(e.target.value)}
        style={{ height: "150px", width: "600px", resize: "none" }}
      ></textarea>
      <br />
      <br />
      <button className="px-3" onClick={handleLogin}>
        Login
      </button>{" "}
      Logged in: <span style={{ color: "red" }}>{loggedUserId}</span> | SDK
      Version: <span style={{ color: "blue" }}>{sdkVersion}</span>
      <br />
      <br />
      <input
        type="text"
        value={toNumber}
        onChange={(e) => setToNumber(e.target.value)}
        style={{ width: "200px" }}
        placeholder="Call to number"
      />{" "}
      <button
        className="px-3"
        onClick={handleMakeCall}
        disabled={!loggedUserId || !toNumber}
      >
        Make Call
      </button>
      <br />
      <br />
      <div>
        Local Stream:
        {localStream && (
          <video
            ref={(ref) => {
              if (ref) ref.srcObject = localStream;
            }}
            autoPlay
            playsInline
            muted
          ></video>
        )}
      </div>
      <br />
      <div>
        Remote Stream:
        {remoteStream && (
          <video
            ref={(ref) => {
              if (ref) ref.srcObject = remoteStream;
            }}
            autoPlay
            playsInline
          ></video>
        )}
      </div>
    </div>
  );
};

export default VideoCall;
