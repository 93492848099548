import React, { useState, useEffect } from "react";

const ProgressBar = () => {
  const [progress, setProgress] = useState(100); // Initial progress value
  const countdownDuration = 10; // Duration of countdown in seconds

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress > 0) {
          return prevProgress - 100 / countdownDuration;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000); // Run every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []); // Empty dependency array means this effect runs only once

  return (
    <div className="container mt-5">
      <div className="progress" style={{ width: "250px" }}>
        <div
          className="progress-bar bg-warning d-flex justify-content-center align-align-items-center  flex-row"
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <p className="text-center mt-2">
        {Math.round(progress / 10)} seconds remaining
      </p>
    </div>
  );
};

export default ProgressBar;
