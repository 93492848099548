import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import FilterIcon from "../../../images/filter.png";
import { Link, useNavigate ,useParams} from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import BackIcon from '../../../assets/icons/icon-back.svg'

import { motion } from "framer-motion";
import Edit from "../../../assets/icons/edit-ic.svg";
import Delete from "../../../assets/icons/delete-ic.svg";
import Details from "../../../assets/icons/infoo.svg";
import HashLoader from "react-spinners/ClipLoader";
import Images from "../modal/Images";
import { notification } from "antd";
import CrossIcon from "../../../assets/icons/cross_icon.svg";
import Modal from "react-modal";
const Logs = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id,slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const itemsPerPage = 10;
  useEffect(() => {
    fetchData();
    // getTypes();
  }, [currentPage]);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };

  const openModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImageUrl("");
  };

  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(
        `${url}/api/locker/lockerLogs?page=${currentPage}&limit=${itemsPerPage}&id=${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.locker_data.length > 0) {
        setData(json.locker_data);
        setIsDisplay(true);
      } else {
        setIsNotFound(true);
      }
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };


  const formatDate = (dateString) => {
    // Split the date string into components
    const [day, month, year, time] = dateString.split(" ");
    const [hours, minutes, seconds] = time.split(":");
  
    // Create a new date object from these components
    const date = new Date(year, month - 1, day, hours, minutes, seconds);
  
    // Format the date as DD/MM/YYYY HH:MM:SS
    const formattedDate = `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  
    return formattedDate;
  };
 

  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
          <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to={`/${slug}/lockers`}>
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Lockers logs</h3>
                  <p>
                  List of all the lockers Logs in the system
                  </p>
                </div>
              </div>
            </div>
           
          </div>
        </div>
        {/* row */}
        <div className="table-wrap">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                          <th>Locker no</th>
                          <th>Date Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <>
                            <tr>
                              <td
                                colSpan={10}
                                className="text-center"
                                style={{ background: "transparent" }}
                              >
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        {isDisplay && data.length > 0
                          ? data.map((item, i) => (
                              <motion.tr
                                key={item._id}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1.2, delay: i * 0.1 }}
                              >
                                <td>
                                  <div className="usr_det_tb">
                                    <div className="img_wrap_qr_tb">
                                      {item.product_image &&
                                        item.product_image !== "" && (
                                          <>
                                            <img
                                              className={`not_prof_img`}
                                              src={`${item.product_image}`}
                                              onClick={() =>
                                                openModal(
                                                  `${item.product_image}`
                                                )
                                              }
                                              alt=""
                                            />
                                            <Images
                                              isOpen={isModalOpen}
                                              imageUrl={selectedImageUrl}
                                              onClose={closeModal}
                                            />
                                          </>
                                        )}
                                    </div>
                                    <span>{item.LockerNumber}</span>
                                  </div>{" "}
                                </td>
                                <td>{formatDate(item.date_time)}</td>
                                
                              </motion.tr>
                            ))
                          : isNotFound && (
                              <tr>
                                <td colSpan={7} className="text-center">
                                  <p className="text-center text-danger">
                                    No data found.
                                  </p>
                                </td>
                              </tr>
                            )}
                      </tbody>
                    </table>
                  </div>
                  <div className="table-num">
                    {/* {data.length > 0 && ( */}
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={totalPages}
                        forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                        onPageChange={handlePageChange}
                        containerClassName={" dig-num"}
                        pageClassName={"dig-num"}
                        previousClassName={"num-btns"}
                        nextClassName={"num-btns"}
                        disabledClassName={"pagination-disabled"}
                        activeClassName={"pagination-active"}
                      />
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};
export default Logs;

