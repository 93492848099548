import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from "../../../assets/icons/icon-back.svg";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import UploadIcon from "../../../images/icon-upload.png";
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
const Add = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const site = isAuthenticated.site;
  const role = isAuthenticated.role;
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [getSites, setSite] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mediaShow, setMediaShow] = useState(false);
  const [file, setFile] = useState(null);
  const [getLocker, setLockerData] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  useEffect(() => {
    siteData();
  }, []);

  const [credentials, setCredentials] = useState({
    name: "",
    quantity: "",
    site: "",
    price: "",
    locker:"",
  });
  const handleInput = (e) => {
    if (e.target.name.toLowerCase() === "site") {
      getSiteLockers(e.target.value);
    }
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    formData.append("name", credentials.name);
    formData.append("quantity", credentials.quantity);
    formData.append("price", credentials.price);
    formData.append("site", credentials.site);
    formData.append("locker", credentials.locker);
    setIsLoading(true);
    const response = await fetch(`${url}/api/inventory/createInventory`, {
      method: "POST",
      body: formData,
    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setCredentials({
        name: "",
        quantity: "",
        price: "",
        site: "",
        locke:"",
      });
      notification.success({
        message: "Success",
        description: "Product Created Successfully",
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/sites/list/?site=${site}&role=${role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      setSite(json.site_data);
    } catch (error) {
      console.error(error);
    }
  };

   // site lockers
   const getSiteLockers = async (site) => {
    try {
      if (site !== "") {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(`${url}/api/locker/siteLockers/${site}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        });
        const json = await response.json();
        if (json.success) {
          setLockerData(json.data);
        }
      } else {
        notification.error({
          message: "Failed",
          description: "Please select site first to select lockers!",
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "Failed",
        description: "Intervel server issue",
        duration: 3,
      });
    }
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setMediaShow(false);
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        setFile(file);
        setError(null);
        // Create a FileReader
        const reader = new FileReader();
        // Add a progress event listener to the FileReader
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const percentUploaded = Math.round((e.loaded / e.total) * 100);
            setUploadProgress(percentUploaded);
          }
        };
        // Read the file (you can also add other event listeners like 'onload')
        reader.readAsDataURL(file);
      } else {
        setFile(null);
        setError("Invalid file type. Please select an image file.");
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/product/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Create Product</h3>
                  <p>Enter the credentials to add product to the system</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Name</h4>
                      <p>Enter the precise name of product</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fuserName"
                        name="name"
                        placeholder="Enter Name.."
                        value={credentials.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Quantity</h4>
                      <p>Enter the quantity of product</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="quantity"
                        name="quantity"
                        placeholder="Enter Quantity.."
                        value={credentials.quantity}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Price</h4>
                      <p>Enter the price of product</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="price"
                        name="price"
                        placeholder="Enter Price.."
                        value={credentials.price}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Sites</h4>
                      <p>Enter the site of product</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="site"
                        value={credentials.site}
                        onChange={handleInput}
                        required
                      >
                        <option value="">Choose Site</option>
                        {getSites &&
                          getSites.map((per) => (
                            <option key={per._id} value={per._id}>
                              {per.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Lockers</h4>
                      <p>Select lockers to assign product</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="locker"
                        value={credentials.locker}
                        onChange={handleInput}
                        required
                      >
                        <option value="">Choose Locker</option>
                        {getLocker &&
                          getLocker.map((per) => (
                            <option key={per._id} value={per._id}>
                              {per.number}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Picture</h4>
                      <p>Enter the picture of product</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <div className="file-wrap">
                        <input type="file" onChange={handleFileSelect} />
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="file_name">
                            {file ? file.name : "No file selected"}
                          </span>{" "}
                          <img src={UploadIcon} alt="Upload Icon" />
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "red",
                          fontStyle: "italic",
                        }}
                      >
                        {uploadProgress > 0 && uploadProgress < 100 && (
                          <span>{uploadProgress}% uploaded</span>
                        )}
                      </div>
                      {error && (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "red",
                            fontStyle: "italic",
                          }}
                        >
                          {error}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    {file && (
                      <div className="upld-img-wrap">
                        <img src={URL.createObjectURL(file)} alt="" />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button
                    type="submit"
                    disabled={isLoading}
                    href=""
                    className="btn btn-prim w-auto ms-5"
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Add;
