import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from "../../../assets/icons/icon-back.svg";
import UploadIcon from "../../../images/icon-upload.png";
import { useSelector } from "react-redux";
import { notification } from "antd";
import styles from "./style.module.css";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
const Edit = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const [appendedHTML, setAppendedHTML] = useState("");
  const [logoImage, setLogoImage] = useState(null);
  useEffect(() => {
    getSite(id);
  }, [id]);
  const [credentials, setCredentials] = useState({
    name: "",
    address: "",
    status: "",
    ip_address: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      if (logoImage) {
        await uploadLogo();
      }
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      // const response = await fetch("http://54.174.4.89:5000/api/auth/login", {
      //   mode: "cors",
      //   method: "POST",
      //   headers: headers,
      //   body: JSON.stringify({
      //     email: credentials.email,
      //     password: credentials.password,
      //   }),
      // });
      setIsLoading(true);
      const response = await fetch(`${url}/api/sites/updateSite/${id}`, {
        mode: "cors",
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          name: credentials.name,
          address: credentials.address,
          status: credentials.status,
          ip_address: credentials.ip_address,
          protocol: credentials.protocol,
          host: credentials.host,
          port: credentials.port,
          cmd: credentials.cmd,
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        notification.success({
          message: "Success",
          description: "Site Updated Successfully",
          duration: 3,
        });
      } else {
        setIsLoading(false);
        notification.error({
          message: "Failed",
          description: json.error,
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // get site data
  // get role
  const getSite = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSiteData/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setCredentials({ ...json.site });
    } catch (error) {
      console.error(error);
    }
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const handleLogo = (e) => {
    setLogoImage(e.target.files[0]);
  };
  const uploadLogo = async () => {
    try {
      const formData = new FormData();
      if (logoImage) {
        formData.append("file", logoImage);
        formData.append("id", id);
      }
      const response = await axios.post(`${url}/api/sites/logo/add`, formData);
      const json = await response;
      console.log(json, "pic logo");
      if (json.success) {
        toast.success({
          message: "Success",
          description: "Image uploaded Successfully",
          duration: 3,
        });
      } else {
        toast.error({
          message: "Failed",
          description: "failed to upload image",
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/site/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Update Site</h3>
                  <p>List of all the sites registered in the system</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Site Name</h4>
                      <p>Enter the name of the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="sitec"
                        placeholder="Site Name"
                        name="name"
                        value={credentials?.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Status</h4>
                      <p>Select the status of the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="status"
                        required
                        value={credentials.status}
                        onChange={handleInput}
                      >
                        <option value="">Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">In Active</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Site IP</h4>
                      <p>Enter the IP address of the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        placeholder="192.168.10.1"
                        name="ip_address"
                        required
                        value={credentials.ip_address}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Site Address</h4>
                      <p>Enter complete address of the site</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <textarea
                        name="address"
                        id=""
                        cols="30"
                        rows="5"
                        value={credentials.address}
                        onChange={handleInput}
                      >
                        2405 Wesbrook Mall, Vancouver, BC V6T 1Z4, Canada
                      </textarea>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Logo</h4>
                      <p>Upload the image</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input type="file" name="file" onChange={handleLogo} />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <a href="" className="btn btn-prim btn-transparent w-auto">
                    Reset Form
                  </a>
                  <button
                    type="submit"
                    className="btn btn-prim w-auto ms-5"
                    disabled={isLoading}
                  >
                    {isLoading ? "Updating..." : "Update Site"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Edit;
